import { GLOBAL_SETTINGS } from './global-settings';

export const AnonymousUser: any = {
    _id: ''
}

export interface IAppState {
    production: boolean;
    version: string;
    domain: string;
    server: string;
    apiVersion: string;
    officeSsoToken: string;
    spAccessToken: string;
    loggedIn: boolean;
    me: any;
    connected: boolean;
    dataSource: any;
    theme: string;
    excel: any;
    panelSize: 'medium-size' | 'large-size';
    hostname?: string;
    serverRelativePath?: string;
    siteId?: string;
    backEndUrl?: string;
    onOnline?: boolean;
    appvityApiKey?: string;
    crmAccessToken: string;
    efiesToken?: string;
    ehelpdeskProps?: any;
    eProductUrl: string;
    eProductAppIds?: {
        eTask: string;
        eHelpdesk: string;
        eFiles: string;
    };
}

export class AppState {
    production: boolean;
    version: string;
    domain: string;
    server: string;
    apiVersion: string;
    officeSsoToken: string;
    spAccessToken: string;
    loggedIn: boolean;
    me: any;
    connected: boolean;
    dataSource: any;
    theme: string;
    excel: any;
    panelSize: 'medium-size' | 'large-size';
    hostname?: string;
    serverRelativePath?: string;
    siteId?: string;
    backEndUrl?: string;
    onOnline?: boolean;
    appvityApiKey?: string;
    crmAccessToken: string;
    efiesToken?: string;
    ehelpdeskProps?: any;
    eProductUrl: string;
    eProductAppIds?: {
        eTask: string;
        eHelpdesk: string;
        eFiles: string;
    };

    constructor(
        production = false,
        version = '',
        domain = '',
        server = '',
        apiVersion = '',
        officeSsoToken = '',
        spAccessToken = '',
        loggedIn = false,
        me = AnonymousUser,
        connected = false,
        dataSource = '',
        theme = 'light',
        excel = {},
        panelSize: 'medium-size' | 'large-size' = 'medium-size',
        hostname = '',
        serverRelativePath = '',
        siteId = '',
        backEndUrl = '',
        onOnline = true,
        appvityApiKey = '',
        crmAccessToken = '',
        efiesToken = '',
        ehelpdeskProps = null,
        eProductUrl = '',
        eProductAppIds = {
            eTask: '',
            eHelpdesk: '',
            eFiles: ''
        }
    ) {
        this.production = production;
        this.version = version;
        this.domain = domain;
        this.server = server;
        this.apiVersion = apiVersion;
        this.officeSsoToken = officeSsoToken;
        this.spAccessToken = spAccessToken;
        this.loggedIn = loggedIn;
        this.me = me;
        this.connected = connected;
        this.dataSource = dataSource;
        this.theme = theme;
        this.excel = excel;
        this.panelSize = panelSize;
        this.hostname = hostname;
        this.serverRelativePath = serverRelativePath;
        this.siteId = siteId;
        this.backEndUrl = backEndUrl;
        this.onOnline = onOnline;
        this.appvityApiKey = appvityApiKey;
        this.crmAccessToken = crmAccessToken;
        this.efiesToken = efiesToken;
        this.ehelpdeskProps = ehelpdeskProps;
        this.eProductUrl = eProductUrl;
        this.eProductAppIds = eProductAppIds;
    }
}

export class AppStateBuilder {
    production: boolean;
    version: string;
    domain: string;
    server: string;
    apiVersion: string;
    officeSsoToken: string;
    spAccessToken: string;
    loggedIn: boolean;
    me: any;
    connected: boolean;
    dataSource: any;
    theme: string;
    excel: any;
    panelSize: 'medium-size' | 'large-size';
    hostname?: string;
    serverRelativePath?: string;
    siteId?: string;
    backEndUrl?: string;
    onOnline?: boolean;
    appvityApiKey?: string;
    crmAccessToken: string;
    efilesToken?: string;
    ehelpdeskProps?: any;
    eProductUrl: string;
    eProductAppIds?: any;

    setProduction(value = false) {
        this.production = value;
        return this;
    }

    setVersion(value = '') {
        this.version = value;
        return this;
    }

    setDomain(value = '') {
        this.domain = value;
        return this;
    }

    setServer(value = '') {
        this.server = value;
        return this;
    }

    setApiVersion(value = '') {
        this.apiVersion = value;
        return this;
    }

    setOfficeSsoToken(value = '') {
        this.officeSsoToken = value;
        return this;
    }

    setAccessToken(value = '') {
        this.spAccessToken = value;
        return this;
    }

    setLoggedIn(value = false) {
        this.loggedIn = value;
        return this;
    }

    setMe(value = AnonymousUser) {
        this.me = value;
        return this;
    }

    setConnected(value = false) {
        this.connected = value;
        return this;
    }

    setDataSource(value = '') {
        this.dataSource = value;
        return this;
    }

    setTheme(value = 'light') {
        this.theme = value;
        return this;
    }

    setExcel(value = {}) {
        this.excel = value;
        return this;
    }

    setPanelSize(value: 'medium-size' | 'large-size' = 'medium-size') {
        this.panelSize = value;
        return this;
    }

    setHostname(value = '') {
        this.hostname = value;
        return this;
    }

    setServerRelatePath(value = '') {
        this.serverRelativePath = value;
        return this;
    }

    setSiteId(value = '') {
        this.siteId = value;
        return this;
    }

    setBackEndUrl(value = '') {
        this.backEndUrl = value;
        console.log('state be-url: ' + value);
        return this;
    }

    setOnline(value = true) {
        this.onOnline = value;
        return this;
    }

    setAppvityApiKey(value = '') {
        this.appvityApiKey = value;
        return;
    }

    setCRMAccessToken(value = '') {
        this.crmAccessToken = value;
        return this;
    }

    setEFilesToken(value = '') {
        this.efilesToken = value;
        return this;
    }

    setHelpDeskProperties(value = null) {
        this.ehelpdeskProps = value;
        return this;
    }

    setEProductUrl(value = '') {
        this.eProductUrl = value;
        return this;
    }

    setEProductAppIds(value = { eTask: '', eHelpdesk: '', eFiles: '' }) {
        this.eProductAppIds = value;
        return this;
    }

    build() {
        return new AppState(this.production, this.version, this.domain, this.server, this.apiVersion, this.officeSsoToken, this.spAccessToken, this.loggedIn, this.me, this.connected, this.dataSource, this.theme, this.excel, this.panelSize, this.hostname, this.serverRelativePath, this.siteId, this.backEndUrl, this.onOnline, this.appvityApiKey, this.crmAccessToken, this.efilesToken, this.ehelpdeskProps, this.eProductUrl, this.eProductAppIds);
    }
}

export const INITIAL_STATE = new AppStateBuilder()
    .setProduction(GLOBAL_SETTINGS.production)
    .setVersion(GLOBAL_SETTINGS.version)
    .setDomain(GLOBAL_SETTINGS.domain)
    .setServer(GLOBAL_SETTINGS.server)
    .setApiVersion(GLOBAL_SETTINGS.apiVersion)
    .setOfficeSsoToken(GLOBAL_SETTINGS.officeSsoToken)
    .setAccessToken(GLOBAL_SETTINGS.spAccessToken)
    .setBackEndUrl(GLOBAL_SETTINGS.backEndUrl)
    .setOnline(true)
    .setCRMAccessToken(GLOBAL_SETTINGS.crmAccessToken)
    .setEFilesToken(GLOBAL_SETTINGS.efilesToken)
    .setHelpDeskProperties(GLOBAL_SETTINGS.ehelpdeskProps)
    .setEProductUrl(GLOBAL_SETTINGS.eProductUrl)
    .setEProductAppIds(GLOBAL_SETTINGS.eProductAppIds)
    .build();
