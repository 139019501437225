import { inject, Injectable } from "@angular/core";
import { IAppState } from "@app-state";
import { ExcelSyncAzureStorageService } from "@common/services/appvity";
import { StateService } from "@utils/state";
import { concat, finalize, Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class AppApiService {
    state = inject(StateService<IAppState>);
    azureStorageService = inject(ExcelSyncAzureStorageService);

    processUploadFile$(files: File[], sessionKey: string) {
        const result = files.reduce((acc, cur) => {
            acc.push({
                total: 1,
                current: 0,
                metadata: {
                    fileName: cur.name,
                    fileSize: cur.size
                }
            });
            return acc;
        }, []);
        return new Observable(observer => {
            const requests$ = files.reduce((acc: Observable<any>[], cur, index) => {
                acc.push(this.processUploadSingleFile$(cur, sessionKey, index));
                return acc;
            }, []);
            concat(...requests$).subscribe({
                next: (res) => {
                    const { total, current, index, metadata, isFailed } = res;
                    result[index] = {
                        total,
                        current,
                        metadata: metadata,
                        isFailed: isFailed
                    };
                    observer.next({
                        total: files.length,
                        current: result
                    });
                    if (index === files.length - 1 && total === current) {
                        observer.complete();
                    }
                }
            });
        });
    }

    processUploadSingleFile$(file: File, session: string, index: number) {
        return new Observable(observer => {
            const chunkSize = (3 * 1024 * 1024); // 3MB chunk size
            const totalChunks = Math.ceil(file.size / chunkSize);
            const request$ = [];
            const result = [];

            if (totalChunks === 0) {
                request$.push(this.azureStorageService.uploadFileStream$(file.name, file, session, 0, file.size));
            }

            for (let i = 0; i < totalChunks; i++) {
                const start = i * chunkSize;
                const end = Math.min(start + chunkSize, file.size);
                const chunk = file.slice(start, end);
                request$.push(this.azureStorageService.uploadFileStream$(file.name, chunk, session, start, file.size));
            }
            concat(...request$).subscribe({
                next: (res: any) => {
                    if (res) {
                        result.push(1);
                    }
                    observer.next({
                        total: totalChunks,
                        current: result.length,
                        index: index,
                        metadata: {
                            fileName: res.fileName,
                            fileId: res.id,
                            fileSize: file.size
                        }
                    });
                    if (result.length === totalChunks) {
                        observer.complete();
                    }
                },
                error: () => {
                    observer.next({
                        total: totalChunks,
                        current: [],
                        index: index,
                        metadata: {
                            fileName: file.name,
                            fileSize: file.size
                        },
                        isFailed: true
                    });
                    observer.complete();
                }
            });
        })
    }
}