import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { PreloadAllModules, provideRouter, withHashLocation, withPreloading, withRouterConfig } from '@angular/router';
import { environment } from '@environment';
import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { appInterceptor } from './app/app.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStateConfigs } from '@utils/state';
import { INITIAL_STATE } from '@app-state';
import { ToastrModule } from 'ngx-toastr';

declare const Office: any;

if (environment.production) {
    enableProdMode();
}

Office.onReady().then(() => {
    bootstrapApplication(AppComponent, {
        providers: [
            provideHttpClient(withInterceptors([appInterceptor])),
            provideAnimations(),
            provideRouter(
                routes,
                withPreloading(PreloadAllModules),
                withRouterConfig({
                    onSameUrlNavigation: 'reload',
                }),
                withHashLocation()
            ),
            provideStateConfigs({
                initialState: INITIAL_STATE
            }),
            importProvidersFrom(
                ToastrModule.forRoot({
                    positionClass: 'toast-top-full-width',
                    maxOpened: 3,
                    preventDuplicates: true
                })
            )
        ]
    }).catch(err => console.error(err));
    // Office.context.document.settings.set("Office.AutoShowTaskpaneWithDocument", true);
    // Office.context.document.settings.saveAsync();
})
