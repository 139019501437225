import { Injectable, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    toastr = inject(ToastrService);

    showSuccess(message = '') {
        if (message) {
            this.toastr.success(message);
        }
    }

    showError(cause = '') {
        if (cause) {
            this.toastr.error(cause);
        }
    }
}
