import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IRenameDialogData } from './schemas/rename-dialog.config';
import { ResizeObserverService } from '@utils/services';
import { AbstractControl, FormControl, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorMessageComponent } from '../error-message';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-rename-dialog',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        ReactiveFormsModule,
        ErrorMessageComponent,
    ],
    templateUrl: './rename-dialog.component.html',
    styleUrls: ['./rename-dialog.component.scss']
})
export class RenameDialogComponent implements OnInit, OnDestroy {
    destroy$ = new Subject<void>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: IRenameDialogData) {
        this.registerResizeObserver();
        this.panelSize = data.state.panelSize;
    }
    ngOnInit(): void {
        this.controlNewName.patchValue(this.data.currentWorksheetName, { emitEvent: false });
        this.controlNewName.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
            next: () => {
                this.cdr.detectChanges();
            }
        })
    }

    resizeObserver = inject(ResizeObserverService);
    panelSize = '';
    cdr = inject(ChangeDetectorRef);

    controlNewName = new FormControl('', { validators: [Validators.required, Validators.maxLength(30), this.invalidPatternValidator(), this.invalidSpecificWsNameValidator(), this.duplicateWsNameValidator()] });

    trackByFn(index: number) {
        return index;
    }

    registerResizeObserver() {
        this.resizeObserver.observe(document.querySelector('body'), (width, _) => {
            if (width > 500) {
                this.panelSize = 'large-size';
            } else {
                this.panelSize = 'medium-size';
            }
            this.cdr.detectChanges();
        });
    }

    invalidPatternValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const value = control.value;

            if (!value) {
                return null;
            }

            const valueInvalid = /[\/\\?*:[\]\s]/.test(value);
            return valueInvalid ? { invalidCharacter: true } : null;
        }
    }

    invalidSpecificWsNameValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const value = control.value;

            if (!value) {
                return null;
            }

            const valueInvalid = /^[Hh]istory$|^[']|[']$/.test(value);
            return valueInvalid ? { invalidSpecificName: true } : null;
        }
    }

    duplicateWsNameValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const value = control.value;

            if (!value) {
                return null;
            }

            const valueInvalid = this.data.listWorksheetConnected.find(item => item.text === value);
            return valueInvalid ? { duplicateName: true } : null;
        }
    }

    ngOnDestroy() {
        this.destroy$.unsubscribe();
        this.resizeObserver.unobserve(document.querySelector('body'));
    }
}
