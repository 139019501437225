export enum CRMDynamicsDataSource {
    'Microsoft Dataverse' = 'Microsoft.Dataverse'
}

export interface ICRMEntityContext {
    LogicalName: string;
    EntitySetName: string;
    ObjectTypeCode: number;
    PrimaryIdAttribute: string;
    CreatedOn: string;
    ModifiedOn: string;
    TableType: string;
    MetadataId: string;
    IsActivity: string;
    DisplayName: {
        LocalizedLabels: ILocalizedLabel[],
        UserLocalizedLabel: ILocalizedLabel
    }
}

export interface IEntity {
    [k: string]: any;
    id: string;
    title: string;
    entitySetName: string;
    logicalName: string;
    created: string;
    tableType: string;
}

export interface IEntitySettings {
    [k: string]: any;
    entityId: string;
    entityName: string;
    views?: IEntityView[];
    columns: IEntityColumn[];
    optionSet: IEntityOptionSet[];
    viewId: string;
    viewName: string;
    oneToManyRelationships: IEntityRelationship[];
    manyToOneRelationships: IEntityRelationship[];
}

/**
 * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/reference/savedquery?view=dataverse-latest#properties
 */
export interface IEntityView {
    [k: string]: any;
    id: string;
    title: string;
    fields: {
        internalName: string,
        relatedTable: string
    }[];
    fetchXml: string;
}

export interface IEntityColumn {
    [k: string]: any;
    id: string;
    internalName: string;
    title: string;
    required: boolean;
    defaultValue?: any;
    readonlyField: boolean;
    columnType: string;
    columnFormat?: string;
    maxValue?: any;
    minValue?: any;
    maxLength?: number;
    decimalPlace?: number;
    isPrimaryColumn: boolean;
    isPrimaryID: boolean;
    relationship?: string[];
    autoNumberFormat?: string;
    schemaName: string;
    entityLogicalName: string;
}

export interface IEntityOptionSet {
    attributeLogicalName: string;
    id: string;
    choices: {
        value: number;
        text: string;
        color: string;
    }[];
}

export interface IEntityRelationship {
    [k: string]: any;
    id: string;
    referencingAttribute: string;
    referencingNavigationPropertyName: string;
}

interface ILocalizedLabel {
    'Label': string
    'LanguageCode': number,
    'IsManaged': boolean,
    'MetadataId': string,
    'HasChanged': any
}

export const PowerAppsDataverseRegrexConfig = {
    /**@description Prefix of entities or column of an Environemt */
    'Environment Prefix': /^cr[a-zA-Z0-9]{3}_/
}

export const DataverseEntityDefaultListMappingTable = {
    name: '',
    range: '',
    values: [['Entity ID', 'Entity Name', 'View ID', 'Mapping Worksheet ID', 'Mapping Worksheet Name', 'Data Items Worksheet ID', 'Data Items Worksheet Name', 'Data Items Table Name', 'Review Data Items Worksheet Name', 'Review Data Items Original Table Name', 'Review Data Items New Table Name', 'Review Data Items Edited Table Name', 'Review Data Items Deleted Table Name']],
    rows: [['', '', '', '', '', '', '', '', '', '', '', '', '']]
}

export const DataverseDefaultEntitySettingsColumnsTable = {
    name: '',
    range: '',
    values: [['Column ID', 'Title', 'Internal Name', 'Required', 'Read Only', 'Column Type', 'Column Format', 'Default Value', 'Max Length', 'Max Value', 'Min Value', 'Is Primary', 'AutoNumberFormat', 'Relationship', 'Schema Name', 'Is Primary ID', 'Entity Logical Name']],
    rows: [['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']]
}

export const DataverseDefaultEntitySettingsOptionSetTable = {
    name: '',
    range: '',
    values: [['Column ID', 'Column Name', 'Choices']],
    rows: [['', '', '']]
}

export const DataverseDefaultEntitySettingsRelationshipsTable = {
    name: '',
    range: '',
    values: [['ID', 'Reference Attribute', 'Reference Navigation Property Name']],
    rows: [['', '', '']]
}

export const DataverseDefaultEntitySettingsTable = {
    name: '',
    range: '',
    values: [['Entity ID', 'Entity Name', 'View ID', 'View Name', 'Column', 'Option Set', 'One 2 Many Relationships', 'Many 2 One Relationships']],
    rows: [['', '', '', '', '', '', '', '']]
}

export const EntitiesFilterQueryString = `(IsIntersect eq false and IsLogicalEntity eq false and PrimaryNameAttribute ne null and PrimaryNameAttribute ne '' and ObjectTypeCode gt 0 and ObjectTypeCode ne 4712 and ObjectTypeCode ne 4724 and ObjectTypeCode ne 9933 and ObjectTypeCode ne 9934 and ObjectTypeCode ne 9935 and ObjectTypeCode ne 9947 and ObjectTypeCode ne 9945 and ObjectTypeCode ne 9944 and ObjectTypeCode ne 9942 and ObjectTypeCode ne 9951 and ObjectTypeCode ne 2016 and ObjectTypeCode ne 9949 and ObjectTypeCode ne 9866 and ObjectTypeCode ne 9867 and ObjectTypeCode ne 9868) and (IsCustomizable/Value eq true or IsCustomEntity eq true or IsManaged eq false or IsMappable/Value eq true or IsRenameable/Value eq true)`