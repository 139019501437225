declare const Office;

/**
 * @description Specifies the kind of event that was raised. Returned by the type property of an *EventArgs object.
 * @see https://learn.microsoft.com/en-us/javascript/api/office/office.eventtype?view=common-js-preview
 */
export enum OfficeEventType {
    'ActiveViewChanged' = Office ?? Office.EventType.ActiveViewChanged,
    'AppointmentTimeChanged' = Office ?? Office.EventType.AppointmentTimeChanged,
    'AttachmentsChanged' = Office ?? Office.EventType.AttachmentsChanged,
    'BindingDataChanged' = Office ?? Office.EventType.BindingDataChanged,
    'BindingSelectionChanged' = Office ?? Office.EventType.BindingSelectionChanged,
    'DialogEventReceived' = Office ?? Office.EventType.DialogEventReceived,
    'DialogMessageReceived' = Office ?? Office.EventType.DialogMessageReceived,
    'DialogParentMessageReceived' = Office ?? Office.EventType.DialogParentMessageReceived,
    'DocumentSelectionChanged' = Office ?? Office.EventType.DocumentSelectionChanged,
    'EnhancedLocationsChanged' = Office ?? Office.EventType.EnhancedLocationsChanged,
    'InfobarClicked' = Office ?? Office.EventType.InfobarClicked,
    'ItemChanged' = Office ?? Office.EventType.ItemChanged,
    'NodeDeleted' = Office ?? Office.EventType.NodeDeleted,
    'NodeInserted' = Office ?? Office.EventType.NodeInserted,
    'NodeReplaced' = Office ?? Office.EventType.NodeReplaced,
    'OfficeThemeChanged' = Office ?? Office.EventType.OfficeThemeChanged,
    'RecipientsChanged' = Office ?? Office.EventType.RecipientsChanged,
    'RecurrenceChanged' = Office ?? Office.EventType.RecurrenceChanged,
    'ResourceSelectionChanged' = Office ?? Office.EventType.ResourceSelectionChanged,
    'SelectedItemsChanged' = Office ?? Office.EventType.SelectedItemsChanged,
    'SensitivityLabelChanged' = Office ?? Office.EventType.SensitivityLabelChanged,
    'SettingsChanged' = Office ?? Office.EventType.SettingsChanged,
    'TaskSelectionChanged' = Office ?? Office.EventType.TaskSelectionChanged,
    'ViewSelectionChanged' = Office ?? Office.EventType.ViewSelectionChanged
}
