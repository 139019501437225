import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BaseComponent } from '@utils/base/base.component';
import { FooterComponent } from './footer/footer.component';
import { OfficeService } from '@common/services/office';
import { DefaultConnectionWorksheet, DefaultMappingWorksheet, DefaultMappingWorksheetTable, DefaultSettingsWorksheet, RangeObjectNumberFormatText } from '@common/schemas';
import { concat, finalize, Observable, of, switchMap, timer } from 'rxjs';
import { takeUntil } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { getIconSource } from '@utils/functions';
import { MatMenuModule } from '@angular/material/menu';
import { DataSourceInfoDialogComponent } from '@common/components/datasource-info-dialog/datasource-info-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@common/components/confirm-dialog';
import { RenameDialogComponent } from '@common/components/rename-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LogService } from '@utils/services';
import { PowerAppsDataverseService } from '@common/services/powerapps-dataverse';
import jwt_decode from 'jwt-decode';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        FooterComponent,
        ReactiveFormsModule,
        MatMenuModule,
        DataSourceInfoDialogComponent,
        MatDialogModule,
        MatIconModule,
        MatTooltipModule
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
    dialog = inject(MatDialog);
    officeApi = inject(OfficeService);
    log = inject(LogService);
    powerAppApi = inject(PowerAppsDataverseService);

    listWorksheetConnected: {
        value: any,
        text: string,
    }[] = [];

    connectingWorksheet = new FormControl('%add_connection%');

    layoutState: 'main-flow' | 'list-connections' | 'log-message' = 'main-flow';

    eventWorksheetActiveResult = new Map();

    actionMessageLog = [];

    ngOnInit() {
        this.registerPerformanceMark('init-app-component');
        this.registerResources();
        this.registerAppStateChanged();
        concat(
            this.initGlobalWorksheetState$(),
            this.getOfficeSsoToken$(),
            this.ensureSettingsWorksheet$(),
            this.ensureMappingWorksheet$(),
            this.ensureMappingWorksheetTable$()
        ).pipe(
            finalize(() => {
                this.reigsterSsoTokenSchedule();
                this.registerCoreLayer();
                this.finishPerformanceMark('init-app-component', `Registrations retrieved ({{timeRange}})`);
                this.registerPerformanceMark('ensure-configuration');
                this.ensureConfigurationWorksheet$().pipe(
                    switchMap(data => this.processConnectionDataObject$(data))).subscribe({
                        next: (url: any) => {
                            if (url && url.error) {
                                this.notification.showError(url.error);
                                this.finishPerformanceMark('ensure-configuration', `Server configuration retrieved failed ({{timeRange}})`, 'error');
                                return;
                            }
                            if (url && (this.router.url === '/' || this.router.url === '/connection')) {
                                this.finishPerformanceMark('ensure-configuration', `Server configuration retrieved ({{timeRange}})`);
                                this.router.navigate([url]);
                            }
                        }
                    });
            })
        ).subscribe();
    }

    generateAdaptiveCardBody(data, columns, primaryColumn) {
        const fact = columns.reduce((acc: any[], cur) => {
            const value = this.bindingAdaptiveCardData(cur, data).toString();
            if (value && ![null, undefined, '', primaryColumn].includes(cur.internalName)) {
                acc.push({
                    title: `${cur.title}:`,
                    value: value
                });
            }
            return acc;
        }, [{
            title: columns.find(item => item.isPrimaryID).title,
            value: data[primaryColumn]
        }]);
        const body = [
            {
                'type': 'TextBlock',
                'size': 'Medium',
                'text': data[columns.find(item => item.isPrimaryColumn).internalName] ?? '',
                'weight': 'Bolder',
                'spacing': 'none'
            },
            {
                'type': 'FactSet',
                'facts': fact
            }
        ]
        return body;
    }

    bindingAdaptiveCardData(columnObject, data) {
        const formatValue = data[`${columnObject.internalName}_value@OData.Community.Display.V1.FormattedValue`] ?? data[`${columnObject.internalName}@OData.Community.Display.V1.FormattedValue`] ?? data[columnObject.internalName] ?? '';
        return formatValue;
    }

    registerResources() {
        this.iconRegistry.addSvgIcon('icon_add', this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/icon-add-wp.svg'));
    }

    initGlobalWorksheetState$() {
        return new Observable<any>((observer) => {
            this.appState.excel[DefaultSettingsWorksheet._key] = DefaultSettingsWorksheet;
            this.appState.excel[DefaultMappingWorksheet._key] = DefaultMappingWorksheet;
            this.appState.excel[DefaultMappingWorksheetTable._key] = DefaultMappingWorksheetTable;
            this.state.commit(this.appState);
            observer.next();
            observer.complete();
        });
    }

    getOfficeSsoToken$() {
        return new Observable<any>((observer) => {
            this.officeApi.getIDToken$().then(res => {
                const { error, ssoToken } = res;
                if (!error) {
                    console.log('Office SSO Token:\n' + ssoToken);
                    this.appState.officeSsoToken = ssoToken;
                    this.state.commit(this.appState);
                    observer.next();
                    observer.complete();
                } else {
                    observer.error(error);
                }
            });
        });
    }

    ensureSettingsWorksheet$() {
        return new Observable<any>((observer) => {
            this.excelApi.ensureWorksheet$(DefaultSettingsWorksheet.name)
                .subscribe({
                    next: (worksheet) => {
                        if (worksheet) {
                            this.appState.excel[DefaultSettingsWorksheet._key]._added = true;
                            this.appState.excel[DefaultSettingsWorksheet._key].id = worksheet.id;
                            this.state.commit(this.appState);
                            observer.next();
                            observer.complete();
                        } else {
                            const worksheetName = DefaultSettingsWorksheet.name;
                            const worksheetActivate = false;
                            const worksheetProtection = true;
                            const worksheetHidden = DefaultSettingsWorksheet._hidden;
                            this.excelApi.addWorksheet$(worksheetName, worksheetActivate, worksheetProtection, worksheetHidden).subscribe({
                                next: (worksheet) => {
                                    this.appState.excel[DefaultSettingsWorksheet._key]._added = true;
                                    this.appState.excel[DefaultSettingsWorksheet._key].id = worksheet.id;
                                    this.state.commit(this.appState);
                                    observer.next();
                                    observer.complete();
                                }
                            });
                        }
                    }
                });
        });
    }

    ensureMappingWorksheet$() {
        return new Observable<any>((observer) => {
            this.excelApi.ensureWorksheet$(DefaultMappingWorksheet.name)
                .subscribe({
                    next: (worksheet) => {
                        if (worksheet) {
                            this.appState.excel[DefaultMappingWorksheet._key]._added = true;
                            this.appState.excel[DefaultMappingWorksheet._key].id = worksheet.id;
                            this.state.commit(this.appState);
                            observer.next();
                            observer.complete();
                        } else {
                            const worksheetName = DefaultMappingWorksheet.name;
                            const worksheetActivate = false;
                            const worksheetProtection = true;
                            const worksheetHidden = DefaultMappingWorksheet._hidden;
                            this.excelApi.addWorksheet$(worksheetName, worksheetActivate, worksheetProtection, worksheetHidden).subscribe({
                                next: (worksheet) => {
                                    this.appState.excel[DefaultMappingWorksheet._key]._added = true;
                                    this.appState.excel[DefaultMappingWorksheet._key].id = worksheet.id;
                                    this.state.commit(this.appState);
                                    observer.next();
                                    observer.complete();
                                }
                            });
                        }
                    }
                });
        });
    }

    ensureMappingWorksheetTable$() {
        return new Observable<any>((observer) => {
            const mappingWorksheetId = this.appState.excel[DefaultMappingWorksheet._key].id;
            const mappingWorksheetTableName = this.appState.excel[DefaultMappingWorksheetTable._key].name;
            this.excelApi.ensureTable$(mappingWorksheetId, mappingWorksheetTableName).subscribe({
                next: (table) => {
                    if (table) {
                        observer.next();
                        observer.complete();
                    } else {
                        concat(
                            this.excelApi.unprotectWorksheet$(mappingWorksheetId),
                            this.excelApi.insertTable$(mappingWorksheetId, {
                                name: mappingWorksheetTableName,
                                range: this.appState.excel[DefaultMappingWorksheetTable._key].range,
                                values: this.appState.excel[DefaultMappingWorksheetTable._key].values,
                                rows: [
                                    [
                                        this.appState.excel[DefaultSettingsWorksheet._key].id,
                                        this.appState.excel[DefaultSettingsWorksheet._key].name
                                    ],
                                    [
                                        this.appState.excel[DefaultMappingWorksheet._key].id,
                                        this.appState.excel[DefaultMappingWorksheet._key].name
                                    ]
                                ],
                                numberFormat: RangeObjectNumberFormatText
                            }),
                            this.excelApi.autoFitWorksheetColumnsAndRows$(mappingWorksheetId),
                            this.excelApi.protectWorksheet$(mappingWorksheetId)
                        ).pipe(
                            finalize(() => {
                                observer.next();
                                observer.complete();
                            })
                        ).subscribe();
                    }
                }
            });
        });
    }

    override registerCoreLayer() {
        this.appFacadeService.getListWorksheetConnected$().pipe(takeUntil(this.destroy$)).subscribe({
            next: res => {
                if (res !== null) {
                    this.listWorksheetConnected = Array.from(res, ([key, value]) => ({
                        text: key,
                        value: {
                            ...value,
                            icon: getIconSource(value.datasource)
                        },
                    }));
                }
            }
        });
        this.connectingWorksheet.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
            next: value => {
                if (value !== null) {
                    if (value !== '%add_connection%') {
                        this.excelApi.ensureWorksheet$(value).pipe(
                            switchMap(worksheet => {
                                if (worksheet) {
                                    const connectionTableName = this.excelApi.generateTableName(`Connection_${worksheet.name}`, true);
                                    return this.excelApi.ensureTable$(DefaultConnectionWorksheet.name, connectionTableName);
                                } else {
                                    this.notification.showError(`This worksheet has been removed or renamed!`);
                                    return of(null);
                                }
                            }),
                            switchMap((tableConnection) => {
                                if (tableConnection) {
                                    return this.excelApi.getActiveWorksheet$();
                                } else {
                                    return of(null);
                                }
                            })
                        ).subscribe({
                            next: (res) => {
                                if (res) {
                                    const result = res.name !== value;
                                    if (result) {
                                        this.excelApi.activeWorksheet$(value).pipe(
                                            finalize(() => {
                                                this.onWorksheetChangedHandle(value);
                                            })
                                        ).subscribe();
                                    } else {
                                        this.onWorksheetChangedHandle(value);
                                    }
                                }
                            }
                        });
                    } else {
                        this.router.navigate([`/connection`]);
                    }
                }
            }
        });
        this.appFacadeService.getWorksheetConnecting$().pipe(takeUntil(this.destroy$)).subscribe({
            next: value => {
                if (value) {
                    this.layoutState = 'main-flow';
                    const previousValue = this.connectingWorksheet.value;
                    const currentValue = value['worksheetName'];
                    if (previousValue !== currentValue) {
                        this.connectingWorksheet.patchValue(currentValue, { emitEvent: false, onlySelf: false });
                        this.cdr.detectChanges();
                    }
                }
            }
        });
        this.appFacadeService.getWorksheetOnActiveChanged$().pipe(takeUntil(this.destroy$)).subscribe({
            next: (value) => {
                const lastWorksheet = this.connectingWorksheet.value;
                if (value !== lastWorksheet) {
                    this.connectingWorksheet.patchValue(value, { emitEvent: false });
                    //handle event when worksheet changed inside here for case user back to current sheet
                    this.onWorksheetChangedHandle(value);
                }
                this.cdr.detectChanges();
            }
        });
        this.appFacadeService.getEventWorksheetActiveResult$().pipe(takeUntil(this.destroy$)).subscribe({
            next: result => {
                this.eventWorksheetActiveResult = result;
            }
        });
        this.appFacadeService.getAppActionMessageLog$().pipe(takeUntil(this.destroy$)).subscribe({
            next: log => {
                if (log) {
                    this.actionMessageLog = [...log].reverse();
                }
            }
        });
        this.appFacadeService.getOnUploadChanged$().pipe(takeUntil(this.destroy$)).subscribe({
            next: (res) => {
                if (res) {
                    this.appFacadeService.processUploadFile$(res.files, res.sessionKey).subscribe({
                        next: (result: any) => {
                            this.appFacadeService.setProcessUpload({
                                worksheet: res.worksheetName,
                                itemId: res.itemId,
                                total: result.total,
                                current: result.current,
                                isAttachments: res.isAttachments,
                                address: res.address,
                                columnNameAddress: res.columnNameAddress
                            });
                        }
                    });
                }
            }
        });
        this.appFacadeService.getProcessUpload$().pipe(takeUntil(this.destroy$)).subscribe({
            next: (res) => {
                if (res) {
                    const { worksheet, itemId, total, current, isAttachments, address, columnNameAddress } = res;
                    this.processUploadStatusCell(worksheet, itemId, current, total, isAttachments, address, columnNameAddress);
                }
            }
        });
        this.appFacadeService.getSocketEmitter$().pipe(takeUntil(this.destroy$)).subscribe({
            next: (res) => {

            }
        });
    }

    onChangeConnection(value) {
        if (value !== null) {
            this.layoutState = 'main-flow';
            this.connectingWorksheet.patchValue(value);
        }
    }

    reigsterSsoTokenSchedule() {
        //detect token is expired or not per 1 min
        timer(60000, 60000).subscribe({
            next: t => {
                if (this.state.currentState.officeSsoToken && this.state.currentState.officeSsoToken !== '') {
                    const ssoTokenDecode: any = jwt_decode(this.state.currentState.officeSsoToken);
                    const expiredTime = ssoTokenDecode?.exp;
                    if (expiredTime) {
                        if (new Date().getTime() > Number(`${expiredTime}000`)) {
                            this.getOfficeSsoToken$().subscribe();
                        }
                    } else {
                        this.getOfficeSsoToken$().subscribe();
                    }
                } else {
                    this.getOfficeSsoToken$().subscribe();
                }
            }
        })
    }

    onMenuClick(e) {
        e.preventDefault();
        this.layoutState = 'list-connections';
        this.cdr.detectChanges();
    }

    onLogClick(e) {
        e.preventDefault();
        this.layoutState = 'log-message';
        this.cdr.detectChanges();
    }

    onCloseClick(e) {
        e.preventDefault();
        this.layoutState = 'main-flow';
        this.cdr.detectChanges();
    }

    onGetInformation(connectionData) {
        let dialogContent = connectionData.objectContext;
        this.dialog.open(DataSourceInfoDialogComponent,
            {
                disableClose: false,
                data: {
                    title: 'Information',
                    content: dialogContent,
                    state: this.appState
                },
                width: '200px',
            })
    }

    onDisconnect(connectionData) {
        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                title: `Information`,
                content: `<span style="color: var(--grayscale-500);">Disconnect '<strong>${connectionData.worksheetName}</strong>' will affect to the worksheet. What would you like to do?</span>`,
                actions: [
                    {
                        text: 'Delete',
                        primary: false,
                        action: () => {
                            this.disconnectWorksheet.bind(this)(connectionData, true);
                            confirmDialogRef.close();
                        }
                    },
                    {
                        text: 'Keep',
                        primary: true,
                        action: () => {
                            this.disconnectWorksheet.bind(this)(connectionData, false);
                            confirmDialogRef.close();
                        }
                    }
                ],
                state: this.appState,
            },
        });
    }

    disconnectWorksheet(connectionData: any, isDeleteWorksheet: boolean = false) {
        this.appFacadeService.removeWorksheetConnected(connectionData.worksheetName);
        this.excelApi.ensureWorksheet$(DefaultConnectionWorksheet.name).pipe(
            switchMap((worksheet) => {
                if (worksheet) {
                    return this.excelApi.unprotectWorksheet$(DefaultConnectionWorksheet.name);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return this.excelApi.deleteTable$(DefaultConnectionWorksheet.name, connectionData.connectionTableName);
            }),
            switchMap(() => {
                return this.excelApi.protectWorksheet$(DefaultConnectionWorksheet.name);
            }),
            switchMap(() => {
                const _eventResult = this.eventWorksheetActiveResult.get(connectionData.worksheetName);
                return this.excelApi.removeWorksheetActivedEvent$(_eventResult);
            }),
            switchMap(() => {
                const title = connectionData.objectContext.entity ?? connectionData.objectContext.list ?? connectionData.objectContext.title ?? connectionData.objectContext.name ?? connectionData.objectContext.channelName;
                const view = connectionData.objectContext.view;
                const objectTableName = this.excelApi.generateTableName(`${title}_${view}`);
                const newObjectTableName = this.excelApi.generateTableName(`DISCONNECTED_${title}_${view}`);
                return this.excelApi.renameTable$(objectTableName, newObjectTableName);
            })
        ).subscribe({
            next: () => {
                if (isDeleteWorksheet) {
                    this.excelApi.getNumberOfWorksheet$(true).pipe(
                        switchMap((count) => {
                            if (count > 1) {
                                return this.excelApi.deleteWorksheet$(connectionData.worksheetName);
                            } else {
                                return this.excelApi.addWorksheet$().pipe(
                                    switchMap(() => {
                                        return this.excelApi.deleteWorksheet$(connectionData.worksheetName);
                                    })
                                )
                            }
                        }),
                        finalize(() => {
                            if (this.listWorksheetConnected.length === 0) {
                                this.layoutState = 'main-flow';
                                this.router.navigate(['/connection'])
                            } else {
                                if (connectionData.worksheetName === this.connectingWorksheet.value) {
                                    this.connectingWorksheet.setValue('%add_connection%', { emitEvent: false });
                                    this.router.navigate(['/connection']);
                                }
                                // check is remove current worksheet
                            }
                        })
                    ).subscribe()
                } else {
                    if (this.listWorksheetConnected.length === 0) {
                        this.layoutState = 'main-flow';
                        this.router.navigate(['/connection']);
                    } else {
                        if (connectionData.worksheetName === this.connectingWorksheet.value) {
                            this.connectingWorksheet.setValue('%add_connection%', { emitEvent: false });
                            this.router.navigate(['/connection']);
                        }
                    }
                }
            }
        });
    }

    onRenameWorksheet(connectionData) {
        const renameDialogRef = this.dialog.open(RenameDialogComponent, {
            disableClose: true,
            data: {
                title: `New Name`,
                content: ``,
                actions: [
                    {
                        text: 'Cancel',
                        primary: false,
                        action: () => {
                            renameDialogRef.close();
                        }
                    },
                    {
                        text: 'Save',
                        primary: true,
                        action: () => {
                            if (renameDialogRef.componentInstance.controlNewName.valid) {
                                renameDialogRef.close(renameDialogRef.componentInstance.controlNewName.value);
                            }
                        }
                    }
                ],
                state: this.appState,
                listWorksheetConnected: this.listWorksheetConnected,
                currentWorksheetName: connectionData.worksheetName
            },
        });

        renameDialogRef.afterClosed().subscribe({
            next: newName => {
                if (newName) {
                    const newConnectionTableName = this.excelApi.generateTableName(`Connection_${newName}`, true);
                    this.excelApi.renameWorksheet$(connectionData.worksheetName, newName).pipe(
                        switchMap(() => {
                            return this.excelApi.ensureWorksheet$(DefaultConnectionWorksheet.name)
                        }),
                        switchMap((worksheet) => {
                            if (worksheet) {
                                return this.excelApi.unprotectWorksheet$(DefaultConnectionWorksheet.name);
                            } else {
                                return of(null);
                            }
                        }),
                        switchMap(() => {
                            return this.excelApi.renameTable$(connectionData.connectionTableName, newConnectionTableName);
                        }),
                        switchMap(() => {
                            return this.excelApi.protectWorksheet$(DefaultConnectionWorksheet.name);
                        }),
                        finalize(() => {
                            this.changeConnectionWorksheetName$({ newConnectionTableName: newConnectionTableName, newWorksheetName: newName }, connectionData).subscribe()
                        })
                    ).subscribe();
                }
            }
        })
    }

    changeConnectionWorksheetName$({
        newWorksheetName,
        newConnectionTableName
    }, connectionData) {
        return new Observable(obs => {
            const newConnectionData = { ...connectionData };
            newConnectionData['worksheetName'] = newWorksheetName;
            newConnectionData['connectionTableName'] = newConnectionTableName;
            this.appFacadeService.removeWorksheetConnected(connectionData.worksheetName);
            this.appFacadeService.setListWorksheetConnected(newConnectionData);
            this.appFacadeService.setEventWorksheetActiveResult(newWorksheetName, this.eventWorksheetActiveResult.get(connectionData.worksheetName));
            this.appFacadeService.removeEventWorksheetActiveResult(connectionData.worksheetName);
            if (this.connectingWorksheet.value === connectionData.worksheetName) {
                this.connectingWorksheet.patchValue(newWorksheetName, {
                    emitEvent: false
                });
            }
            this.appFacadeService.detactWorksheetRename(newWorksheetName, connectionData.worksheetName);
            obs.next();
            obs.complete();
        })
    }

    processUploadStatusCell(worksheet: string, itemId: string, current: any[], total: number, isAttachments: boolean, address, columnNameAddress?: string) {
        // const percent = parseFloat((current / total).toFixed(2));
        const unitPercent = 1 / total;
        const isFailed = current.find(item => item.isFailed);
        if (isFailed) {
            const requests$ = [
                this.excelApi.clearCellListValidation$(worksheet, `${address}:${address}`),
                this.excelApi.clearCellListConditionalFormatting$(worksheet, `${address}:${address}`),
                this.excelApi.setCellValue$(worksheet, address, isAttachments ? `click here to see attachments\u200B` : `{{ ${current[0].metadata.fileId} }}`),
                this.excelApi.setRangeNumberFormat$(worksheet, `${address}:${address}`, [['@']])
            ]
            if (!isAttachments) {
                requests$.push(this.excelApi.setCellValue$(worksheet, columnNameAddress, `${current[0].metadata.fileName}`));
            }
            concat(...requests$).subscribe();
            this.processStoreFileUpload(worksheet, address, isAttachments, current, itemId);
            return;
        }
        const percent = parseFloat(current.reduce((acc, cur) => {
            if (cur.total > 0) {
                const currentPercent = (unitPercent * (1 / cur.total) * cur.current);
                acc += currentPercent;
            }
            return acc;
        }, 0).toFixed(4));
        if (percent === 1) {
            const requests$ = [
                this.excelApi.clearCellListValidation$(worksheet, `${address}:${address}`),
                this.excelApi.clearCellListConditionalFormatting$(worksheet, `${address}:${address}`),
                this.excelApi.setCellValue$(worksheet, address, isAttachments ? `click here to see attachments\u200B` : `{{ ${current[0].metadata.fileId} }}`),
                this.excelApi.setRangeNumberFormat$(worksheet, `${address}:${address}`, [['@']])
            ]
            if (!isAttachments) {
                requests$.push(this.excelApi.setCellValue$(worksheet, columnNameAddress, `${current[0].metadata.fileName}`));
            }
            concat(...requests$).subscribe();
            this.processStoreFileUpload(worksheet, address, isAttachments, current, itemId);
            return;
        }
        if (current[0].current === 1) {
            const requests$ = [
                this.excelApi.clearCellListValidation$(worksheet, `${address}:${address}`),
                this.excelApi.clearCellListConditionalFormatting$(worksheet, `${address}:${address}`),
                this.excelApi.setCellValue$(worksheet, `${address}:${address}`, percent),
                this.excelApi.setRangeNumberFormat$(worksheet, `${address}:${address}`, [['0.00%']]),
                this.excelApi.setCellDataBarConditionalFormatting$(worksheet, address, 0, 1, '#74060E')
            ];
            concat(...requests$).subscribe();
        } else {
            this.excelApi.setCellValue$(worksheet, address, percent).subscribe();
        }
    }

    processStoreFileUpload(worksheet: string, address: string, isAttachments: boolean, current: any[], itemId: string) {
        const requests$ = current.reduce((acc: Observable<any>[], cur) => {
            if (cur.metadata) {
                acc.push(this.processFileUploadInfoTable$({
                    worksheet: worksheet,
                    metadata: cur.metadata,
                    address: address,
                    isAttachments: isAttachments,
                    itemId: itemId
                }));
            }
            return acc;
        }, []);
        concat(...requests$).pipe(
            finalize(() => {
                this.appFacadeService.setProcessUploadCompleted({
                    worksheet: worksheet,
                    metadata: [...current].map(item => item.metadata),
                    address: address,
                    itemId: itemId
                })
            })
        ).subscribe();
    }
}
