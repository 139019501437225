export const AppvityTaskColumnsDefaultProperties = [
    {
        'name': '_id',
        'displayName': 'ID',
        'hidden': false,
        'dataType': 'String',
        'readOnly': true,
        'require': true
    },
    {
        'name': 'key',
        'displayName': 'ID',
        'hidden': false,
        'dataType': 'String',
        'readOnly': true
    },
    {
        'name': 'name',
        'displayName': 'Title',
        'hidden': false,
        'dataType': 'String',
        'readOnly': false,
        'require': true
    },
    {
        'name': 'startDate',
        'displayName': 'Start Date',
        'hidden': false,
        'dataType': 'DateTime',
        'readOnly': false,
        'require': false
    },
    {
        'name': 'dueDate',
        'displayName': 'Due Date',
        'hidden': false,
        'dataType': 'DateTime',
        'readOnly': false,
        'require': false
    },
    {
        'name': 'createdAt',
        'displayName': 'Created',
        'hidden': false,
        'dataType': 'DateTime',
        'readOnly': true,
        'require': false
    },
    {
        'name': 'completedDate',
        'displayName': 'Completed Date',
        'hidden': false,
        'dataType': 'DateTime',
        'readOnly': true,
        'require': true
    },
    {
        'name': 'priority',
        'displayName': 'Priority',
        'hidden': false,
        'dataType': 'String',
        'readOnly': false,
        'require': true
    },
    {
        'name': 'status',
        'displayName': 'Status',
        'hidden': false,
        'dataType': 'String',
        'readOnly': false,
        'require': true
    },
    {
        'name': 'assignedTo',
        'displayName': 'Assignee',
        'hidden': false,
        'dataType': 'Array<User>',
        'readOnly': false,
        'require': false
    },
    {
        'name': 'phaseName',
        'displayName': 'Phase',
        'hidden': false,
        'dataType': 'String',
        'readOnly': false,
        'require': false
    },
    {
        'name': 'bucketName',
        'displayName': 'Story',
        'hidden': false,
        'dataType': 'String',
        'readOnly': false,
        'require': false
    },
    {
        'name': 'duration',
        'displayName': 'Duration',
        'hidden': false,
        'dataType': 'Number',
        'readOnly': false,
        'require': true
    },
    {
        'name': 'complete',
        'displayName': 'Completion',
        'hidden': false,
        'dataType': 'Number',
        'readOnly': false,
        'require': true
    },
    {
        'name': 'source',
        'displayName': 'Source',
        'hidden': false,
        'dataType': 'String',
        'readOnly': false,
        'require': true
    },
    {
        'name': 'updatedAt',
        'displayName': 'Updated',
        'hidden': false,
        'dataType': 'DateTime',
        'readOnly': true,
        'require': false
    },
    {
        'name': 'createdBy',
        'displayName': 'Created by',
        'hidden': false,
        'dataType': 'User',
        'readOnly': true,
        'require': true
    },
];