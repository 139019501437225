export interface IUPS {
    [k: string]: any;
    upsId: string,
    title: string,
    description: string,
}

export interface IUPSProperty {
    '_id': string,
    'name': string,
    'displayName': string,
    'type': 'Built-in' | 'System' | 'Application',
    'dataType': string,
    'length': number,
    'description': string,
    'category': any,
    'defaultValue': any,
    'isRequired': boolean,
    'isActive': boolean,
    'privacy': string,
    'allowUpdate': string,
    'mapping': boolean,
    'mappingInfo': {
        'source': string,
        'field': string
    }[]
}

export interface IUPSView {
    [k: string]: any;
    id: string;
    title: string;
    fields: string[];
}

export interface IUPSSettings extends IUPS {
    [k: string]: any;
    views?: IUPSView[];
    columns: IUPSProperty[];
    viewId?: string;
    viewName?: string;
}

export const UPSInfo = {
    'upsId': '689084a1-9635-4ced-91d2-4507d30dcc44',
    'title': 'Users Profile Service',
    'description': 'Users Profile Service for Excel Add-in',
}

export const AppvityUPSDefaultSettingsColumnsTable = {
    name: '',
    range: '',
    values: [['_id', 'name', 'displayName', 'type', 'dataType', 'length', 'description', 'category', 'defaultValue', 'isRequired', 'isActive', 'privacy', 'allowUpdate', 'mapping', 'mappingInfo']],
    rows: [['', '', '', '', '', '', '', '', '', '', '', '', '', '', '']]
}

export const AppvityUPSDefaultSettingsTable = {
    name: '',
    range: '',
    values: [['upsId', 'title', 'viewId', 'viewName', 'columns']],
    rows: [['', '', '', '', '']]
}

export const UPSDefaultView: IUPSView[] = [{
    id: '00000000-0000-0000-0000-000000000000',
    title: 'Default View',
    fields: [
        '_id',
        'username',
        'displayName',
        'userType',
        'nickName',
        'department',
        'color',
        'birthday',
        'jobTitle',
        'employeeHireDate',
        'companyName',
        'manager',
        'email',
        'otherMails',
        'mobilePhone',
        'city',
        'state',
        'officeLocation',
        'businessPhones',
        'faxNumber',
        'streetAddress',
        'country',
        'postalCode',
        'aboutMe',
    ]
}]

export const UPSPropertiesDefault: IUPSProperty[] = [
    {
        '_id': '663ca6bc000000e00f00f00d',
        'name': '_id',
        'displayName': 'ID',
        'type': 'Built-in',
        'dataType': 'String',
        'length': 50,
        'description': '',
        'category': 'System',
        'defaultValue': null,
        'isRequired': true,
        'isActive': true,
        'privacy': 'Public',
        'allowUpdate': 'Read Only',
        'mapping': false,
        'mappingInfo': []
    }
]