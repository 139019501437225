<div id="app-body" (click)="cdr.detectChanges()">
    <div *ngIf="!appState.onOnline" class="__app-network-notification"><span>No internet connection</span></div>
    <div class="__app-menu-header" *ngIf="layoutState === 'main-flow'">
        <span class="__app-icon-menu-block" (click)="onMenuClick($event)" matTooltip="Connections">
            <img src="/assets/icons/icon-menu.svg" />
        </span>
    </div>
    <div class="__app-list-of-connections" *ngIf="layoutState === 'list-connections'">
        <div class="__app-list-of-connection-header">
            <span class="__app-icon-close-block" (click)="onCloseClick($event)">
                <img src="/assets/icons/icon-back.svg" />
            </span>
            <span class="__app-connection-title">Connections</span>
        </div>
        <div class="__app-list-of-connection-content">
            <div class="__app-connection-add" *ngIf="router.url !== '/connection'"
                (click)="onChangeConnection('%add_connection%')">
                <img src="/assets/icons/icon-add-wp.svg" /><span>New Connection</span>
            </div>
            <ng-container *ngFor="let connection of listWorksheetConnected; trackBy: trackByFn">
                <div class="__app-connection-block">
                    <div class="__app-worksheet-name-block"
                        [ngClass]="connectingWorksheet.value === connection.text ? 'selected' : ''"
                        (click)="onChangeConnection(connection.text)">
                        <img [src]="connection.value.icon" />
                        <span style="overflow-wrap: anywhere; padding: 6px 0;">{{ connection.text }}</span>
                    </div>
                    <div class="__app-more-action-block" [matMenuTriggerFor]="menu">
                        <img src="/assets/icons/icon-more.svg" />
                    </div>
                    <mat-menu #menu="matMenu" class="__app-menu-panel">
                        <div mat-menu-item class="__app-action-menu" (click)="onGetInformation(connection.value)">
                            <img src="/assets/icons/icon-information.svg" />
                            <span style="font-size: var(--font-size);">Information</span>
                        </div>
                        <div mat-menu-item class="__app-action-menu" (click)="onRenameWorksheet(connection.value)">
                            <img src="/assets/icons/icon-edit.svg" />
                            <span style="font-size: var(--font-size);">Rename</span>
                        </div>
                        <div mat-menu-item class="__app-action-menu" (click)="onDisconnect(connection.value)">
                            <img src="/assets/icons/icon-disconnect.svg" />
                            <span style="font-size: var(--font-size);">Disconnect</span>
                        </div>
                    </mat-menu>
                </div>
            </ng-container>
            <ng-container *ngIf="listWorksheetConnected.length === 0">
                <div class="__app-no-connection-block" style="color: var(--grayscale-500);">
                    <span>
                        No active connections
                    </span>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="__app-log-message" *ngIf="layoutState === 'log-message'">
        <div class="__app-log-message-header">
            <span class="__app-icon-close-block" (click)="onCloseClick($event)">
                <img src="/assets/icons/icon-back.svg" />
            </span>
            <span class="__app-log-message-title">Messages</span>
        </div>
        <div class="__app-log-message-content">
            <ng-container *ngFor="let message of actionMessageLog">
                <div class="__app-log-message-block">
                    <div class="__app-log-message-time">
                        {{ message.time }}
                    </div>
                    <div class="__app-log-message-content-info">
                        <div class="__app-log-message-icon" *ngIf="message.status === 'info'">
                            <mat-icon>check_circle</mat-icon>
                        </div>
                        <div class="__app-log-message-icon invalid" *ngIf="message.status === 'error'">
                            <mat-icon>cancel</mat-icon>
                        </div>
                        <div class="__app-log-message-text">
                            <span>{{ message.message }}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="actionMessageLog.length === 0">
                <div class="__app-no-message-block" style="color: var(--grayscale-500);">
                    <span>
                        No messages
                    </span>
                </div>
            </ng-container>
        </div>
    </div>
    <router-outlet />
</div>
<app-footer (onLogFlagClick)="onLogClick($event)" />