import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, inject } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDataSourceInfoDialogData } from './schemas/datasource-info-dialog.config';
import { ResizeObserverService } from '@utils/services';

@Component({
    selector: 'app-datasource-info-dialog',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule
    ],
    templateUrl: './datasource-Info-dialog.component.html',
    styleUrls: ['./datasource-Info-dialog.component.scss']
})
export class DataSourceInfoDialogComponent implements OnDestroy {
    constructor(@Inject(MAT_DIALOG_DATA) public data: IDataSourceInfoDialogData) { 
        this.registerResizeObserver();
        this.panelSize = data.state.panelSize;
    }
    resizeObserver = inject(ResizeObserverService);
    panelSize = '';
    cdr = inject(ChangeDetectorRef);
    
    trackByFn(index: number) {
        return index;
    }

    registerResizeObserver() {
        this.resizeObserver.observe(document.querySelector('body'), (width, _) => {
            if (width > 500) {
                this.panelSize = 'large-size';
            } else {
                this.panelSize = 'medium-size';
            }
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy() {
        this.resizeObserver.unobserve(document.querySelector('body'));
    }
}
