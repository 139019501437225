import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppStateService {
    private worksheetConnecting$ = new Subject<any>();
    private listWorksheetConnected$ = new Subject<Map<string, any>>();
    private __listWorksheetConnectingMap = new Map<string, any>();
    private worksheetRename$ = new Subject<{
        oldName: string,
        newName: string
    }>();
    private eventWorksheetActiveResult$ = new Subject<Map<string, any>>();
    private __eventWorksheetActiveResultMap = new Map<string, any>();
    worksheetOnClickAttach$ = new Subject<any>();

    private __worksheetOnActivechanged$ = new Subject<string>();

    private __worksheetOnRowInsert$ = new Subject<any>();
    private __worksheetOnRowDelete$ = new Subject<any>();
    private __worksheetOnRangeEdit$ = new Subject<any>();
    private __excelDialogMessageReceived$ = new Subject<any>();

    private __appActionMessageLog = []
    private actionMessageLog = new Subject<any[]>();

    private __onUploadChanged$ = new Subject<any>();
    private __socketEmitter$ = new Subject<any>();
    private __processUpload$ = new Subject<any>();
    private __processUploadCompleted$ = new Subject<any>();

    getWorksheetConnecting$() {
        return this.worksheetConnecting$.asObservable();
    }

    setWorksheetConnecting(worksheet) {
        this.worksheetConnecting$.next(worksheet);
    }

    getEventWorksheetActiveResult$() {
        return this.eventWorksheetActiveResult$.asObservable();
    }

    setEventWorksheetActiveResult(worksheetName, eventResult) {
        this.__eventWorksheetActiveResultMap.set(worksheetName, eventResult);
        this.eventWorksheetActiveResult$.next(this.__eventWorksheetActiveResultMap);
    }

    removeEventWorksheetActiveResult(key) {
        this.__eventWorksheetActiveResultMap.delete(key);
        this.eventWorksheetActiveResult$.next(this.__eventWorksheetActiveResultMap);
    }

    getListWorksheetConnected$() {
        return this.listWorksheetConnected$.asObservable();
    }

    setListWorksheetConnected(value: {
        worksheetName,
        connectionTableName,
        datasource,
        objectContext,
    }) {
        this.__listWorksheetConnectingMap.set(value.worksheetName, value)
        this.listWorksheetConnected$.next(this.__listWorksheetConnectingMap);
    }

    removeWorksheetConnected(key) {
        this.__listWorksheetConnectingMap.delete(key);
        this.listWorksheetConnected$.next(this.__listWorksheetConnectingMap);
    }

    detactWorksheetRename(newName, oldName) {
        this.worksheetRename$.next({
            newName,
            oldName
        })
    }

    getDetactWorksheetRenameTrigger$() {
        return this.worksheetRename$.asObservable()
    }

    getWorksheetOnClickAttach$() {
        return this.worksheetOnClickAttach$.asObservable();
    }

    getAppActionMessageLog$() {
        return this.actionMessageLog.asObservable();
    }

    setAppActionMessageLog(value) {
        this.__appActionMessageLog.push(value);
        this.actionMessageLog.next(this.__appActionMessageLog);
    }

    clearAppActionMessageLog() {
        this.__appActionMessageLog = [];
        this.actionMessageLog.next(this.__appActionMessageLog);
    }

    setWorksheetOnClickAttach({
        worksheetName,
        event
    }) {
        this.worksheetOnClickAttach$.next({
            worksheetName,
            event
        });
    }

    getWorksheetOnRowInsert$() {
        return this.__worksheetOnRowInsert$.asObservable();
    }

    setWorksheetOnRowInsert(value) {
        this.__worksheetOnRowInsert$.next(value);
    }

    getWorksheetOnRowDelete$() {
        return this.__worksheetOnRowDelete$.asObservable();
    }

    setWorksheetOnRowDelete(value) {
        this.__worksheetOnRowDelete$.next(value);
    }

    getWorksheetOnRangeEdit$() {
        return this.__worksheetOnRangeEdit$.asObservable();
    }

    setWorksheetOnRangeEdit({ worksheetName, event }) {
        this.__worksheetOnRangeEdit$.next({ worksheetName, event });
    }

    getWorksheetOnActiveChanged$() {
        return this.__worksheetOnActivechanged$.asObservable();
    }

    setWorksheetOnActiveChanged(worksheetName: string) {
        this.__worksheetOnActivechanged$.next(worksheetName);
    }

    
    setExcelDialogMessageReceiver(message: string, type: 'info' | 'error' = 'info') {
        this.__excelDialogMessageReceived$.next({ message, type });
    }

    getExcelDialogMessageReceiver$() {
        return this.__excelDialogMessageReceived$.asObservable();
    }

    setOnUploadChanged(value) {
        this.__onUploadChanged$.next(value);
    }

    getOnUploadChanged$() {
        return this.__onUploadChanged$.asObservable();
    }

    setSocketEmitter(value) {
        this.__socketEmitter$.next(value);
    }

    getSocketEmitter$() {
        return this.__socketEmitter$.asObservable();
    }

    setProcessUpload(value) {
        this.__processUpload$.next(value);
    }

    getProcessUpload$() {
        return this.__processUpload$.asObservable();
    }

    setProcessUploadCompleted(value) {
        this.__processUploadCompleted$.next(value);
    }

    getProcessUploadCompleted$() {
        return this.__processUploadCompleted$.asObservable();
    }
    
}