import { Injectable } from '@angular/core';
import { forkJoin, map, tap } from 'rxjs';
import { AppvityProjectSourceAvailable, IAppvityCoreView } from '../../schemas/appvity.schema';
import { sortBy } from 'lodash';
import { AppvityCoreService } from './appvity-core.service';

@Injectable({
    providedIn: 'root'
})
export class ETaskService extends AppvityCoreService {

    getProjectList$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/teams/getChannels`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('ETaskService', 'getProjectList', res);
            })
        );
    }

    getProject$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/teams/getChannels`;
        return forkJoin([
            this.http.getItems(requestURL, requestHeaders, requestParams).pipe(map(res => res.value[0])),
            this.getProjectGeneralSettings$(requestHeaders, requestParams)
        ]).pipe(
            map(res => {
                let result = {}
                const project = res[0];
                Object.keys(project).forEach((key) => {
                    result[key] = project[key];
                });
                result['generalSettings'] = res[1];
                return result;
            }),
            tap(res => {
                this.log.log('ETaskService', 'getProject', res);
            })
        );
    }

    getProjectGeneralSettings$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/_configs/project`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                const sources = Object.keys(res).filter((key) => AppvityProjectSourceAvailable.includes(key));
                const generalSettings = { ...res.projectSetting, sources }
                return generalSettings
            }),
            tap(res => {
                this.log.log('ETaskService', 'getProjectGeneralSettings', res);
            })
        );
    }

    getProjectTaskViews$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/views`;
        if (requestParams == null) {
            requestParams = {
                $filter: `_model eq 'task' and type ne 'pinned'`,
                $select: '_id,title,value,createdBy,createdAt,updatedAt,type,order,isDefault,isShow,isActive,userId,viewId,_model'
            }
        }
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                return res.value.reduce((acc: IAppvityCoreView[], cur) => {
                    acc.push({
                        id: cur._id,
                        title: cur.viewId.title,
                        fields: cur.viewId.value.grid.displayColumns.map((field) => field.name),
                        extendsion: {
                            conditions: cur.viewId.value.conditions,
                            maxRow: cur.viewId.value.maxRows,
                            sortBy: cur.viewId.value.sortBy,
                            viewType: cur.type,
                        }
                    });
                    return acc;
                }, []);
            }),
            map(res => sortBy(res, (item) => item.title.toLowerCase())),
            tap(res => {
                this.log.log('ETaskService', 'getProjectTaskViews', res);
            })
        )
    }

    getProjectBugViews$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/views`;
        if (requestParams == null) {
            requestParams = {
                $filter: `_model eq 'bug' and type ne 'pinned'`,
                $select: '_id,title,value,createdBy,createdAt,updatedAt,type,order,isDefault,isShow,isActive,userId,viewId,_model'
            }
        }
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                return res.value.reduce((acc: IAppvityCoreView[], cur) => {
                    acc.push({
                        id: cur._id,
                        title: cur.viewId.title,
                        fields: cur.viewId.value.grid.displayColumns.map((field) => field.name),
                        extendsion: {
                            conditions: cur.viewId.value.conditions,
                            maxRow: cur.viewId.value.maxRows,
                            sortBy: cur.viewId.value.sortBy,
                            viewType: cur.type,
                        }
                    });
                    return acc;
                }, []);
            }),
            map(res => sortBy(res, (item) => item.title.toLowerCase())),
            tap(res => {
                this.log.log('ETaskService', 'getProjectBugViews', res);
            }));
    }

    getProjectTasks$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/tasks`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('ETaskService', 'getProjectTasks', res);
            }));
    }

    getProjectBugs$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/bugs`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('ETaskService', 'getProjectBugs', res);
            })
        );
    }

    getProjectTaskByIds$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/tasksById`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('ETaskService', 'getProjectTaskByIds', res);
            }));
    }

    getProjectBugByIds$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/bugsById`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('ETaskService', 'getProjectBugByIds', res);
            }));
    }

    getProjectSetting_Status$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/status`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                const taskStatus = res.value.filter((item) => item.type === 'Task');
                const bugStatus = res.value.filter((item) => item.type === 'Bug');
                return { taskStatus, bugStatus }
            }),
            tap(res => {
                this.log.log('ETaskService', 'getProjectSetting_Status', res);
            })
        );
    }

    getProjectSetting_Priority$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/priority`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                const taskPriority = res.value.filter((item) => item.type === 'Task');
                const bugPriority = res.value.filter((item) => item.type === 'Bug');
                return { taskPriority, bugPriority }
            }),
            tap(res => {
                this.log.log('ETaskService', 'getProjectSetting_Priority', res);
            }))
    }

    getProjectSetting_Severity$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/severity`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('ETaskService', 'getProjectSetting_Severity', res);
            })
        );
    }

    getProjectSetting_Phase$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/phases`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                const phases = res.value;
                const phase = res.value.map((item) => item._id);
                const phaseName = res.value.map((item) => item.phaseName);
                return { phases, phase, phaseName }
            }),
            tap(res => {
                this.log.log('ETaskService', 'getProjectSetting_Phase', res);
            })
        );
    }

    getProjectSetting_Story$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/stories`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                const stories = res.value;
                const story = res.value.map((item) => item._id);
                const storyName = res.value.map((item) => item.bucketName);
                return { stories, story, storyName }
            }),
            tap(res => {
                this.log.log('ETaskService', 'getProjectSetting_Story', res);
            })
        );
    }

    getProjectSetting_User$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/mappings/user`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('ETaskService', 'getProjectSetting_User', res);
            })
        );
    }

    getProjectSetting_Field$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/fields`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('ETaskService', 'getProjectSetting_Field', res);
            })
        );
    }

    getProjectSetting_ColorStyle$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/fields/colorStyle`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            tap(res => {
                this.log.log('ETaskService', 'getProjectSetting_ColorStyle', res);
            })
        );
    }

    getProjectSetting_Source$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/projects`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('ETaskService', 'getProjectSetting_Source', res);
            })
        );
    }

    deleteDataItem$(requestHeaders: any, dataItemId) {
        const requestURL = `${this.state.currentState.domain}/odata/tasks(${dataItemId})`;
        return this.http.deleteItem(requestURL, null, requestHeaders).pipe(
            tap(res => {
                this.log.log('ETaskService', 'deleteDataItem$', res);
            })
        );
    }

    updateDataItem$(requestHeaders: any, dataItem, dataItemId: string) {
        const requestURL = `${this.state.currentState.domain}/odata/tasks(${dataItemId})`;
        return this.http.updateItem(requestURL, dataItem, requestHeaders).pipe(
            tap(res => {
                this.log.log('ETaskService', 'updateDataItem$', res);
            })
        );
    }

    createDataItem$(requestHeaders: any, dataItem) {
        const requestURL = `${this.state.currentState.domain}/odata/tasks`;
        return this.http.addItem(requestURL, dataItem, requestHeaders).pipe(
            tap(res => {
                this.log.log('ETaskService', 'createDataItem$', res);
            })
        );
    }

    createListTaskDataItems$(requestHeaders: any, dataItems) {
        const requestURL = `${this.state.currentState.domain}/api/v2/create/tasks`;
        return this.http.addItem(requestURL, dataItems, requestHeaders).pipe(
            tap(res => {
                this.log.log('ETaskService', 'createListTaskDataItems$', res);
            })
        );
    }

    updateListTaskDataItems$(requestHeaders: any, dataItems) {
        const requestURL = `${this.state.currentState.domain}/api/v2/update/tasks`;
        return this.http.addItem(requestURL, dataItems, requestHeaders).pipe(
            tap(res => {
                this.log.log('ETaskService', 'updateListTaskDataItems$', res);
            })
        );
    }

    deleteListTaskDataItem$(requestHeaders: any, dataItemIds) {
        const requestURL = `${this.state.currentState.domain}/api/v2/delete/tasks`;
        return this.http.addItem(requestURL, dataItemIds, requestHeaders).pipe(
            tap(res => {
                this.log.log('ETaskService', 'deleteListTaskDataItem$', res);
            })
        );
    }

    createListBugDataItems$(requestHeaders: any, dataItems) {
        const requestURL = `${this.state.currentState.domain}/api/v2/create/bugs`;
        return this.http.addItem(requestURL, dataItems, requestHeaders).pipe(
            tap(res => {
                this.log.log('ETaskService', 'createListBugDataItems$', res);
            })
        );
    }

    updateListBugDataItems$(requestHeaders: any, dataItems) {
        const requestURL = `${this.state.currentState.domain}/api/v2/update/bugs`;
        return this.http.addItem(requestURL, dataItems, requestHeaders).pipe(
            tap(res => {
                this.log.log('ETaskService', 'updateListBugDataItems$', res);
            })
        );
    }

    deleteListBugDataItem$(requestHeaders: any, dataItemIds) {
        const requestURL = `${this.state.currentState.domain}/api/v2/delete/bugs`;
        return this.http.addItem(requestURL, dataItemIds, requestHeaders).pipe(
            tap(res => {
                this.log.log('ETaskService', 'deleteListBugDataItem$', res);
            })
        );
    }

    generateDeepLink(dataItem: {
        name: string,
        _id: string
    },
        target: 'task' | 'bug' = 'task') {
        let webUrl = this.state.currentState.domain + `/app/web/${target}/`;
        let entityTarget = target + '_' + dataItem._id.toString();
        // microsoftTeams.shareDeepLink({ subEntityId: entityTarget, subEntityLabel: dataItem.name, subEntityWebUrl: webUrl });

    }
}
