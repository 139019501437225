import { Injectable, inject } from "@angular/core";
import { AppStateService } from "../core/app-state.service";
import { AppApiService } from "../core/app-api.service";

@Injectable({
    providedIn: 'root'
})
export class AppFacadeService {
    appStateService = inject(AppStateService);
    appApiService = inject(AppApiService);

    getWorksheetConnecting$() {
        return this.appStateService.getWorksheetConnecting$();
    }

    setWorksheetConnecting(worksheet) {
        this.appStateService.setWorksheetConnecting(worksheet);
    }

    getListWorksheetConnected$() {
        return this.appStateService.getListWorksheetConnected$();
    }

    setEventWorksheetActiveResult(worksheet, eventResult) {
        this.appStateService.setEventWorksheetActiveResult(worksheet, eventResult);
    }

    getEventWorksheetActiveResult$() {
        return this.appStateService.getEventWorksheetActiveResult$();
    }

    removeEventWorksheetActiveResult(worksheet) {
        return this.appStateService.removeEventWorksheetActiveResult(worksheet);
    }

    setListWorksheetConnected(value) {
        this.appStateService.setListWorksheetConnected(value);
    }

    removeWorksheetConnected(value) {
        this.appStateService.removeWorksheetConnected(value);
    }

    detactWorksheetRename(newName, oldName) {
        this.appStateService.detactWorksheetRename(newName, oldName);
    }

    getDetactWorksheetRename$() {
        return this.appStateService.getDetactWorksheetRenameTrigger$()
    }

    getWorksheetOnClickAttach$() {
        return this.appStateService.getWorksheetOnClickAttach$();
    }

    getAppActionMessageLog$() {
        return this.appStateService.getAppActionMessageLog$();
    }

    setAppActionMessageLog(value) {
        this.appStateService.setAppActionMessageLog(value);
    }

    clearAppActionMessageLog() {
        this.appStateService.clearAppActionMessageLog();
    }

    setWorksheetOnClickAttach({
        worksheetName,
        event
    }) {
        this.appStateService.setWorksheetOnClickAttach({
            worksheetName,
            event
        });
    }

    getWorksheetOnRowInserted$() {
        return this.appStateService.getWorksheetOnRowInsert$();
    }

    setWorksheetOnRowInserted(worksheetName: string) {
        this.appStateService.setWorksheetOnRowInsert(worksheetName);
    }

    getWorksheetOnRowDeleted$() {
        return this.appStateService.getWorksheetOnRowDelete$();
    }

    setWorksheetOnRowDeleted(worksheetName: string) {
        this.appStateService.setWorksheetOnRowDelete(worksheetName);
    }

    getWorksheetOnRangeEdited$() {
        return this.appStateService.getWorksheetOnRangeEdit$();
    }

    setWorksheetOnRangeEdited(worksheetName: string, event) {
        this.appStateService.setWorksheetOnRangeEdit({ worksheetName: worksheetName, event: event});
    }

    getWorksheetOnActiveChanged$() {
        return this.appStateService.getWorksheetOnActiveChanged$();
    }

    setWorksheetOnActiveChanged(worksheetName: string) {
        this.appStateService.setWorksheetOnActiveChanged(worksheetName);
    }

    
    setExcelDialogMessageReceiver(message: string, type: 'info' | 'error' = 'info') {
        this.appStateService.setExcelDialogMessageReceiver( message, type );
    }

    getExcelDialogMessageReceiver$() {
        return this.appStateService.getExcelDialogMessageReceiver$();
    }

    setOnUploadChanged(value) {
        this.appStateService.setOnUploadChanged(value);
    }

    getOnUploadChanged$() {
        return this.appStateService.getOnUploadChanged$();
    }

    processUploadFile$(files: File[], sessionKey: string) {
        return this.appApiService.processUploadFile$(files, sessionKey);
    }

    setSocketEmitter(value) {
        this.appStateService.setSocketEmitter(value);
    }

    getSocketEmitter$() {
        return this.appStateService.getSocketEmitter$();
    }

    setProcessUpload(value) {
        this.appStateService.setProcessUpload(value);
    }

    getProcessUpload$() {
        return this.appStateService.getProcessUpload$();
    }

    setProcessUploadCompleted(value) {
        this.appStateService.setProcessUploadCompleted(value);
    }

    getProcessUploadCompleted$() {
        return this.appStateService.getProcessUploadCompleted$();
    }
}