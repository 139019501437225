import { Injectable } from '@angular/core';
import { Observable, concat, map, of, tap } from 'rxjs';
import { AppvityCoreService } from './appvity-core.service';
import { sortBy } from 'lodash';
import { IAppvityCoreView, IAppvityProjectProps } from '@common/schemas';

@Injectable({
    providedIn: 'root'
})
export class EFilesService extends AppvityCoreService {


    getProjectList$(requestHeaders, requestParams) {
        const requestURL = `${this.state.currentState.domain}/api/v2/product`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                return res.value.reduce((acc: IAppvityProjectProps[], cur) => {
                    const project: IAppvityProjectProps = {
                        _id: cur._id,
                        tenantId: cur.tenantId,
                        channelId: cur.channelId.channelId,
                        channelName: cur.channelId.channelName,
                        teamId: cur.channelId.teamId,
                        teamName: cur.channelId.groupId.teamName,
                        projectName: cur.name,
                        entityId: cur.entityId,
                        groupId: cur.channelId.groupId._id,
                    }
                    acc.push(project);
                    return acc;
                }, []).sort((a, b) => {
                    return a.channelName.toLowerCase() < b.channelName.toLowerCase() ? -1 : 1;
                });
            }),
            tap(res => {
                this.log.log('EFilesService', 'getProjectList$', res);
            })
        );
    }

    getProjectDetail$(requestHeaders, requestParams) {
        const requestURL = `${this.state.currentState.domain}/api/v2/product`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                return res.value.reduce((acc: IAppvityProjectProps[], cur) => {
                    const project: IAppvityProjectProps = {
                        _id: cur._id,
                        tenantId: cur.tenantId,
                        channelId: cur.channelId.channelId,
                        channelName: cur.channelId.channelName,
                        teamId: cur.channelId.teamId,
                        teamName: cur.channelId.groupId.teamName,
                        projectName: cur.name,
                        entityId: cur.entityId,
                        groupId: cur.channelId.groupId._id,
                    }
                    acc.push(project);
                    return acc;
                }, [])[0] ?? null;
            }),
            tap(res => {
                this.log.log('EFilesService', 'getProjectDetail$', res);
            })
        );
    }

    getFiles$(requestHeaders, requestParams, documentId: string) {
        const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/documents/${documentId}/overview`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('EFilesService', 'getFiles$', res);
            })
        );
    }

    getFilesByView$(requestHeaders, requestParams) {
        const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/documents`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('EFilesService', 'getFiles$', res);
            })
        );
    }

    getProjectViews$(requestHeaders: any, requestParams: any, handleExtendsion?: Function) {
        const requestURL = `${this.state.currentState.domain}/api/v2/component/views`;
        if (requestParams == null) {
            requestParams = {
                $count: true,
                $top: 200,
                $filter: `application eq 'appvity.eFiles'`,
                $select: '_id,title,value,createdBy,createdAt,updatedAt,type,order,isDefault,isShow,isActive,userId,viewId,_model'
            }
        }
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                return res.value.reduce((acc: IAppvityCoreView[], cur) => {
                    acc.push({
                        id: cur.viewId._id,
                        title: cur.viewId.title,
                        fields: cur.viewId.value.grid.displayColumns.map((field) => field.fieldName),
                        extendsion: handleExtendsion ? handleExtendsion(cur) : {
                            folderIds: cur.viewId.value.folder.map((folder) => folder._id),
                            folderPath: cur.viewId.value.folder.map((folder) => {
                                if (folder._id !== 'root') {
                                    return `${folder.parentReference.path}/${folder.name}`
                                } else {
                                    return `${folder.parentReference.path}`
                                }
                            }),
                            conditions: cur.viewId.value.conditions ? Object.keys(cur.viewId.value.conditions).reduce((acc_condition, cur_condition) => {
                                acc_condition.push(`${cur.viewId.value.conditions[cur_condition].filter}`);
                                return acc_condition;
                            }, []).join(' and ') : null,
                            isIncludeSubFolder: cur.viewId.value.scope === 'all-subfolders'
                        }
                    });
                    return acc;
                }, [{
                    id: '000000000000000000000000',
                    title: 'Home',
                    fields: ['_id', 'name', 'fileType', 'updatedBy', 'lastModifiedDateTime', 'tags'],
                    extendsion: {
                        folderIds: ['root'],
                        folderPath: [''],
                        isIncludeSubFolder: false,
                        conditions: null
                    }
                }])
            }),
            tap(res => {
                this.log.log('EFilesService', 'getProjectViews', res);
            }));
    }

    getProjectSetting_Status$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/status`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('EFilesService', 'getProjectSetting_Status$', res);
            })
        );
    }

    getProjectSetting_Sensitivity$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/sensitivity`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('EFilesService', 'getProjectSetting_Sensitivity$', res);
            }))
    }

    getProjectSetting_Tag$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/tag`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('EFilesService', 'getProjectSetting_Tag$', res);
            }))
    }

    getTreeView$(requestHeaders: any, requestParams: any, folderId: string) {
        const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/documents/${folderId}/treeview`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                return res.value.reduce((acc, cur) => {
                    acc.push({
                        id: cur._id,
                        name: cur.name,
                        itemCount: cur.childCount,
                        pathUrl: cur.parentReference.path.replace('/drive/root:', 'root')
                    })
                    return acc;
                }, [])
            }),
            tap(res => {
                this.log.log('EFilesService', 'getTreeView$', res);
            }))
    }

    getPreviewFileUrl$(requestHeaders: any, documentId: string) {
        return new Observable(observer => {
            const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/documents/${documentId}/preview`;
            this.http.http.post(requestURL, {
                page: '1',
                zoom: 1
            }, {
                headers: requestHeaders
            }).subscribe({
                next: (res: any) => {
                    observer.next({
                        url: res.getUrl
                    });
                    observer.complete();
                },
                error: (err) => {
                    observer.next({
                        error: err
                    });
                    observer.complete();
                }
            });
        });
    }

    uploadSingleFile$(requestHeaders, documentId: string, file: File, fileName: string) {
        return new Observable(observer => {
            const chunkSize = (5 * 1024 * 1024); // 5MB chunk size
            const totalChunks = Math.ceil(file.size / chunkSize);
            const request$ = [];
            const result = [];

            if (totalChunks === 0) {
                observer.next({
                    error: 'File is empty'
                });
                observer.complete();
                return;
            }

            for (let i = 0; i < totalChunks; i++) {
                const start = i * chunkSize;
                const end = Math.min(start + chunkSize, file.size);
                const chunk = file.slice(start, end);
                request$.push(this.uploadFileBlob$(requestHeaders, documentId, fileName, chunk, start, end, file.size));
            }
            concat(...request$).subscribe({
                next: (res: any) => {
                    if (result.length === totalChunks) {
                        observer.next(res);
                        observer.complete();
                    }
                },
                error: (err) => {
                    observer.next({
                        error: err
                    });
                    observer.complete();
                }
            });
        })
    }

    uploadFileBlob$(requestHeaders, documentId: string, fileName: string, fileBlob: Blob, start: number, end: number, totalSize: number) {
        const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/documents/${documentId}/upload/large`;
        return new Observable(observer => {
            const formData = new FormData();
            formData.append('fileName', fileName);
            formData.append('stream', fileBlob);
            formData.append('fileSize', totalSize.toString());
            formData.append('start', start.toString());
            formData.append('end', end.toString());
            this.http.http.post(requestURL, formData, {
                headers: requestHeaders
            }).pipe(
                tap(res => {
                    this.log.log('EFilesService', 'uploadFileBlob$', res);
                })
            ).subscribe({
                next: (res) => {
                    observer.next({
                        data: res
                    });
                    observer.complete();
                },
                error: (err) => {
                    observer.next({
                        error: err
                    });
                    observer.complete();
                }
            });
        })
    }

    updateFilePropertiesESource$(requestHeaders, dataItemId, dataItem) {
        return new Observable((observer) => {
            const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/documents/${dataItemId}`;
            this.http.updateItem(requestURL, dataItem, requestHeaders).pipe(
                map(res => res.value),
                tap(res => {
                    this.log.log('EFilesService', 'updateFilePropertiesESource$', res);
                })
            ).subscribe({
                next: (res) => {
                    observer.next({
                        data: res
                    });
                    observer.complete();
                },
                error: (err) => {
                    observer.next({
                        error: err
                    });
                    observer.complete();
                }
            });

        });

    }

    updateFilePropertiesSharePoint$(requestHeaders, dataItemId, dataItem) {
        return new Observable((observer) => {
            const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/documents/${dataItemId}/update`;
            this.http.updateItem(requestURL, dataItem, requestHeaders).pipe(
                map(res => res.value),
                tap(res => {
                    this.log.log('EFilesService', 'updateFilePropertiesSharePoint$', res);
                })
            ).subscribe({
                next: (res) => {
                    observer.next({
                        data: res
                    });
                    observer.complete();
                },
                error: (err) => {
                    observer.next({
                        error: err
                    });
                    observer.complete();
                }
            });
        });
    }

    updateFileSetProperties$(requestHeaders, dataItemId, dataItem) {
        return new Observable((observer) => {
            const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/fileSet/${dataItemId}`;
            return this.http.updateItem(requestURL, dataItem, requestHeaders).pipe(
                map(res => res.value),
                tap(res => {
                    this.log.log('EFilesService', 'updateFilePropertiesSharePoint$', res);
                })).subscribe({
                    next: (res) => {
                        observer.next({
                            data: res
                        });
                        observer.complete();
                    },
                    error: (err) => {
                        observer.next({
                            error: err
                        });
                        observer.complete();
                    }
                });
        });

    }

    deleteListFileDataItem$(requestHeaders, dataItemId) {
        return new Observable((observer) => {
            const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/documents/${dataItemId}`;
            return this.http.deleteItem(requestURL, undefined, requestHeaders).pipe(
                tap(res => {
                    this.log.log('EFilesService', 'updateFilePropertiesSharePoint$', res);
                })).subscribe({
                    next: (res) => {
                        observer.next({
                            data: res
                        });
                        observer.complete();
                    },
                    error: (err) => {
                        observer.next({
                            error: err
                        });
                        observer.complete();
                    }
                });
        })
    }

    deleteFileSetDataItem$(requestHeaders, dataItemId) {
        return new Observable((observer) => {
            const requestURL = `${this.state.currentState.domain}/api/v2/eFiles/fileSet/${dataItemId}`;
            this.http.deleteItem(requestURL, undefined, requestHeaders).pipe(
                tap(res => {
                    this.log.log('EFilesService', 'updateFilePropertiesSharePoint$', res);
                })).subscribe({
                    next: (res) => {
                        observer.next({
                            data: res
                        });
                        observer.complete();
                    },
                    error: (err) => {
                        observer.next({
                            error: err
                        });
                        observer.complete();
                    }
                });
        })
    }

}
