<div mat-dialog-title style="display: flex;">
    <div class="__app-dialog-title">
        <span class="__title">{{data.title}}</span><span
            style="width: 24px; height: 25px; cursor: pointer; padding: 4px;" mat-dialog-close><img
                src="/assets/icons/icon-close.svg" /></span>
    </div>
</div>
<div mat-dialog-content class="__app-dialog-content">
    <div>
        <div style="color: var(--grayscale-400); font-size: 11px;">New name <span
                style="color: var(--rasberry-100);">*</span>
        </div>
        <div style="display: flex; cursor: pointer;">
            <input type="text" placeholder="New name" required [formControl]="controlNewName">
        </div>

        <div *ngIf="controlNewName.dirty && controlNewName.errors">
            <small class="error-message">
                <error-message [target]="'New name'" [errors]="controlNewName.errors" />
            </small>
        </div>
    </div>
</div>
<div mat-dialog-actions [class]="'__app-dialog-actions_' + data.actions.length" [ngClass]="panelSize ?? ''">
    <ng-container *ngFor="let action of data.actions; trackBy:trackByFn;">
        <button [class]="action.primary ? '__primary-button' : ''" [ngClass]="action.primary && !controlNewName.valid ? 'btn-disabled' : ''" (click)="action.action()">{{action.text}}</button>
    </ng-container>
</div>