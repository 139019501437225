import { ChangeDetectorRef, Component, NgZone, OnDestroy, inject } from '@angular/core';
import { Observable, Subject, fromEvent, merge, of } from 'rxjs';
import { finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { IAppState } from '@app-state';
import { StateService } from '../state/state.service';
import { Effect } from '../state/state.schema';
import { ResizeObserverService } from '@utils/services';
import { ExcelService } from '@common/services/office';
import { NotificationService } from '@common/services/notification';
import { AppFacadeService } from 'src/app/facade/app-facade.service';
import { AppvityDataSource, CRMDynamicsDataSource, DefaultConfigurationWorksheet, DefaultConfigurationWorksheetTable, DefaultConnectionWorksheet, DefaultConnectionWorksheetTable, RangeObjectNumberFormatText, SharePointDataSource } from '@common/schemas';
import { Router } from '@angular/router';
import { SharePointService } from '@common/services/sharepoint';
import { IAppvityCompanyTicketInfo, IAppvitySourceInfo, IAppvityUsersProfileInfo, IDataverseSourceInfo, ISharePointSourceInfo, IAppvityFileProfileInfo } from '@common/components/datasource-info-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DateTime } from 'luxon';

@Component({
    template: '',
    standalone: true
})
export abstract class BaseComponent implements OnDestroy {
    state = inject(StateService<IAppState>);
    resizeObserver = inject(ResizeObserverService);
    cdr = inject(ChangeDetectorRef);
    excelApi = inject(ExcelService);
    zone = inject(NgZone);
    notification = inject(NotificationService);
    appFacadeService = inject(AppFacadeService);
    router = inject(Router);
    iconRegistry = inject(MatIconRegistry);
    sanitizer = inject(DomSanitizer);

    destroy$ = new Subject<void>();
    networkStatus$ = new Subject<boolean>();
    activeWorksheet$ = new Subject<any>();
    appState: IAppState;
    isLoading = false;
    isLocalAddInActiveWorksheet = false;
    sharepointApi = inject(SharePointService);

    constructor() {
        this.appState = this.state.currentState;
        this.registerWindowNetworkObserver();
        this.registerResizeObserver();
    }

    registerAppStateChanged() {
        this.state.stateChanges$.pipe(takeUntil(this.destroy$)).subscribe({
            next: (changes) => {
                if (changes instanceof Effect) {
                    this.appState = changes.newState;
                    this.cdr.detectChanges();
                } else {
                    this.appState = changes;
                    this.cdr.detectChanges();
                }
            }
        });
    }

    registerCoreLayer() { }

    /**
     * @example
     *  override registerResizeObserver() {
            this.resizeObserver.observe(document.querySelector('body'), (width, _) => {
                if (width > 599) {
                    // TODO
                }
            });
        }
        ngOnDestroy() {
            super.ngOnDestroy();
            this.resizeObserver.unobserve(document.querySelector('body'));
        }
     */
    registerResizeObserver() {
        window.addEventListener('resize', () => {
            if (window.innerWidth > 500) {
                this.appState.panelSize = 'large-size';
            } else {
                this.appState.panelSize = 'medium-size';
            }
            this.state.commit(this.appState);
            this.cdr.detectChanges();
        });
        // this.resizeObserver.observe(document.querySelector('body'), (width, _) => {
        //     if (width > 500) {
        //         this.appState.panelSize = 'large-size';
        //     } else {
        //         this.appState.panelSize = 'medium-size';
        //     }
        //     this.state.commit(this.appState);
        //     this.cdr.detectChanges();
        // });
    }

    registerWindowNetworkObserver() {
        merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        ).pipe(map(() => navigator.onLine), takeUntil(this.destroy$))
            .subscribe(status => {
                this.appState.onOnline = status;
                this.state.commit(this.appState);
                this.networkStatus$.next(status);
            });
    }

    onWorksheetChangedHandle(worksheetName) {
        if ([null, undefined, ''].includes(worksheetName)) {
            return;
        }
        if (this.isLocalAddInActiveWorksheet) {
            // do nothing if the worksheet is actived by local add-in code
            return;
        }
        const connectionTableName = this.excelApi.generateTableName(`Connection_${worksheetName}`, true);;
        this.excelApi.ensureTable$(DefaultConnectionWorksheet.name, connectionTableName).pipe(takeUntil(this.destroy$)).subscribe({
            next: (table) => {
                if (table) {
                    this.processConnectionTable(worksheetName, table);
                } else {
                    this.cdr.detectChanges();
                    this.notification.showError(`This worksheet has been changed or deleted!`);
                    this.router.navigate(['/connection']);
                }
            }
        });
    }

    checkIsOnCurrentDetailPage(datasource, objectContext) {
        const isOnDetailPage = ['/project', '/file', '/company', '/ups', '/entity', '/lists'].some(path => this.router.url.includes(path));
        if (!isOnDetailPage) {
            // return false if the current page is not detail page
            return false;
        }
        // continues check if the current page is detail page
        switch (datasource) {
            case AppvityDataSource['Appvity.Task']: case AppvityDataSource['Appvity.Bug']:
                return this.router.url.includes(`/project/${objectContext.teamId}/${objectContext.channelId}`);
            case SharePointDataSource['SharePoint.List']:
                return this.router.url.includes(`/lists/${objectContext.id}`);
            case AppvityDataSource['Appvity.Ticket']:
                return this.router.url.includes(`/company/${objectContext._id}`);
            case AppvityDataSource['Appvity.UPS']:
                return this.router.url.includes(`/ups/${objectContext._id}`);
            case AppvityDataSource['Appvity.File']:
                return this.router.url.includes(`/file/${objectContext._id}`);
            case CRMDynamicsDataSource['Microsoft Dataverse']:
                return this.router.url.includes(`/entity/${objectContext.id}`);
        }
        return false;
    }

    processConnectionTable(worksheetName, table) {
        const connectionData = table.rows[0];
        const objectContext = JSON.parse(connectionData[1] ?? null);
        const domain = connectionData[15] ?? null;
        const datasource = connectionData[0] ?? null;
        this.appState.domain = domain;
        this.state.commit(this.appState);

        // if (this.checkIsOnCurrentDetailPage(datasource, objectContext)) {
        //     // do nothing if the current page is detail page
        //     return;
        // }

        switch (datasource) {
            case AppvityDataSource['Appvity.Task']: case AppvityDataSource['Appvity.Bug']:
                this.processAppvitySource(worksheetName, {
                    datasource: datasource,
                    domain: domain,
                    teamId: objectContext['teamId'] ?? '',
                    channelId: objectContext['channelId'] ?? '',
                    channelName: objectContext['channelName'] ?? '',
                    viewId: JSON.parse(connectionData[7] ?? null)?.id,
                    currentState: Number(connectionData[5]),
                })
                break;
            case SharePointDataSource['SharePoint.List']:
                this.processSharePointSource(worksheetName, {
                    domain: domain,
                    listId: objectContext.id,
                    listName: objectContext.title,
                    datasource: datasource,
                    viewId: JSON.parse(connectionData[7] ?? null)?.id,
                    currentState: Number(connectionData[5]),
                })
                break;
            case AppvityDataSource['Appvity.Ticket']:
                this.processAppvityTicketSource(worksheetName, {
                    domain: domain,
                    companyId: objectContext._id,
                    companyName: objectContext.name,
                    datasource: datasource,
                    viewId: JSON.parse(connectionData[7] ?? null)?.id,
                    currentState: Number(connectionData[5]),
                })
                break;
            case AppvityDataSource['Appvity.UPS']:
                this.processAppvityUPSSource(worksheetName, {
                    domain: domain,
                    upsId: objectContext._id,
                    title: objectContext.name,
                    datasource: datasource,
                    viewId: JSON.parse(connectionData[7] ?? null)?.id,
                    currentState: Number(connectionData[5]),
                })
                break;
            case AppvityDataSource['Appvity.File']:
                this.processAppvityFileSource(worksheetName, {
                    datasource: datasource,
                    domain: domain,
                    projectId: objectContext['_id'] ?? '',
                    teamId: objectContext['teamId'] ?? '',
                    channelId: objectContext['channelId'] ?? '',
                    channelName: objectContext['channelName'] ?? '',
                    viewId: JSON.parse(connectionData[7] ?? null)?.id,
                    currentState: Number(connectionData[5]),
                })
                break;
            case CRMDynamicsDataSource['Microsoft Dataverse']:
                this.processDataverseSource(worksheetName, {
                    domain: domain,
                    entityId: objectContext.id,
                    entityName: objectContext.title,
                    datasource: datasource,
                    viewId: JSON.parse(connectionData[7] ?? null)?.id,
                    currentState: Number(connectionData[5]),
                })
                break;
        }
    }

    processSharePointSource(worksheetName, data: {
        domain,
        listName,
        listId,
        datasource,
        viewId,
        currentState
    }) {
        this.appState.excel[DefaultConfigurationWorksheet._key] = {
            id: '',
            name: DefaultConfigurationWorksheetTable.name,
            domain: data.domain,
            listId: data.listId,
            listName: data.listName,
            teamId: '',
            dataSource: data.datasource,
            viewId: data.viewId,
            currentState: data.currentState,
            initial: false,
            isRegisterEventWorksheetChanged: true,
            worksheetName: worksheetName
        };
        this.appState.domain = data.domain;
        this.appState.serverRelativePath = data.domain;
        const hostname = /[\w%-]+.sharepoint.com/.exec(data.domain)[0];
        this.appState.hostname = hostname;
        this.appState.dataSource = data.datasource;
        this.state.commit(this.appState);
        this.zone.run(() => {
            if (this.appState.onOnline) {
                const redirectUrl = `/lists/${data.listId}`
                this.router.navigate([`/shell-loading/${encodeURIComponent(redirectUrl)}`]);
            } else {
                this.notification.showError('Lost connection!');
            }
        });

    }

    processAppvityTicketSource(worksheetName, data: {
        domain,
        companyId,
        companyName,
        datasource,
        viewId,
        currentState
    }) {
        this.appState.excel[DefaultConfigurationWorksheet._key] = {
            id: '',
            name: DefaultConfigurationWorksheetTable.name,
            domain: data.domain,
            companyId: data.companyId,
            companyName: data.companyName,
            teamId: '',
            dataSource: data.datasource,
            viewId: data.viewId,
            currentState: data.currentState,
            initial: false,
            isRegisterEventWorksheetChanged: true,
            worksheetName: worksheetName
        };
        this.appState.domain = data.domain;
        this.appState.dataSource = data.datasource;
        this.state.commit(this.appState);
        this.zone.run(() => {
            if (this.appState.onOnline) {
                const redirectUrl = `/company/${data.companyId}`
                this.router.navigate([`/shell-loading/${encodeURIComponent(redirectUrl)}`]);
            } else {
                this.notification.showError('Lost connection!');
            }
        });
    }

    processAppvityUPSSource(worksheetName, data: {
        domain,
        upsId,
        title,
        datasource,
        viewId,
        currentState
    }) {
        this.appState.excel[DefaultConfigurationWorksheet._key] = {
            id: '',
            name: DefaultConfigurationWorksheetTable.name,
            domain: data.domain,
            upsId: data.upsId,
            title: data.title,
            teamId: '',
            dataSource: data.datasource,
            viewId: data.viewId,
            currentState: data.currentState,
            initial: false,
            isRegisterEventWorksheetChanged: true,
            worksheetName: worksheetName
        };
        this.appState.domain = data.domain;
        this.appState.dataSource = data.datasource;
        this.state.commit(this.appState);
        this.zone.run(() => {
            if (this.appState.onOnline) {
                const redirectUrl = `/ups/${data.upsId}`
                this.router.navigate([`/shell-loading/${encodeURIComponent(redirectUrl)}`]);
            } else {
                this.notification.showError('Lost connection!');
            }
        });
    }

    processDataverseSource(worksheetName, data: {
        domain,
        entityId,
        entityName,
        datasource,
        viewId,
        currentState
    }) {
        this.appState.excel[DefaultConfigurationWorksheet._key] = {
            id: '',
            name: DefaultConfigurationWorksheetTable.name,
            domain: data.domain,
            entityId: data.entityId,
            entityName: data.entityName,
            teamId: '',
            dataSource: data.datasource,
            viewId: data.viewId,
            currentState: data.currentState,
            initial: false,
            isRegisterEventWorksheetChanged: true,
            worksheetName: worksheetName
        };
        this.appState.domain = data.domain;
        this.appState.dataSource = data.datasource;
        this.state.commit(this.appState);
        this.zone.run(() => {
            if (this.appState.onOnline) {
                const redirectUrl = `/entity/${data.entityId}`
                this.router.navigate([`/shell-loading/${encodeURIComponent(redirectUrl)}`]);
            } else {
                this.notification.showError('Lost connection!');
            }
        });
    }

    processAppvityFileSource(worksheetName, data: {
        domain,
        projectId
        teamId,
        channelName,
        channelId,
        datasource,
        viewId,
        currentState
    }) {
        this.appState.excel[DefaultConfigurationWorksheet._key] = {
            id: '',
            name: DefaultConfigurationWorksheetTable.name,
            domain: data.domain,
            channelId: data.channelId,
            channelName: data.channelName,
            teamId: data.teamId,
            dataSource: data.datasource,
            viewId: data.viewId,
            currentState: data.currentState,
            initial: false,
            isRegisterEventWorksheetChanged: false,
            worksheetName: worksheetName,
        };
        this.appState.domain = data.domain;
        this.appState.dataSource = data.datasource;
        this.state.commit(this.appState);
        this.zone.run(() => {
            if (this.appState.onOnline) {
                const redirectUrl = `/file/${data.projectId}`
                this.router.navigate([`/shell-loading/${encodeURIComponent(redirectUrl)}`]);
            } else {
                this.notification.showError('Lost connection!');
            }
        })
    }

    processAppvitySource(worksheetName, data: {
        domain,
        teamId,
        channelName,
        channelId,
        datasource,
        viewId,
        currentState
    }) {
        this.appState.excel[DefaultConfigurationWorksheet._key] = {
            id: '',
            name: DefaultConfigurationWorksheetTable.name,
            domain: data.domain,
            channelId: data['channelId'] ?? '',
            channelName: data['channelName'] ?? '',
            teamId: data['teamId'] ?? '',
            dataSource: data.datasource,
            viewId: data.viewId,
            currentState: data.currentState,
            initial: false,
            isRegisterEventWorksheetChanged: false,
            worksheetName: worksheetName,
        };
        this.appState.domain = data.domain;
        this.appState.dataSource = data.datasource;
        this.state.commit(this.appState);
        this.zone.run(() => {
            if (this.appState.onOnline) {
                const redirectUrl = `/project/${data['teamId']}/${data.channelId}`
                this.router.navigate([`/shell-loading/${encodeURIComponent(redirectUrl)}`]);
            } else {
                this.notification.showError('Lost connection!');
            }
        })
    }

    registerWorksheetDataItemsActivated(worksheetName) {
        this.excelApi.registerWorksheetActivedEvent$(worksheetName, (worksheetKey) => {
            this.appFacadeService.setWorksheetOnActiveChanged(worksheetKey);
        }).subscribe({
            next: (eventResult) => {
                this.appFacadeService.setEventWorksheetActiveResult(worksheetName, eventResult);
            }
        });
    }

    reRegisterWorksheetDataItemsActivated() {
        this.excelApi.retrieveWorksheetTables$(DefaultConnectionWorksheet.name).pipe(takeUntil(this.destroy$)).subscribe({
            next: value => {
                if (value) {
                    const listWorksheetNames = value.items.reduce((acc, cur) => {
                        const rowData = cur.rows.items[0].values[0];
                        const worksheetName = JSON.parse(rowData[14] ?? null)?.worksheetName;
                        acc.push(worksheetName);
                        return acc;
                    }, []);
                    listWorksheetNames.forEach(name => {
                        this.excelApi.registerWorksheetActivedEvent$(name, (worksheetKey) => {
                            this.appFacadeService.setWorksheetOnActiveChanged(worksheetKey);
                        }).pipe(takeUntil(this.destroy$)).subscribe({
                            next: (eventResult) => {
                                this.appFacadeService.setEventWorksheetActiveResult(name, eventResult);
                            }
                        });
                    });
                }
            }
        })
    }

    reRegisterWorksheetClickEvent() {
        this.excelApi.retrieveWorksheetTables$(DefaultConnectionWorksheet.name).pipe(takeUntil(this.destroy$)).subscribe({
            next: value => {
                if (value) {
                    const listWorksheetNames = value.items.reduce((acc, cur) => {
                        const rowData = cur.rows.items[0].values[0];
                        const worksheetName = JSON.parse(rowData[14] ?? null)?.worksheetName;
                        acc.push(worksheetName);
                        return acc;
                    }, []);
                    listWorksheetNames.forEach(name => {
                        this.excelApi.registerEventWorksheetClick$(name, (args) => {
                            this.appFacadeService.setWorksheetOnClickAttach(args);
                        }).pipe(takeUntil(this.destroy$)).subscribe();
                    });
                }
            }
        })
    }

    reRegisterWorksheetChangedEvent() {
        this.excelApi.retrieveWorksheetTables$(DefaultConnectionWorksheet.name).pipe(takeUntil(this.destroy$)).subscribe({
            next: value => {
                if (value) {
                    const listWorksheetNames = value.items.reduce((acc, cur) => {
                        const rowData = cur.rows.items[0].values[0];
                        const worksheetName = JSON.parse(rowData[14] ?? null)?.worksheetName;
                        acc.push(worksheetName);
                        return acc;
                    }, []);
                    listWorksheetNames.forEach(name => {
                        this.excelApi.registerWorksheetOnChangedEvent$(name, {
                            onRowDeleted: event => {
                                this.appFacadeService.setWorksheetOnRowDeleted(name);
                            },
                            onRowInserted: event => {
                                if (event && event.triggerSource !== 'ThisLocalAddin') {
                                    this.appFacadeService.setWorksheetOnRowInserted(name);
                                }
                            },
                            onRangeEdited: event => {
                                this.appFacadeService.setWorksheetOnRangeEdited(name, event);
                            }
                        }).pipe(takeUntil(this.destroy$)).subscribe();
                    });
                }
            }
        });
    }

    reUpdateListWorksheetConnected() {
        this.excelApi.retrieveWorksheetTables$(DefaultConnectionWorksheet.name).pipe(takeUntil(this.destroy$)).subscribe({
            next: (value) => {
                if (value) {
                    value.items.forEach(row => {
                        const rowData = row.rows.items[0].values[0];
                        const referenceVariable = JSON.parse(rowData[14] ?? null) ?? null;
                        let folder;
                        if (referenceVariable?.treeViewRawDataBase) {
                            const dataJson = JSON.parse(referenceVariable?.treeViewRawDataBase);
                            if (referenceVariable?.selectFormControl === dataJson.serverRelativeUrl) {
                                folder = dataJson.item;
                            } else {
                                const pathUrlArr = referenceVariable?.selectFormControl.split('/');
                                const subFolder = this.findFolderNode(dataJson, pathUrlArr);
                                folder = subFolder ? subFolder.item : referenceVariable?.selectFormControl.split('/')[referenceVariable?.selectFormControl.split('/').length - 1]
                            }
                        }
                        if (referenceVariable) {
                            this.updateListWorksheetConnected({
                                worksheetName: referenceVariable.worksheetName,
                                connectionTableName: referenceVariable.connectionTableName,
                                datasource: rowData[0],
                                objectContext: this.getObjectContext(rowData[0], JSON.parse(rowData[7]), JSON.parse(rowData[1]), JSON.parse(rowData[2]), folder)
                            });
                        }
                    });
                }
            }
        })
    }

    findFolderNode(node: any, pathArray: string[]) {
        // Base case: If pathArray is empty, we've reached the end of the path
        if (pathArray.length === 0) {
            return node;
        }

        // Find the child folder with the current path segment
        const folderName = pathArray.shift();
        const childFolder = node.children.find(child => child.item === folderName);

        // If the child folder exists, continue the search
        if (childFolder) {
            return this.findFolderNode(childFolder, pathArray);
        } else {
            // If the child folder doesn't exist, the path is invalid
            return null;
        }
    }

    getObjectContext(dataSource, view, objectContext, objectSettings, folder) {
        let dialogContent;
        if (dataSource === SharePointDataSource['SharePoint.List']) {
            dialogContent = {
                source: 'SharePoint',
                list: objectContext.title,
                site: objectContext.siteTitle,
                view: view.title,
            } as ISharePointSourceInfo;
            if (folder) {
                dialogContent['folder'] = folder
            }
        } else if (dataSource === CRMDynamicsDataSource['Microsoft Dataverse']) {
            dialogContent = {
                source: 'Dataverse',
                entity: objectContext.title,
                view: view.title,
            } as IDataverseSourceInfo
        } else if (dataSource === AppvityDataSource['Appvity.Ticket']) {
            dialogContent = {
                source: 'eSource',
                object: dataSource.split('.')[1],
                abbreviation: objectSettings.abbreviation,
                view: view.viewId.title,
                name: objectContext.companyName
            } as IAppvityCompanyTicketInfo;
        } else if (dataSource === AppvityDataSource['Appvity.UPS']) {
            dialogContent = {
                source: 'eSource',
                object: dataSource.split('.')[1],
                view: view.title,
            } as IAppvityUsersProfileInfo;
        } else if (dataSource === AppvityDataSource['Appvity.File']) {
            dialogContent = {
                source: 'eSource',
                object: dataSource.split('.')[1],
                view: view.title,
            } as IAppvityFileProfileInfo;
        } else {
            dialogContent = {
                source: 'eSource',
                object: dataSource.split('.')[1],
                channelName: objectContext.channelName,
                abbreviation: objectSettings.abbreviation,
                view: view.title
            } as IAppvitySourceInfo;
        }
        return dialogContent;
    }

    updateListWorksheetConnected(value: {
        worksheetName,
        connectionTableName,
        datasource,
        objectContext,
    }) {
        this.appFacadeService.setListWorksheetConnected(value);
    }

    trackByFn(index: number) {
        return index;
    }

    ensureConfigurationWorksheet$() {
        return new Observable(obs => {
            const configurationWorksheetName = DefaultConfigurationWorksheet.name;
            const configurationWorksheetTableName = DefaultConfigurationWorksheetTable.name;
            this.excelApi.getActiveWorksheet$().pipe(
                switchMap((worksheet) => {
                    const connectionTableName = this.excelApi.generateTableName(`Connection_${worksheet.name}`, true);
                    return this.excelApi.ensureTable$(DefaultConnectionWorksheet.name, connectionTableName)
                }),
                switchMap((table) => {
                    if (table) {
                        const connectionData = table.rows[0];
                        const domain = connectionData[15] ?? null;
                        const objectContext = JSON.parse(connectionData[1] ?? null);
                        const configurationData = {
                            domain: domain ?? '',
                            dataSource: connectionData[0] ?? '',
                            channelOrlistId: objectContext.id ?? objectContext.channelId,
                            channelOrlistName: objectContext.channelName ?? objectContext.title,
                            viewId: JSON.parse(connectionData[7] ?? null)?.id ?? JSON.parse(connectionData[7] ?? null)?._id ?? null,
                            currentState: Number(connectionData[5]),
                            teamId: objectContext.teamId ?? null,
                            worksheetName: JSON.parse(connectionData[14] ?? null)?.worksheetName
                        }
                        if (configurationData.dataSource === 'Appvity.Ticket') {
                            configurationData['companyId'] = objectContext._id;
                            configurationData['companyName'] = objectContext.name;
                        }
                        if (configurationData.dataSource === AppvityDataSource['Appvity.File']) {
                            configurationData['projectId'] = objectContext['_id'];
                        }
                        return of(configurationData);
                    } else {
                        return this.excelApi.ensureTable$(configurationWorksheetName, configurationWorksheetTableName).pipe(switchMap(tableConfig => {
                            if (tableConfig) {
                                const configs = tableConfig.rows[0];
                                const configurationData = {
                                    domain: configs[0] ?? '',
                                    dataSource: configs[4] ?? '',
                                    channelOrlistId: configs[1],
                                    channelOrlistName: configs[2],
                                    teamId: configs[3],
                                    viewId: configs[5],
                                    currentState: configs[6],
                                    isLoadActiveWS: false,
                                }
                                if (configurationData.dataSource === 'Appvity.Ticket') {
                                    configurationData['companyId'] = configs[1];
                                    configurationData['companyName'] = configs[2];
                                }
                                return of(configurationData);
                            } else {
                                return of(null);
                            }
                        }))
                    }
                })
            ).pipe(takeUntil(this.destroy$)).subscribe({
                next: (data) => {
                    obs.next(data);
                    obs.complete();
                }
            });
        })
    }

    processConnectionDataObject$(data) {
        return new Observable(obs => {
            this.reRegisterWorksheetDataItemsActivated();
            this.reRegisterWorksheetClickEvent();
            this.reRegisterWorksheetChangedEvent();
            this.reUpdateListWorksheetConnected();
            if (data) {
                this.appState.domain = data.domain;
                this.appState.dataSource = data.dataSource;
                this.appState.connected = true;
                if (data['worksheetName']) {
                    this.appFacadeService.setWorksheetConnecting({
                        isWorksheetActived: false,
                        worksheetName: data['worksheetName']
                    });
                }
                if (data.dataSource === SharePointDataSource['SharePoint.List']) {
                    this.appState.excel[DefaultConfigurationWorksheet._key] = {
                        id: '',
                        name: DefaultConfigurationWorksheetTable.name,
                        domain: data.domain,
                        listId: data.channelOrlistId,
                        listName: data.channelOrlistName,
                        teamId: data['teamId'] ?? '',
                        dataSource: data.dataSource,
                        viewId: data.viewId,
                        currentState: data.currentState,
                        initial: false,
                        isRegisterEventWorksheetChanged: false,
                        worksheetName: data['worksheetName'] ?? null,
                    }
                    this.appState.serverRelativePath = data.domain;
                    const hostname = /[\w%-]+.sharepoint.com/.exec(data.domain)[0];
                    this.appState.hostname = hostname;
                    this.state.commit(this.appState);
                    this.sharepointApi.requestAccessToken$().pipe(takeUntil(this.destroy$)).pipe(
                        switchMap((token) => {
                            this.appState.spAccessToken = token;
                            this.state.commit(this.appState);
                            return this.sharepointApi.getSPSiteContextInfo$(data.domain)
                        })
                    ).subscribe({
                        next: (value) => {
                            if (value.error) {
                                obs.next({
                                    error: 'Incorrect site URL'
                                });
                                obs.complete();
                            } else {
                                this.appState.serverRelativePath = value.webFullUrl;
                                this.state.commit(this.appState);
                                obs.next(`/lists/${data.channelOrlistId}`);
                                obs.complete();
                            }

                        }
                    })
                } else if (data.dataSource === 'Appvity.Ticket') {
                    this.appState.excel[DefaultConfigurationWorksheet._key] = {
                        id: '',
                        name: DefaultConfigurationWorksheetTable.name,
                        domain: data.domain,
                        companyId: data.companyId,
                        companyName: data.companyName,
                        teamId: '',
                        dataSource: data.dataSource,
                        viewId: data.viewId,
                        currentState: data.currentState,
                        initial: false,
                        isRegisterEventWorksheetChanged: false,
                        worksheetName: data['worksheetName'] ?? null,
                    };
                    this.state.commit(this.appState);
                    obs.next(`/company/${data.companyId}`);
                    obs.complete();
                } else if (data.dataSource === AppvityDataSource['Appvity.UPS']) {
                    this.appState.excel[DefaultConfigurationWorksheet._key] = {
                        id: '',
                        name: DefaultConfigurationWorksheetTable.name,
                        domain: data.domain,
                        upsId: data.upsId,
                        title: data.title,
                        teamId: '',
                        dataSource: data.dataSource,
                        viewId: data.viewId,
                        currentState: data.currentState,
                        initial: false,
                        isRegisterEventWorksheetChanged: false,
                        worksheetName: data['worksheetName'] ?? null,
                    };
                    this.state.commit(this.appState);
                    obs.next(`/ups/${data.upsId}`);
                    obs.complete();
                } else if (data.dataSource === AppvityDataSource['Appvity.File']) {
                    this.appState.excel[DefaultConfigurationWorksheet._key] = {
                        id: '',
                        name: DefaultConfigurationWorksheetTable.name,
                        domain: data.domain,
                        channelId: data.channelOrlistId,
                        channelName: data.channelOrlistName,
                        teamId: data['teamId'] ?? '',
                        dataSource: data.dataSource,
                        viewId: data.viewId,
                        currentState: data.currentState,
                        initial: false,
                        isRegisterEventWorksheetChanged: false,
                        worksheetName: data['worksheetName'] ?? null,
                    };
                    this.state.commit(this.appState);
                    obs.next(`/file/${data.projectId}`);
                    obs.complete();
                } else if (data.dataSource === CRMDynamicsDataSource['Microsoft Dataverse']) {
                    this.appState.excel[DefaultConfigurationWorksheet._key] = {
                        id: '',
                        name: DefaultConfigurationWorksheetTable.name,
                        domain: data.domain,
                        entityId: data.channelOrlistId,
                        entityName: data.channelOrlistName,
                        teamId: '',
                        dataSource: data.dataSource,
                        viewId: data.viewId,
                        currentState: data.currentState,
                        initial: false,
                        isRegisterEventWorksheetChanged: false,
                        worksheetName: data['worksheetName'] ?? null,
                    };
                    this.state.commit(this.appState);
                    obs.next(`/entity/${data.channelOrlistId}`);
                    obs.complete();
                } else {
                    this.appState.excel[DefaultConfigurationWorksheet._key] = {
                        id: '',
                        name: DefaultConfigurationWorksheetTable.name,
                        domain: data.domain,
                        channelId: data.channelOrlistId,
                        channelName: data.channelOrlistName,
                        teamId: data['teamId'] ?? '',
                        dataSource: data.dataSource,
                        viewId: data.viewId,
                        currentState: data.currentState,
                        initial: false,
                        isRegisterEventWorksheetChanged: false,
                        worksheetName: data['worksheetName'] ?? null,
                    }
                    this.state.commit(this.appState);
                    obs.next(`/project/${data['teamId']}/${data.channelOrlistId}`);
                    obs.complete();
                }
            } else {
                obs.next();
                obs.complete();
            }

        });
    }

    processConnectionWorksheet$(data: {
        dataSource: string,
        dataSourceInfo?: string,
        dataSourceSettings?: string,
        listWorksheetsName?: string,
        listTablesName?: string,
        lastAction?: string,
        listViews?: string,
        viewSelected?: string,
        reviewState?: string,
        syncState?: string,
        syncDataItem?: string,
        reviewLocalStatus?: boolean,
        reviewServerStatus?: boolean,
        syncStatus?: boolean,
        referenceVariable?: string,
        domain: string
    }, connectionTableName) {
        return new Observable<void>(obs => {
            const dataFormatted = [
                data.dataSource ?? '',
                data.dataSourceInfo ?? '',
                data.dataSourceSettings ?? '',
                data.listWorksheetsName ?? '',
                data.listTablesName ?? '',
                data.lastAction ?? '',
                data.listViews ?? '',
                data.viewSelected ?? '',
                data.reviewState ?? '',
                data.syncState ?? '',
                data.syncDataItem ?? '',
                data.reviewLocalStatus ?? '',
                data.reviewServerStatus ?? '',
                data.syncStatus ?? '',
                data.referenceVariable ?? '',
                data.domain ?? ''
            ]
            this.excelApi.ensureWorksheet$(DefaultConnectionWorksheet.name).pipe(takeUntil(this.destroy$)).subscribe({
                next: (worksheet) => {
                    if (worksheet) {
                        this.excelApi.unprotectWorksheet$(DefaultConnectionWorksheet.name).pipe(
                            switchMap(() => this.excelApi.ensureTable$(DefaultConnectionWorksheet.name, connectionTableName)),
                            switchMap((table) => {
                                if (table) {
                                    return this.excelApi.updateTable$(DefaultConnectionWorksheet.name, connectionTableName, [dataFormatted])
                                } else {
                                    return this.addNewTableConnection$(dataFormatted, connectionTableName);
                                }
                            }),
                            finalize(() => {
                                this.excelApi.autoFitWorksheetColumns$(DefaultConnectionWorksheet.name).subscribe();
                                this.excelApi.protectWorksheet$(DefaultConnectionWorksheet.name).subscribe();
                            })
                        ).subscribe({
                            next: () => {
                                obs.next();
                                obs.complete();
                            }
                        });
                    } else {
                        this.excelApi.addWorksheet$(DefaultConnectionWorksheet.name, false, false, this.appState.production ? true : false).pipe(
                            switchMap(() => {
                                return this.addNewTableConnection$(dataFormatted, connectionTableName);
                            }),
                            finalize(() => {
                                this.excelApi.autoFitWorksheetColumns$(DefaultConnectionWorksheet.name).subscribe();
                                this.excelApi.protectWorksheet$(DefaultConnectionWorksheet.name).subscribe();
                            })
                        ).subscribe({
                            next: () => {
                                obs.next();
                                obs.complete();
                            }
                        })
                    }
                }
            })
        })

    }

    addNewTableConnection$(data, connectionTableName) {
        return new Observable(obs => {
            this.excelApi.getWorksheetUsedRange$(DefaultConnectionWorksheet.name).pipe(takeUntil(this.destroy$)).subscribe({
                next: (usedRange) => {
                    if (usedRange) {
                        const nextRowIndex = this.excelApi.getUsedRangeObjectNextRowIndex(usedRange);
                        this.excelApi.insertTable$(DefaultConnectionWorksheet.name, {
                            name: connectionTableName,
                            range: `A${nextRowIndex}:${this.excelApi.intToExcelCol(DefaultConnectionWorksheetTable.values[0].length)}${nextRowIndex}`,
                            values: DefaultConnectionWorksheetTable.values,
                            rows: [data],
                            numberFormat: RangeObjectNumberFormatText
                        }).subscribe({
                            next: (value) => {
                                obs.next(value);
                                obs.complete();
                            }
                        });
                    }
                }
            })
        })
    }

    processFileUploadInfoTable$(data) {
        return new Observable(obs => {
            const tableName = `hexTABLE_File_Uploaded_Information`;
            const worksheetName = `eXsync_Attachments`;
            this.excelApi.ensureWorksheet$(worksheetName).pipe(takeUntil(this.destroy$)).subscribe({
                next: (worksheet) => {
                    if (worksheet) {
                        this.excelApi.unprotectWorksheet$(worksheetName).pipe(
                            switchMap(() => this.excelApi.ensureTable$(worksheetName, tableName)),
                            switchMap((table) => {
                                if (table) {
                                    // return this.excelApi.updateTable$(worksheetName, tableName, [...table.rows, [JSON.stringify(data)]])
                                    return this.excelApi.deleteTable$(worksheetName, tableName).pipe(
                                        switchMap(() => this.excelApi.getWorksheetUsedRange$(worksheetName)),
                                        switchMap((usedRange) => {
                                            const nextRowIndex = this.excelApi.getUsedRangeObjectNextRowIndex(usedRange);
                                            return this.excelApi.insertTable$(worksheetName, {
                                                name: tableName,
                                                range: `A${nextRowIndex}:A${nextRowIndex}`,
                                                values: [['Metadata']],
                                                rows: [...table.rows, [JSON.stringify(data)]],
                                                numberFormat: RangeObjectNumberFormatText
                                            })
                                        })
                                    );
                                } else {
                                    return this.excelApi.getWorksheetUsedRange$(worksheetName).pipe(
                                        switchMap((usedRange) => {
                                            const nextRowIndex = this.excelApi.getUsedRangeObjectNextRowIndex(usedRange);
                                            return this.excelApi.insertTable$(worksheetName, {
                                                name: tableName,
                                                range: `A${nextRowIndex}:A${nextRowIndex}`,
                                                values: [['Metadata']],
                                                rows: [[JSON.stringify(data)]],
                                                numberFormat: RangeObjectNumberFormatText
                                            })
                                        })
                                    );
                                }
                            }),
                            finalize(() => {
                                this.excelApi.autoFitWorksheetColumns$(worksheetName).subscribe();
                                this.excelApi.protectWorksheet$(worksheetName).subscribe();
                            })
                        ).subscribe({
                            next: () => {
                                obs.next(true);
                                obs.complete();
                            }
                        });
                    } else {
                        this.excelApi.addWorksheet$(worksheetName, false, false, this.appState.production ? true : false).pipe(
                            switchMap(() => {
                                return this.excelApi.getWorksheetUsedRange$(worksheetName).pipe(
                                    switchMap((usedRange) => {
                                        const nextRowIndex = this.excelApi.getUsedRangeObjectNextRowIndex(usedRange);
                                        return this.excelApi.insertTable$(worksheetName, {
                                            name: tableName,
                                            range: `A${nextRowIndex}:A${nextRowIndex}`,
                                            values: [['Metadata']],
                                            rows: [[JSON.stringify(data)]],
                                            numberFormat: RangeObjectNumberFormatText
                                        })
                                    })
                                );;
                            }),
                            finalize(() => {
                                this.excelApi.autoFitWorksheetColumns$(worksheetName).subscribe();
                                this.excelApi.protectWorksheet$(worksheetName).subscribe();
                            })
                        ).subscribe({
                            next: () => {
                                obs.next(true);
                                obs.complete();
                            }
                        })
                    }
                }
            })
        })
    }

    processRenameWorksheet(newName) {
        const connectionTableName = this.excelApi.generateTableName(`Connection_${newName}`, true);
        this.excelApi.ensureTable$(DefaultConnectionWorksheet.name, connectionTableName).pipe(
            switchMap(table => {
                if (table) {
                    const connectionData = table.rows[0];
                    let listWorksheetsName = JSON.parse(connectionData[3]);
                    let referenceVariable = JSON.parse(connectionData[14]);
                    listWorksheetsName['objectDataItemsWorksheetName'] = newName;
                    referenceVariable['connectionTableName'] = connectionTableName;
                    const newConnectionInfo = {
                        dataSource: connectionData[0],
                        dataSourceInfo: connectionData[1],
                        dataSourceSettings: connectionData[2],
                        listWorksheetsName: JSON.stringify(listWorksheetsName),
                        listTablesName: connectionData[4],
                        lastAction: connectionData[5],
                        listViews: connectionData[6],
                        viewSelected: connectionData[7],
                        reviewState: connectionData[8],
                        syncState: connectionData[9],
                        syncDataItem: connectionData[10],
                        reviewLocalStatus: connectionData[11],
                        reviewServerStatus: connectionData[12],
                        syncStatus: connectionData[13],
                        referenceVariable: JSON.stringify(referenceVariable),
                        domain: connectionData[15],
                    }
                    return this.processConnectionWorksheet$(newConnectionInfo, connectionTableName);
                } else {
                    return of(null);
                }
            })
        ).pipe(takeUntil(this.destroy$)).subscribe();
    }

    /**
     * @description start mark performance for an action
     * @param key primary key of the performance mark
     */
    registerPerformanceMark(key: string) {
        performance.mark(key);
    }

    /**
     * @description end mark performance for an action and store log message in the message log page
     * @param key primary key of the performance mark
     * @param message log message to show in the message log page
     * @param status status of the log message
     */
    finishPerformanceMark(key: string, message: string, status: 'info' | 'error' = 'info') {
        const now = performance.now();
        const startTime = performance.getEntriesByName(key)[0]?.startTime ?? now;
        const timeRange = (now - startTime) / 1000;
        performance.clearMarks(key);
        const currentTime = DateTime.now().toFormat('HH:mm:ss dd-MM-yyyy');
        this.appFacadeService.setAppActionMessageLog({
            status: status,
            message: `${message.replaceAll('{{timeRange}}', `${timeRange.toFixed(1)}s`)}`,
            time: currentTime
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.resizeObserver.unobserve(document.querySelector('body'));
    }
}
