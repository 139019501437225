import { Injectable, inject } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { HttpService } from '../http/http.service';
import { IAppState } from '@app-state';
import { LogService } from '@utils/services';
import { StateService } from '@utils/state';
import { AppvityCoreService } from './appvity-core.service';
import { sortBy } from 'lodash';
import { IAppvityCoreView } from '@common/schemas';

@Injectable({
    providedIn: 'root'
})
export class EHelpDeskService extends AppvityCoreService {

    getCompanyList$(requestHeaders, requestParams) {
        const requestURL = `${this.state.currentState.domain}/api/v2/helpdesk/company`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('EHelpDeskService', 'getCompanyList$', res);
            })
        );
    }

    getCompanyDetail$(requestHeaders, companyId) {
        const requestURL = `${this.state.currentState.domain}/api/v2/helpdesk/company/${companyId}`;
        return this.http.getItems(requestURL, requestHeaders).pipe(
            tap(res => {
                this.log.log('EHelpDeskService', 'getCompanyDetail$', res);
            })
        );
    }

    getCompanyTicket$(requestHeaders, requestParams) {
        const requestURL = `${this.state.currentState.domain}/api/v2/helpdesk/tickets`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('EHelpDeskService', 'getCompanyTicket$', res);
            })
        );
    }

    getCompanyTicketViews$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/views`;
        if (requestParams == null) {
            requestParams = {
                $count: true,
                $top: 200,
                $filter: `type ne 'pinned' and _model eq 'ticket'`,
                $select: '_id,title,value,createdBy,createdAt,updatedAt,type,order,isDefault,isShow,isActive,userId,viewId,_model'
            }
        }
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => {
                return res.value.reduce((acc: IAppvityCoreView[], cur) => {
                    if (cur?.viewId?.application === 'eHelpdesk.SP') {
                        acc.push({
                            id: cur.viewId._id,
                            title: cur.viewId.title,
                            fields: cur.viewId.value.grid.displayColumns.map((field) => field.name),
                            extendsion: {
                                conditions: cur.viewId.value.conditions,
                                maxRow: cur.viewId.value.maxRows,
                                sortBy: cur.viewId.value.sortBy,
                                viewType: cur.type,
                            }
                        });
                    }
                    return acc;
                }, []);
            }),
            map(res => sortBy(res, (item) => item.title.toLowerCase())),
            tap(res => {
                this.log.log('EHelpDeskService', 'getCompanyTicketViews', res);
            }));
    }

    getCompanyTicketSetting_InternalStatus$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/flow/helpdesk/status`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            tap(res => {
                this.log.log('EHelpDeskService', 'getCompanyTicketSetting_InternalStatus$', res);
            })
        );
    }

    getCompanyTicketSetting_Status$(companyId, requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/flow/helpdesk/accounts/${companyId}/status`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            tap(res => {
                this.log.log('EHelpDeskService', 'getCompanyTicketSetting_Status$', res);
            })
        );
    }

    getCompanyTicketSetting_Priority$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/flow/helpdesk/priority`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            tap(res => {
                this.log.log('EHelpDeskService', 'getCompanyTicketSetting_Priority$', res);
            }))
    }

    getCompanyTicketSetting_Staff$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/flow/helpdesk/users`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            tap(res => {
                this.log.log('EHelpDeskService', 'getCompanyTicketSetting_Staff$', res);
            }))
    }

    getCompanyTicketSetting_Members$(companyId, requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/flow/helpdesk/accounts/${companyId}/users`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            tap(res => {
                this.log.log('EHelpDeskService', 'getCompanyTicketSetting_Members$', res);
            }))
    }

    getCompanyTicketSetting_Field$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/v2/helpdesk/fields`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('EHelpDeskService', 'getCompanyTicketSetting_Field$', res);
            }))
    }

    updateTicketDataItems$(requestHeaders, ticketId, dataItem) {
        const requestURL = `${this.state.currentState.domain}/api/v2/helpdesk/tickets/${ticketId}`;
        return this.http.updateItem(requestURL, dataItem, requestHeaders).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('EHelpDeskService', 'updateTicketDataItems$', res);
            }))
    }

}
