import { Injectable, inject } from '@angular/core';
import { map, tap } from 'rxjs';
import { HttpService } from '../http/http.service';
import { StateService } from '@utils/state';
import { IAppState } from '@app-state';
import { LogService } from '@utils/services';

@Injectable({
    providedIn: 'root'
})
export class AppvityCoreService {
    http = inject(HttpService);
    state = inject(StateService<IAppState>);
    log = inject(LogService);

    /**
    * @description Important:  Worksheet names cannot:
       - Be blank.
       - Contain more than 31 characters.
       - Contain any of the following characters: / \ ? * : [ ]. For example, 02/17/2016 would not be a valid worksheet name, but 02-17-2016 would work fine.
       - Begin or end with an apostrophe ('), but they can be used in between text or numbers in a name.
       - Be named 'History'. This is a reserved word Excel uses internally.
    * @param {string} name Worksheet name.
    * @returns {*}
    * @see https://support.microsoft.com/en-us/office/rename-a-worksheet-3f1f7148-ee83-404d-8ef0-9ff99fbad1f9
    */
       validateViewTitle(title: string, target: string) {
        let error = false;
        let cause = '';
        if (!error && typeof name !== 'string') {
            error = true;
            cause = `${target} is invalid.`;
        }
        if (!error && title === '') {
            error = true;
            cause = `${target} cannot be blank.`;
        }
        if (!error && typeof title === 'string' && /[\/\\?\*:\[\]]/g.test(title)) {
            error = true;
            cause = `${target} is currently containing these characters: / \ ? * : [ ]. Please change to a different name.`;
        }
        return ({ error, cause });
    }
    
    ensureUserLogged$() {
        const requestURL = `${this.state.currentState.domain}/api/me`;
        return this.http.getItems(requestURL).pipe(
            tap(res => {
                this.log.log('AppvityCoreService', 'ensureUserLogged$', res);
            })
        );
    }

    getUserInfo$(requestHeaders: any, requestParams: any) {
        const requestURL = `${this.state.currentState.domain}/api/users`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value[0]),
            tap(res => {
                this.log.log('AppvityCoreService', 'getUserInfo$', res);
            })
        );
    }
}
