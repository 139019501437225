import { Injectable, inject } from '@angular/core';
import { Observable, map, of, switchMap, tap } from 'rxjs';
import { HttpService } from '../http/http.service';
import { StateService } from '@utils/state';
import { IAppState } from '@app-state';
import { LogService } from '@utils/services';
import { ILists, ISPListContext, ISPViews, ListTemplate, IUser, SharePointPageContextPattern, SharePointUrlType, SharePointFileUrlPattern, SharePointCustomListUrlPattern, SharePointDocumentListUrlPattern, SharePointSiteUrlPattern, } from '@common/schemas';
import { OfficeService } from '../office';

@Injectable({
    providedIn: 'root'
})
export class SharePointService {
    http = inject(HttpService);
    state = inject(StateService<IAppState>);
    log = inject(LogService);
    officeApi = inject(OfficeService);
    
    /**
    * @description Important:  Worksheet names cannot:
       - Be blank.
       - Contain more than 31 characters.
       - Contain any of the following characters: / \ ? * : [ ]. For example, 02/17/2016 would not be a valid worksheet name, but 02-17-2016 would work fine.
       - Begin or end with an apostrophe ('), but they can be used in between text or numbers in a name.
       - Be named 'History'. This is a reserved word Excel uses internally.
    * @param {string} name Worksheet name.
    * @returns {*}
    * @see https://support.microsoft.com/en-us/office/rename-a-worksheet-3f1f7148-ee83-404d-8ef0-9ff99fbad1f9
    */
    validateViewTitle(title: string, target: string) {
        let error = false;
        let cause = '';
        if (!error && typeof name !== 'string') {
            error = true;
            cause = `${target} is invalid.`;
        }
        if (!error && title === '') {
            error = true;
            cause = `${target} cannot be blank.`;
        }
        if (!error && typeof title === 'string' && /[\/\\?\*:\[\]]/g.test(title)) {
            error = true;
            cause = `${target} is currently containing these characters: / \\ ? * : [ ]. Please change to a different name.`;
        }
        return ({ error, cause });
    }

    validateMessageErrorResponse(err: any) {
        if (err.error) {
            return this.validateMessageErrorResponse(err.error);
        }
        if (err.message) {
            return err.message.value ?? err.message;
        }
        return err;
    }

    getOfficeSsoToken$() {
        return new Observable<any>((observer) => {
            this.officeApi.getIDToken$().then(res => {
                const { error, ssoToken } = res;
                if (!error) {
                    let appState: IAppState = this.state.currentState;
                    appState.officeSsoToken = ssoToken;
                    this.state.commit(appState);
                    observer.next();
                    observer.complete();
                } else {
                    observer.error(error);
                }
            });
        });
    }

    /**
     * @function requestAccessToken
     * @description Middle-tier access token request
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-on-behalf-of-flow#middle-tier-access-token-request
     */
    requestAccessToken$() {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.backEndUrl}/token/sharepoint`;
            const body = {
                tname: `https://${this.state.currentState.hostname}`,
                assertion: this.state.currentState.officeSsoToken
            }
            this.http.http.post(requestURL, body).pipe(
                map(res => res['value']),
                tap(res => {
                    this.log.log('SharePointService', 'requestAccessToken$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    obs.next({
                        error: err
                    });
                    obs.complete();
                }
            });
        })
    }

    refreshSharePointAccessToken(callbackFn$: Observable<any>) {
        return new Observable<any>(obs => {
            let appState: IAppState = this.state.currentState;
            this.requestAccessToken$().pipe(
                switchMap((res: any) => {
                    if (res.error) {
                        return of(res);
                    }
                    appState.spAccessToken = res;
                    this.state.commit(appState);
                    return callbackFn$;
                }),
            ).subscribe({
                next: res => {
                    if (res.error) {
                        obs.error(res.error);
                        obs.complete();
                    } else {
                        obs.next(res);
                        obs.complete();
                    }
                },
                error: () => {
                    this.getOfficeSsoToken$().pipe(
                        switchMap(() => this.requestAccessToken$()),
                        switchMap((res: any) => {
                            if (res.error) {
                                return of(res);
                            }
                            appState.spAccessToken = res;
                            this.state.commit(appState);
                            return callbackFn$;
                        }),
                    ).subscribe({
                        next: res => {
                            obs.next(res);
                            obs.complete();
                        },
                    })
                }
            })
        })
    }

    /**
     * @function getSPSiteContextInfo
     * @description Get the context info of a site.
     * @return {Observable<*>}
     */
    getSPSiteContextInfo$(serverRelativePath: string) {
        return new Observable<any>(obs => {
            const requestURL = `${serverRelativePath}/_api/contextinfo`;
            this.http.http.post(requestURL, undefined).pipe(
                map((res: any) => {
                    return {
                        siteFullUrl: res.d.GetContextWebInformation.SiteFullUrl,
                        webFullUrl: res.d.GetContextWebInformation.WebFullUrl
                    }
                }),
                tap(res => {
                    this.log.log('SharePointService', 'getSPSiteContextInfo$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getSPSiteUsers$()).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else if (err.status === 404) {
                        obs.next({
                            error: 'site-not-found'
                        });
                        obs.complete();
                    } else {
                        obs.next({
                            error: 'error'
                        });
                        obs.complete();
                    }
                }
            });
        })
    }

    getSharePointList$(requestHeaders: any, requestParams: any) {
        return new Observable(observer => {
            if (this.state.currentState.spAccessToken) {
                this.getListsInSite$(requestHeaders, requestParams).subscribe({
                    next: res => {
                        observer.next(res);
                        observer.complete();
                    }
                });
            } else {
                let appState: IAppState = this.state.currentState;
                this.requestAccessToken$().pipe(
                    switchMap(res => {
                        appState.spAccessToken = res;
                        this.state.commit(appState);
                        return this.getListsInSite$(requestHeaders, requestParams);
                    }),
                ).subscribe({
                    next: res => {
                        observer.next(res);
                        observer.complete();
                    }
                })
            }
        })

    }

    /**
     * @function getListInSite
     * @description Get the collection of lists for a site.
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/sharepoint/dev/sp-add-ins/working-with-lists-and-list-items-with-rest#retrieving-lists-and-list-properties-with-rest
     */
    getListsInSite$(requestHeaders: any, requestParams: any) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/web/lists?$filter=Hidden eq false&$expand=ParentWeb&select=Id,Created,Title,ListTemplate,ListItemEntityTypeFullName,EntityTypeName,EntityTypeName,ParentWebUrl,ParentWeb/Title`;
            this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
                map(res => {
                    return res.d.results.reduce((acc: ILists[], cur: ISPListContext) => {
                        if (cur) {
                            acc.push({
                                id: cur.Id,
                                created: cur.Created,
                                title: cur.Title,
                                listTemplate: ListTemplate[cur.BaseTemplate] ?? 'Other',
                                entityTypeFullName: cur.ListItemEntityTypeFullName,
                                entityTypeName: cur.EntityTypeName,
                                internalName: cur.EntityTypeName.replace('_x0020_', ' '),
                                parentWebUrl: cur.ParentWebUrl ?? '',
                                siteTitle: cur.ParentWeb.Title
                            })
                        }

                        return acc;
                    }, [])
                }),
                tap(res => {
                    this.log.log('SharePointService', 'getListInSite$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getListsInSite$(requestHeaders, requestParams)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        })

    }

    /**
    * @function getSPListViews
    * @description Get the collection of views for a list.
    * @return {Observable<*>}
    */
    getSPListViews$(listId: string) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/Web/Lists(guid'${listId}')/Views?$expand=ViewFields&$select=Id,Title,ViewFields,ViewQuery,Paged&$filter=Hidden eq false`;
            this.http.getItems(requestURL).pipe(
                map(res => {
                    return res.d.results.reduce((acc: ISPViews[], cur: any) => {
                        if (cur) {
                            acc.push({
                                id: cur.Id,
                                title: cur.Title,
                                camlQuery: cur.ViewQuery,
                                camlSelect: cur.ViewFields.SchemaXml,
                                fields: cur.ViewFields.Items.results,
                                paging: cur.Paged
                            })
                        }
                        return acc;
                    }, [])
                }),
                tap(res => {
                    this.log.log('SharePointService', 'getSPListViews$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getSPListViews$(listId)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
    * @function getSPListContext
    * @description Get the list context by title.
    * @return {Observable<*>}
    */
    getSPListContext$(listName: string, isCustomList: boolean) {
        return new Observable<any>(obs => {
            const entityParser = listName.replaceAll('%20', '_x0020_');
            const entityTypeName = `${entityParser.charAt(0).toUpperCase()}${entityParser.slice(1)}${isCustomList ? 'List' : ''}`
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/web/lists?$expand=views&$select=id,title,views/id,views/title&$filter=EntityTypeName eq '${entityTypeName}'`;
            this.http.getItems(requestURL).pipe(
                map(res => {
                    if (res.d.results.length > 0) {
                        return {
                            listId: res.d.results[0].Id,
                            listName: res.d.results[0].Title,
                            views: res.d.results[0].Views.results.reduce((acc, cur) => {
                                acc.push({
                                    viewName: cur.Title,
                                    viewId: cur.Id
                                })
                                return acc;
                            }, [])
                        }
                    } else {
                        return null
                    }

                }),
                tap(res => {
                    this.log.log('SharePointService', 'getSPListContext$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getSPListContext$(listName, isCustomList)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
    * @function getSPListColumns
    * @description Get the collection of columns for a list.
    * @return {Observable<*>}
    */
    getSPListColumns$(listId: string, onUpdateDataSource?: (data: any) => any) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/Web/Lists(guid'${listId}')/Fields?$select=Id,Title,Required,FieldTypeKind,TypeAsString,TypeDisplayName,InternalName,ReadOnlyField,DefaultValue,MaxLength,EnforceUniqueValues,FillInChoice,Choices,FieldTypeKind,CommaSeparator,MinimumValue,MaximumValue,Unit,DisplayFormat,CurrencyLocaleId,SelectionMode,AllowMultipleValues,Hidden,TermSetId,AnchorId,SspId`;
            this.http.getItems(requestURL).pipe(
                map(res => {
                    if (onUpdateDataSource) {
                        return onUpdateDataSource(res.d.results);
                    } else {
                        return res.d.results;
                    }
                }),
                tap(res => {
                    this.log.log('SharePointService', 'getSPListColumns$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getSPListColumns$(listId, onUpdateDataSource)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
    * @function ensureUserSharePoint
    * @description ensure a user is belong to tenant or not
    * @return {Observable<*>}
    */
    ensureUserSharePoint$(username: string) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/web/ensureuser`;
            this.http.http.post<any>(requestURL, {
                'logonName': username
            }).pipe(
                map(res => {
                    if (res.d) {
                        return {
                            id: res.d.Id,
                            loginName: res.d.LoginName,
                            displayName: res.d.Title,
                            username: res.d.UserPrincipalName,
                            email: res.d.Email,
                            principalType: res.d.PrincipalType
                        }
                    } else {
                        return null;
                    }
                }),
                tap(res => {
                    this.log.log('SharePointService', 'ensureUserSharePoint$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.ensureUserSharePoint$(username)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @function getSPList
     * @description Get the list context.
     * @return {Observable<*>}
     */
    getSPListDetail$(listId: string) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/Web/Lists(guid'${listId}')?$expand=ParentWeb&select=Id,Created,Title,ListTemplate,ListItemEntityTypeFullName,EntityTypeName,EntityTypeName,ParentWebUrl,ParentWeb/Title`;
            this.http.getItem(requestURL).pipe(
                map((res: any) => {
                    const dataFormatted: ILists = {
                        id: res.d.Id,
                        created: res.d.Created,
                        title: res.d.Title,
                        listTemplate: ListTemplate[res.d.BaseTemplate],
                        entityTypeFullName: res.d.ListItemEntityTypeFullName,
                        entityTypeName: res.d.EntityTypeName,
                        internalName: res.d.EntityTypeName.replaceAll('_x0020_', ' '),
                        parentWebUrl: res.d.ParentWebUrl ?? '',
                        siteTitle: res.d.ParentWeb.Title
                    }
                    return dataFormatted;
                }),
                tap(res => {
                    this.log.log('SharePointService', 'getSPListDetail$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getSPListDetail$(listId)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        })

    }

    /**
     * @function getSPListItems
     * @description Get the collection of items in a list.
     * @return {Observable<*>}
     */
    getSPListItems$(listId: string, viewSelected: ISPViews, onUpdateDataItems: Function) {
        return new Observable<any>(obs => {
            const selectedField = viewSelected.fields.join(',');
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/Web/Lists(guid'${listId}')/getitems?$select=*,${selectedField}`;
            const dataQuery = {
                'query': {
                    '__metadata': {
                        'type': 'SP.CamlQuery'
                    },
                    'ViewXml': `<View><Query>${viewSelected.camlQuery ?? ''}</Query><ViewFields>${viewSelected.camlSelect}</ViewFields></View>`
                }
            };
            this.http.http.post(requestURL, dataQuery, {
                headers: {
                    'content-type': 'application/json;odata=verbose'
                }
            }).pipe(
                map((res: any) => {
                    if (onUpdateDataItems) {
                        return onUpdateDataItems(res.d.results, viewSelected);
                    }
                    return res.d.results;
                }),
                tap(res => {
                    this.log.log('SharePointService', 'getSPListItems$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getSPListItems$(listId, viewSelected, onUpdateDataItems)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function getSPListItems
     * @description Get the collection of items in a list.
     * @return {Observable<*>}
     */
    getSPListItemsParallels$(listId: string, viewSelected: ISPViews, camlQuery: string, onUpdateDataItems: Function) {
        return new Observable<any>(obs => {
            const selectedField = viewSelected.fields.join(',');
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/Web/Lists(guid'${listId}')/getitems?$select=*,${selectedField}`;
            const dataQuery = {
                'query': {
                    '__metadata': {
                        'type': 'SP.CamlQuery'
                    },
                    'ViewXml': `<View><Query>${camlQuery ?? ''}</Query><ViewFields>${viewSelected.camlSelect}</ViewFields></View>`
                }
            };
            this.http.http.post(requestURL, dataQuery, {
                headers: {
                    'content-type': 'application/json;odata=verbose'
                }
            }).pipe(
                map((res: any) => {
                    if (onUpdateDataItems) {
                        return onUpdateDataItems(res.d.results, viewSelected);
                    }
                    return res.d.results;
                }),
                tap(res => {
                    this.log.log('SharePointService', 'getSPListItems$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getSPListItemsParallels$(listId, viewSelected, camlQuery, onUpdateDataItems)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @function getListFolderByPath$
     * @param {string} folderPath a path url of folder.
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/sharepoint/dev/sp-add-ins/working-with-folders-and-files-with-rest#working-with-folders-by-using-rest
     */
    getListFolderByPath$(folderPath: string) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/web/GetFolderByServerRelativeUrl('${folderPath}')/Folders?$select=Name,Folders,ServerRelativeUrl&$filter=Name ne 'Forms'&$expand=Folders`;
            this.http.getItems(requestURL).pipe(
                map((res: any) => {
                    return res.d.results.reduce((acc: any[], cur: any) => {
                        if (cur) {
                            acc.push({
                                name: cur.Name,
                                itemCount: cur.Folders.results.length,
                                pathUrl: `${folderPath}/${cur.Name}`,
                                serverRelativeUrl: cur.ServerRelativeUrl
                            })
                        }
                        return acc;
                    }, [])
                }),
                tap(res => {
                    this.log.log('SharePointService', 'getListFolderByPath$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getListFolderByPath$(folderPath)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @function getListDataAsStream$
     * @description Retrieves information about the list and its data. Using this API you can retrieve list items in case they use complex fields such as lookups or managed metadata.
     * @param {string} listId guid of list. 
     * @param {string} viewId guid of view selected. 
     * @param {Function} onUpdateDataItems function call back format data item.
     * @param {string} camlQuery Specifies the override XML to be combined with the View CAML. Applies only to the Query/Where part of the View CAML.
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/sharepoint/dev/sp-add-ins/working-with-lists-and-list-items-with-rest#retrieve-items-as-a-stream
     */
    getListDataAsStream$(listId: string, viewId: string, camlQuery: string = '', onUpdateDataItems?: Function, serverRelativeUrl?: string, pagination?: { paging: boolean, rowLimit?: number }) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/Web/Lists(guid'${listId}')/RenderListDataAsStream?View=${viewId}&TryNewExperienceSingle=TRUE&IsGroupRender=FALSE${pagination?.paging ? `&RowLimit=${pagination.rowLimit}` : ''}`;
            const dataParameters = {
                'parameters': {
                    '__metadata': {
                        'type': 'SP.RenderListDataParameters'
                    },
                    'RenderOptions': 2,
                    'AllowMultipleValueFilterForTaxonomyFields': true,
                    'AddRequiredFields': true,
                    'DatesInUtc': true,
                    'OverrideViewXml': camlQuery
                }
            };
            if (serverRelativeUrl) {
                dataParameters['parameters']['FolderServerRelativeUrl'] = serverRelativeUrl;
            }
            this.http.http.post(requestURL, dataParameters, {
                headers: {
                    'content-type': 'application/json;odata=verbose'
                }
            }).pipe(
                map((res: any) => {
                    if (onUpdateDataItems) {
                        return onUpdateDataItems(res.Row);
                    }
                    return res.Row;
                }),
                tap(res => {
                    this.log.log('SharePointService', 'getListDataAsStream$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getListDataAsStream$(listId, viewId, camlQuery, onUpdateDataItems)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @function getListTaxonomyHidden$
     * @description Retrieves information about the list and its data. Using this API you can retrieve list items in case they use complex fields such as lookups or managed metadata.
     * @param {string} path url path of list.
     * @param {Function} onUpdateDataItems function call back format data item.
     * @param {string} camlQuery Specifies the override XML to be combined with the View CAML. Applies only to the Query/Where part of the View CAML.
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/sharepoint/dev/sp-add-ins/working-with-lists-and-list-items-with-rest#retrieve-items-as-a-stream
     */
    getListTaxonomyHidden$(path: string, onUpdateDataItems?: Function) {
        return new Observable<any>(obs => {
            const pathEncode = encodeURIComponent(`'${path}'`);
            const rootSiteUrl = /^https:\/\/[\w%-]+.sharepoint.com(?:\/sites)?(?:\/[\w%-]+)?/.exec(this.state.currentState.serverRelativePath)[0];
            const requestURL = `${rootSiteUrl}/_api/Web/GetListUsingPath(DecodedUrl=@a1)/RenderListDataAsStream?RowLimit=10000&@a1=${pathEncode}`;
            const dataParameters = {
                'parameters': {
                    '__metadata': {
                        'type': 'SP.RenderListDataParameters'
                    },
                    'RenderOptions': 2,
                    'AllowMultipleValueFilterForTaxonomyFields': true,
                    'AddRequiredFields': true,
                    'DatesInUtc': true,
                }
            };
            this.http.http.post(requestURL, dataParameters, {
                headers: {
                    'content-type': 'application/json;odata=verbose'
                }
            }).pipe(
                map((res: any) => {
                    if (onUpdateDataItems) {
                        return onUpdateDataItems(res.Row);
                    }
                    return res.Row;
                }),
                tap(res => {
                    this.log.log('SharePointService', 'getListTaxonomyHidden$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getListTaxonomyHidden$(path, onUpdateDataItems)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @function updateBatchListItems$
     * @description Make batch requests with the REST APIs.
     * @param {string} batchGuid guid of batch. 
     * @param {any} batchBody batch requests.
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/sharepoint/dev/sp-add-ins/make-batch-requests-with-the-rest-apis#executive-summary-of-the-batch-option
     * @see https://github.com/andrewconnell/sp-o365-rest/blob/master/SpRestBatchSample/Scripts/App.js
     * @see https://www.codesharepoint.com/sharepoint-tutorial/batch-crud-operation-using-sharepoint-rest-api
    */
    updateBatchListItems$(batchGuid: string, batchBody: any) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/$batch`;
            this.http.http.post(requestURL, batchBody, {
                headers: {
                    'content-type': `multipart/mixed;boundary="batch_${batchGuid}"`
                },
                responseType: 'text'
            }).pipe(
                tap(res => {
                    this.log.log('SharePointService', 'updateBatchListItems$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.updateBatchListItems$(batchGuid, batchBody)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @function getSPListItems
     * @description Get the collection of users or groups for a site.
     * @return {Observable<*>}
     */
    getSPSiteUsers$() {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/web/siteusers`;
            this.http.getItems(requestURL).pipe(
                map((res: any) => {
                    return res.d.results.reduce((acc: IUser[], cur: any) => {
                        if (cur) {
                            acc.push({
                                id: cur.Id,
                                loginName: cur.LoginName,
                                displayName: cur.Title,
                                username: cur.UserPrincipalName,
                                email: cur.Email,
                                principalType: cur.PrincipalType
                            })
                        }
                        return acc;
                    }, [])
                }),
                tap(res => {
                    this.log.log('SharePointService', 'getSPListItems$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getSPSiteUsers$()).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function getTermSetById
     * @description Get the collection of term set.
     * @return {Observable<*>}
     */
    getTermSetById$(termSetId) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/v2.1/termStore/termSets/${termSetId}/getLegacyChildren()?$filter=isDeprecated%20eq%20false&$top=50`;
            this.http.getItems(requestURL).pipe(
                tap(res => {
                    this.log.log('SharePointService', 'getTermSetById$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res.value);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getTermSetById$(termSetId)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * Get attachments of item
     */
    retrieveItemAttachments$(listId: string, itemId: number) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/Web/Lists(guid'${listId}')/Items(${itemId})/AttachmentFiles?$select=FileName,ServerRelativeUrl`;
            this.http.getItems(requestURL).pipe(
                map((res: any) => {
                    return res.d.results.reduce((acc, cur) => {
                        acc.push({
                            fileName: cur.FileName,
                            url: cur.ServerRelativeUrl
                        })
                        return acc;
                    }, [])
                }),
                tap(res => {
                    this.log.log('SharePointService', 'retrieveItemAttachments$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.retrieveItemAttachments$(listId, itemId)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        })
    }

    uploadAttachmentToItem$(listId: string, itemId: number, file: Blob, fileName: string) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/Web/Lists(guid'${listId}')/Items(${itemId})/AttachmentFiles/add(FileName='${fileName}')`;
            this.http.http.post(requestURL, file, {
                headers: {
                    'content-type': 'application/json;odata=verbose'
                }
            }).pipe(
                tap(res => {
                    this.log.log('SharePointService', 'uploadAttachmentToItem$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.uploadAttachmentToItem$(listId, itemId, file, fileName)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @description Update item fields metadata
     * @param listId 
     * @param itemId 
     * @param metadata 
     */
    updateItemFieldsMetadata$(listId: string, itemId: number, metadata: any) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/Web/Lists(guid'${listId}')/Items(${itemId})`;
            this.http.http.post(requestURL, metadata, {
                headers: {
                    'content-type': 'application/json;odata=verbose',
                    'X-HTTP-Method': 'MERGE',
                    'IF-MATCH': '*'
                }
            }).pipe(
                tap(res => {
                    this.log.log('SharePointService', 'updateItemFieldsMetadata$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.updateItemFieldsMetadata$(listId, itemId, metadata)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @description Upload file to SharePoint
     * @param file 
     * @param folderPath 
     * @param fileName 
     * @see https://learn.microsoft.com/en-us/sharepoint/dev/sp-add-ins/working-with-folders-and-files-with-rest#working-with-large-files-by-using-rest
     */
    uploadFileToSharePoint$(file: Blob, folderPath: string, fileName: string) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/web/GetFolderByServerRelativeUrl('${folderPath}')/Files/add(url='${fileName}',overwrite=false)`;
            this.http.http.post(requestURL, file, {
                headers: {
                    'content-type': 'application/json;odata=verbose'
                },
            }).pipe(
                tap(res => {
                    this.log.log('SharePointService', 'uploadFileToSharePoint$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.uploadFileToSharePoint$(file, folderPath, fileName)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @description Upload file to SharePoint
     * @param folderPath 
     * @param fileName 
     * @see https://learn.microsoft.com/en-us/sharepoint/dev/sp-add-ins/working-with-folders-and-files-with-rest#working-with-large-files-by-using-rest
     * @see https://learn.microsoft.com/en-us/answers/questions/1331708/how-to-upload-a-large-size-file(more-than-250mb)-o
     */
    addEmptyFileToSharePoint$(folderPath: string, fileName: string) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/web/GetFolderByServerRelativeUrl('${folderPath}')/Files/add(url='${fileName}',overwrite=false)?$select=ServerRelativeUrl,ListItemAllFields/Id&$expand=ListItemAllFields`;
            this.http.http.post(requestURL, null, {
                headers: {
                    'content-type': 'application/json;odata=verbose'
                },
            }).pipe(
                map((res: any) => {
                    return res.d ? { 
                        filePath: res.d.ServerRelativeUrl,
                        itemId: res.d.ListItemAllFields ? res.d.ListItemAllFields.Id : null
                    } : null;
                }),
                tap(res => {
                    this.log.log('SharePointService', 'addEmptyFileToSharePoint$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.addEmptyFileToSharePoint$(folderPath, fileName)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @description Start upload chunk file to SharePoint
     * @param file 
     * @param filePath 
     * @param sessionID 
     * @see https://learn.microsoft.com/en-us/previous-versions/office/developer/sharepoint-rest-reference/dn450841(v=office.15)#startupload-method
     */
    startUploadFileToSharePoint$(file: Blob, filePath: string, sessionID: string) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/web/getfilebyserverrelativeurl('${filePath}')/startupload(uploadId=guid'${sessionID}')`;
            this.http.http.post(requestURL, file).pipe(
                tap(res => {
                    this.log.log('SharePointService', 'startUploadFileToSharePoint$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.startUploadFileToSharePoint$(file, filePath, sessionID)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @description Continue upload chunk file to SharePoint
     * @param file 
     * @param filePath 
     * @param sessionID 
     * @param offset 
     * @see https://learn.microsoft.com/en-us/previous-versions/office/developer/sharepoint-rest-reference/dn450841(v=office.15)#continueupload-method
     */

    continueUploadFileToSharePoint$(file: Blob, filePath: string, sessionID: string, offset: number) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/web/getfilebyserverrelativeurl('${filePath}')/continueupload(uploadId=guid'${sessionID}',fileOffset=${offset})`;
            this.http.http.post(requestURL, file).pipe(
                tap(res => {
                    this.log.log('SharePointService', 'continueUploadFileToSharePoint$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.continueUploadFileToSharePoint$(file, filePath, sessionID, offset)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @description Finish upload chunk file to SharePoint
     * @param filePath
     * @param sessionID
     * @param fileOffset
     * @see https://learn.microsoft.com/en-us/previous-versions/office/developer/sharepoint-rest-reference/dn450841(v=office.15)#finishupload-method
     */

    finishUploadFileToSharePoint$(file: Blob, filePath: string, sessionID: string, fileOffset: number) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/web/getfilebyserverrelativeurl('${filePath}')/finishupload(uploadId=guid'${sessionID}',fileOffset=${fileOffset})`;
            this.http.http.post(requestURL, file).pipe(
                tap(res => {
                    this.log.log('SharePointService', 'finishUploadFileToSharePoint$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.finishUploadFileToSharePoint$(file, filePath, sessionID, fileOffset)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        });
    }

    /**
     * @function getTermSetById
     * @description Get the collection of term set.
     * @return {Observable<*>}
     */
    getTermById$(termSetId, termId) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_api/v2.1/termStore/termSets/${termSetId}/terms/${termId}/getLegacyChildren()?$filter=isDeprecated%20eq%20false&$top=50`;
            this.http.getItems(requestURL).pipe(
                tap(res => {
                    this.log.log('SharePointService', 'getTermById$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res.value);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getTermById$(termSetId, termId)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function getTaxonomySuggestion$
     * @description Get the collection of term set suggestion.
     * @return {Observable<*>}
     */
    getTaxonomySuggestion$(termStoreId, termSetId, keySearch, termId = '00000000-0000-0000-0000-000000000000') {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.serverRelativePath}/_vti_bin/TaxonomyInternalService.json/GetSuggestions`;
            const datapost = {
                anchorId: termId,
                excludeKeyword: false,
                excludedTermset: '00000000-0000-0000-0000-000000000000',
                isAddTerms: false,
                isIncludeDeprecated: false,
                isIncludePathData: false,
                isIncludeUnavailable: false,
                isSpanTermSets: false,
                isSpanTermStores: false,
                lcid: 1033,
                sspList: termStoreId,
                termSetList: termSetId,
                start: keySearch
            }
            this.http.http.post(requestURL, datapost).pipe(
                tap(res => {
                    this.log.log('SharePointService', 'getTaxonomySuggestion$', res);
                }),
                map((res: any) => {
                    if (res.d.Groups.length > 0) {
                        return res.d.Groups[0].Suggestions.reduce((acc, cur) => {
                            acc.push({
                                Title: cur.DefaultLabel,
                                IdForTerm: cur.Id
                            });
                            return acc;
                        }, []);
                    } else {
                        return [];
                    }
                }),
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshSharePointAccessToken(this.getTaxonomySuggestion$(termStoreId, termSetId, keySearch, termId)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        const message = this.validateMessageErrorResponse(err);
                        obs.next({ error: message });
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
    * @function ensureSharePointUrl
    * @description ensure the SharePoint url link is valid format or not
    * @param {string} domain the SP url input from user
    * @return {Observable<*>}
    */
    ensureSharePointUrl$(domain: string) {
        return new Observable<any>(obs => {
            let sharepointContext = {
                url: null,
                type: -1,
                list: null,
                defaultViewPage: null,
                extends: null
            }
            if (SharePointFileUrlPattern.test(domain)) {
                const symbol = /(:[fwxpbitvuo]:\/[ers])/.exec(domain)[0];
                sharepointContext.url = domain.replace(symbol, 'sites').split('/').reduce((acc, cur, index, array) => {
                    if (index < array.length - 1) {
                        acc.push(cur);
                    }
                    return acc;
                }, []).join('/');
                sharepointContext.type = SharePointUrlType['SPFileUrl'];
            } else if (SharePointCustomListUrlPattern.test(domain)) {
                const _temp = this.getSiteUrlAndParam(/(?:\/sites)?\/Lists\/([\w\%\-]+)/, domain, 'All Items');
                sharepointContext.type = /\/sites\/[\w%\/-]+/.test(domain) ? SharePointUrlType['SPSubSiteCustomList'] : SharePointUrlType['SPSiteCustomList'];;
                sharepointContext.url = _temp.url;
                sharepointContext.list = _temp.listName;
                sharepointContext.defaultViewPage = _temp.defaultViewPage;
                sharepointContext.extends = _temp['extends'];
            } else if (SharePointDocumentListUrlPattern.test(domain)) {
                const _temp = this.getSiteUrlAndParam(/(?:\/sites)?\/([\w\%\-]+)\/Forms/, domain, 'All Documents');
                sharepointContext.type = /\/sites\/[\w%\/-]+/.test(domain) ? SharePointUrlType['SPSubSiteDocumentList'] : SharePointUrlType['SPSiteDocumentList'];;
                sharepointContext.url = _temp.url;
                sharepointContext.list = _temp.listName;
                sharepointContext.defaultViewPage = _temp.defaultViewPage;
                sharepointContext.extends = _temp['extends'];
            } else if (SharePointPageContextPattern.test(domain)) {
                sharepointContext.url = /^(.*?)(\/Forms\/|\/SitePages\/)/.exec(domain)[1];
                sharepointContext.type = /\/sites\/[\w%\/-]+/.test(domain) ? SharePointUrlType['SPSubSitePageContext'] : SharePointUrlType['SPSitePageContext'];
            } else if (SharePointSiteUrlPattern.test(domain)) {
                sharepointContext.url = domain;
                sharepointContext.type = /\/sites\/[\w%\/-]+/.test(domain) ? SharePointUrlType['SPSubSiteUrl'] : SharePointUrlType['SPSiteUrl'];;
            } else {
                sharepointContext.url = domain;
                sharepointContext.type = -1;
            }
            switch (sharepointContext.type) {
                case -1:
                    obs.next({
                        error: 'Invalid URL'
                    });
                    obs.complete();
                    break;
                default:
                    obs.next(sharepointContext);
                    obs.complete();
                    break;
            }
        });
    }

    getSiteUrlAndParam(pattern: RegExp, domain: string, defaultViewName: string = 'All Items') {
        const listName = pattern.exec(domain)[1];
        const defaultViewPage = /\/[\w%-]+\.aspx/.exec(domain)[0].replaceAll(/\/|\.aspx/g, '');
        const result = {
            url: domain.split('?')[0].replace(/\/[\w%-]+\.aspx/, ''),
            listName: listName,
            defaultViewPage: defaultViewPage === 'AllItems' ? defaultViewName : decodeURIComponent(defaultViewPage)
        };
        const parameters = domain.split('?')[1];
        if (parameters) {
            const arrayParam = parameters.split('&');
            const extension = arrayParam.reduce((acc, cur) => {
                if (cur) {
                    const key = decodeURIComponent(cur.split('=')[0]);
                    const value = decodeURIComponent(cur.split('=')[1]);
                    acc[key] = value;
                }
                return acc;
            }, {});
            result['extends'] = extension;
        }
        return result;
    }

    /**
     * @function generateEditFormUrl
     * @description Generate the edit form url.
     * @param listId 
     * @param itemId 
     * @returns {string}
     * @see https://plumsail.com/docs/forms-sp/how-to/link-to-form.html
     */
    generateEditFormUrl(listId: string, itemId: string) {
        return `${this.state.currentState.serverRelativePath}/_layouts/15/listform.aspx?PageType=6&ListId=${listId}&ID=${itemId}`;
    }
}
