export const environment = {
    production: true,
    version: '1.1.5.30',
    domain: '',
    server: '',
    apiVersion: '',
    officeSsoToken: '',
    spAccessToken: '',
    backEndUrl: 'https://excelsyncservice-qa.appvity.com/excel-sync-service/api/v1',
    crmAccessToken: '',
    efilesToken: '',
    ehelpdeskProps: {
        teamId: '19:7mwX9CUSrvrj_mqV2kh_oBkTT7kQv_ErUhEMRX4jvQs1@thread.tacv2',
        channelId: '19:af19ed8babce4bfc9ca5a394eaffd0d9@thread.tacv2',
        entityId: 'eHelpdesk.SP.afefb67a-b8d7-45e6-10eb-bc4028296898',
        tenantId: 'bfe53760-c13f-4271-9e14-60cf6e1727b8'
    },
    eProductUrl: 'https://teams-qa.appvity.com',
    eProductAppIds: {
        eTask: '6e695791-71e6-42fc-a219-342fd62d53b2',
        eHelpdesk: '04458f97-9a5e-4ae9-be18-7b628dbdc3ed',
        eFiles: '82403d07-4c11-4fa6-980c-7ba3f9ba8213',
    }
}
