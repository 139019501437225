import { environment } from '@environment';

export class GlobalSettings {
    production: boolean;
    version: string;
    domain: string;
    server: string;
    apiVersion: string;
    officeSsoToken: string;
    spAccessToken: string;
    backEndUrl: string;
    crmAccessToken: string;
    efilesToken: string;
    ehelpdeskProps: any;
    eProductUrl: string;
    eProductAppIds: {
        eTask: string;
        eHelpdesk: string;
        eFiles: string;
    };

    constructor(production = false, version = '', domain = '', server = '', apiVersion = '', officeSsoToken = '', spAccessToken = '', backEndUrl = '', crmAccessToken = '', efilesToken = '', ehelpdeskProps = null, eProductUrl = '', eProductAppIds = { eTask: '', eHelpdesk: '', eFiles: ''}) {
        this.production = production;
        this.version = version;
        this.domain = domain;
        this.server = server;
        this.apiVersion = apiVersion;
        this.officeSsoToken = officeSsoToken;
        this.spAccessToken = spAccessToken;
        this.backEndUrl = backEndUrl;
        this.crmAccessToken = crmAccessToken;
        this.efilesToken = efilesToken;
        this.ehelpdeskProps = ehelpdeskProps;
        this.eProductUrl = eProductUrl;
        this.eProductAppIds = eProductAppIds;
    }
}

export class GlobalSettingsBuilder {
    production: boolean;
    version: string;
    domain: string;
    server: string;
    apiVersion: string;
    officeSsoToken: string;
    spAccessToken: string;
    backEndUrl: string;
    crmAccessToken: string;
    efilesToken: string;
    ehelpdeskProps: any;
    eProductUrl: string;
    eProductAppIds: {
        eTask: string;
        eHelpdesk: string;
        eFiles: string;
    };

    setProduction(value = false) {
        this.production = value;
        return this;
    }

    setVersion(value = '') {
        this.version = value;
        return this;
    }

    setDomain(value = '') {
        this.domain = value;
        return this;
    }

    setServer(value = '') {
        this.server = value;
        return this;
    }

    setApiVersion(value = '') {
        this.apiVersion = value;
        return this;
    }

    setOfficeSsoToken(value = '') {
        this.officeSsoToken = value;
        return this;
    }

    setSharePointAccessToken(value = '') {
        this.spAccessToken = value;
        return this;
    }

    setBackEndUrl(value = '') {
        this.backEndUrl = value;
        return this;
    }

    setCRMAccessToken(value = '') {
        this.crmAccessToken = value;
        return this;
    }

    setEFilesToken(value = '') {
        this.efilesToken = value;
        return this;
    }

    setHelpDeskProperties(value = null) {
        this.ehelpdeskProps = value;
        return this;
    }

    setEProductUrl(value = '') {
        this.eProductUrl = value;
        return this;
    }

    setEProductAppIds(value = { eTask: '', eHelpdesk: '', eFiles: '' }) {
        this.eProductAppIds = value;
        return this;
    }

    build() {
        return new GlobalSettings(this.production, this.version, this.domain, this.server, this.apiVersion, this.officeSsoToken, this.spAccessToken, this.backEndUrl, this.crmAccessToken, this.efilesToken, this.ehelpdeskProps, this.eProductUrl, this.eProductAppIds);
    }
}

export const GLOBAL_SETTINGS = new GlobalSettingsBuilder()
    .setProduction(environment.production)
    .setVersion(environment.version)
    .setDomain(environment.domain)
    .setServer(environment.server)
    .setApiVersion(environment.apiVersion)
    .setOfficeSsoToken(environment.officeSsoToken)
    .setSharePointAccessToken(environment.spAccessToken)
    .setBackEndUrl(environment.backEndUrl)
    .setCRMAccessToken(environment.crmAccessToken)
    .setEFilesToken(environment.efilesToken)
    .setHelpDeskProperties(environment.ehelpdeskProps)
    .setEProductUrl(environment.eProductUrl)
    .setEProductAppIds(environment.eProductAppIds)
    .build();
