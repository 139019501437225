import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: 'project/:teamId/:channelId',
        loadChildren: () => (import('./project-details/project-details.routes').then((m) => m.routes))
    },
    {
        path: 'file/:projectId',
        loadChildren: () => (import('./project-details/project-details.routes').then((m) => m.routes))
    },
    {
        path: 'company/:companyId',
        loadChildren: () => (import('./company-ticket-details/company-ticket-details.routes').then((m) => m.routes))
    },
    {
        path: 'lists/:listId',
        loadChildren: () => (import('./sp-list-details/sp-list-details.routes').then((m) => m.routes))
    },
    {
        path: 'ups/:upsId',
        loadChildren: () => (import('./users-profile-details/users-profile-details.routes').then((m) => m.routes))
    },
    {
        path: 'entity/:entityId',
        loadChildren: () => (import('./dataverse-entity-details/dataverse-entity-details.routes').then((m) => m.routes))
    },
    {
        path: 'shell-loading/:redirectUrl',
        loadChildren: () => (import('./shell-loading/shell-loading.routes').then((m) => m.routes))
    },
    {
        path: 'home',
        loadChildren: () => (import('./home/home.routes').then((m) => m.routes))
    },
    {
        path: 'connection',
        loadChildren: () => (import('./connection/connection.routes').then((m) => m.routes))
    },
    // {
    //     path: 'login-failed',
    //     loadChildren: () => (import('./login-failed/login-failed.routes').then((m) => m.routes))
    // },
    {
        path: 'access-denied',
        loadChildren: () => (import('./access-denied/access-denied.routes').then((m) => m.routes))
    },
    {
        path: '',
        redirectTo: 'connection',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'connection'
    }
];
