export const AppvityTicketColumnsDefaultProperties = [
    {
        'name': '_id',
        'displayName': '_ID',
        'hidden': false,
        'dataType': 'String',
        'readOnly': true,
        'require': true
    },
    {
        'name': 'key',
        'displayName': 'ID',
        'hidden': false,
        'isShow': true,
        'dataType': 'String',
        'readOnly': true,
        'require': true
    },
    {
        'name': 'title',
        'displayName': 'Title',
        'hidden': false,
        'isShow': true,
        'dataType': 'String',
        'readOnly': true,
        'require': true
    },
    {
        'name': 'assignedTo',
        'displayName': 'Assignee',
        'hidden': false,
        'dataType': 'Array<User>',
        'isShow': true,
        'readOnly': false,
        'require': false
    },
    {
        'name': 'priority',
        'displayName': 'Priority',
        'hidden': false,
        'dataType': 'String',
        'isShow': true,
        'readOnly': false,
        'require': true
    },
    {
        'name': 'severity',
        'displayName': 'Severity',
        'hidden': false,
        'dataType': 'String',
        'isShow': true,
        'readOnly': true,
        'require': false
    },
    {
        'name': 'status',
        'displayName': 'Status',
        'hidden': false,
        'dataType': 'String',
        'isShow': true,
        'readOnly': false,
        'require': true
    },
    {
        'name': 'internalStatus',
        'displayName': 'Internal Status',
        'hidden': false,
        'dataType': 'String',
        'isShow': true,
        'readOnly': false,
        'require': true
    },
    {
        'name': 'technicalAssistant',
        'displayName': 'Technical Assistant',
        'hidden': false,
        'dataType': 'Array<User>',
        'isShow': true,
        'readOnly': false,
        'require': false
    },
    {
        'name': 'companyId',
        'displayName': 'Account',
        'hidden': false,
        'dataType': 'Company',
        'isShow': true,
        'readOnly': true,
        'require': true
    },
    {
        'name': 'createdAt',
        'displayName': 'Created',
        'hidden': false,
        'dataType': 'DateTime',
        'isShow': true,
        'readOnly': true,
        'require': true
    },
    {
        'name': 'contractId',
        'displayName': 'Contract',
        'hidden': false,
        'dataType': 'Contract',
        'isShow': true,
        'readOnly': true,
        'require': true
    },
    {
        'name': 'requestor',
        'displayName': 'Requestor',
        'hidden': false,
        'dataType': 'User',
        'isShow': true,
        'readOnly': true,
        'require': false
    },
    {
        'name': 'createdBy',
        'displayName': 'Created By',
        'hidden': false,
        'dataType': 'User',
        'isShow': true,
        'readOnly': true,
        'require': true
    },
    {
        'name': 'submitedVia',
        'displayName': 'Submited Via',
        'hidden': false,
        'dataType': 'String',
        'isShow': true,
        'readOnly': true,
        'require': false
    },
    {
        'name': 'type',
        'displayName': 'Type',
        'hidden': false,
        'dataType': 'String',
        'isShow': true,
        'readOnly': true,
        'require': false
    },
    {
        'name': 'desireScale',
        'displayName': 'Desire Scale',
        'hidden': false,
        'dataType': 'String',
        'isShow': true,
        'readOnly': true,
        'require': false
    },
    {
        'name': 'product',
        'displayName': 'Product',
        'hidden': false,
        'dataType': 'String',
        'isShow': true,
        'readOnly': true,
        'require': false
    },
    {
        'name': 'documentUrl',
        'displayName': 'Document Url',
        'hidden': false,
        'dataType': 'String',
        'isShow': true,
        'readOnly': true,
        'require': false
    }
]