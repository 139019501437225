<div mat-dialog-title style="display: flex;">
    <div class="__app-dialog-title">
        <span class="__title">{{data.title}}</span><span
            style="width: 24px; height: 25px; cursor: pointer; padding: 4px;" mat-dialog-close><img
                src="/assets/icons/icon-close.svg" /></span>
    </div>
</div>
<div mat-dialog-content class="__app-dialog-content">
    <ng-container *ngIf="data.content.source === 'SharePoint'">
        <div class="__class-box-info">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                Source
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['source'] ? 'External Source -' + data.content['source'] : '--' }}
            </span>
        </div>
        <div class="__class-box-info">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                Site
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['site'] ?? '--' }}
            </span>
        </div>
        <div class="__class-box-info">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                List
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['list'] ?? '--' }}
            </span>
        </div>
        <div class="__class-box-info">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                View
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['view'] ?? '--' }}
            </span>
        </div>
        <ng-container *ngIf="data.content['folder']">
            <div class="__class-box-info">
                <div style="color: var(--grayscale-400); font-size: 11px;">
                    Folder
                </div>
                <span style="color: var(--grayscale-500);">
                    {{ data.content['folder'] ?? '--' }}
                </span>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="data.content.source === 'eSource'">
        <div class="__class-box-info">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                Source
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['source'] ?? '--' }}
            </span>
        </div>
        <div class="__class-box-info">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                Object
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['object'] ?? '--' }}
            </span>
        </div>
        <div class="__class-box-info" *ngIf="data.content['object'] === 'Bug' || data.content['object'] === 'Task'">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                Channel
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['channelName'] ?? '--' }}
            </span>
        </div>
        <div class="__class-box-info" *ngIf="data.content['object'] === 'Ticket'">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                Account name
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['name'] ?? '--' }}
            </span>
        </div>
        <div class="__class-box-info" *ngIf="data.content['abbreviation']">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                Abbreviation
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['abbreviation'] ?? '--' }}
            </span>
        </div>
        <div class="__class-box-info">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                View
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['view'] ?? '--' }}
            </span>
        </div>
    </ng-container>

    <ng-container *ngIf="data.content.source === 'Dataverse'">
        <div class="__class-box-info">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                Source
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['source'] ? 'External Source -' + data.content['source'] : '--' }}
            </span>
        </div>
        <div class="__class-box-info">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                Table
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['entity'] ?? '--' }}
            </span>
        </div>
        <div class="__class-box-info">
            <div style="color: var(--grayscale-400); font-size: 11px;">
                View
            </div>
            <span style="color: var(--grayscale-500);">
                {{ data.content['view'] ?? '--' }}
            </span>
        </div>
    </ng-container>
</div>