<div mat-dialog-title style="display: flex;">
    <div class="__app-dialog-title">
        <span class="__title">{{data.title}}</span>
        <span class="__icon-close" mat-dialog-close>
            <img src="/assets/icons/icon-close.svg" />
        </span>
    </div>
</div>
<div mat-dialog-content class="__app-dialog-content">
    <div [innerHTML]="data.content"></div>
</div>
<div mat-dialog-actions [class]="'__app-dialog-actions_' + data.actions.length" [ngClass]="panelSize ?? ''">
    <ng-container *ngFor="let action of data.actions; trackBy:trackByFn;">
        <button [class]="action.primary ? '__primary-button' : ''" (click)="action.action()">{{action.text}}</button>
    </ng-container>
</div>
