import { HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { IAppState } from '@app-state';
import { StateService } from '@utils/state';

export function appInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
    const state = inject(StateService<IAppState>);
    const appState: IAppState = state.currentState;
    if (appState.dataSource === 'SharePoint.List') {
        const clonedRequest = req.clone({
            headers: req.headers
                .set('authorization', `Bearer ${appState.spAccessToken}`)
                .set('accept', 'application/json;odata=verbose')
        });
        return next(clonedRequest);
    } else if (appState.dataSource === 'Microsoft.Dataverse') {
        const clonedRequest = req.clone({
            headers: req.headers
                .set('authorization', `Bearer ${appState.crmAccessToken}`)
        });
        return next(clonedRequest);
    } else if (appState.dataSource === 'Appvity.File') {
        const clonedRequest = req.clone({
            headers: req.headers
                .set('authorization', `${appState.efiesToken}`)
        });
        return next(clonedRequest);
    } else {
        if (req.headers.get('authorization')) {
            return next(req);
        } else {
            const clonedRequest = req.clone({
                headers: req.headers.set('authorization', `Bearer ${appState.officeSsoToken}`)
            });
            return next(clonedRequest);
        }
    }
}
