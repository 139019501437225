import { Injectable, inject } from "@angular/core";
import { HttpService } from "../http/http.service";
import { StateService } from "@utils/state";
import { IAppState } from "@app-state";
import { LogService } from "@utils/services";
import { Observable, tap } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ExcelSyncAzureStorageService {
    http = inject(HttpService);
    state = inject(StateService<IAppState>);
    log = inject(LogService);

    downloadFileBlob$(downloadInfo: any) {
        return new Observable(observer => {
            const requestURL = `${this.state.currentState.backEndUrl}/azure-storage/download`;
            this.http.http.post(requestURL, downloadInfo, {
                responseType: 'blob'
            }).pipe(
                tap(res => {
                    this.log.log('ExcelSyncAzureStorageService', 'downloadFileBlob$', res);
                })
            ).subscribe({
                next: res => {
                    observer.next(res);
                    observer.complete();
                },
                error: err => {
                    //current error handling is not good, need to improve
                    //see: https://github.com/angular/angular/issues/19148
                    observer.next({
                        error: 'File not found'
                    });
                    observer.complete();
                }
            });
        });
    }

    clearSessionDirectory$(session: string) {
        return new Observable(observer => {
            const requestURL = `${this.state.currentState.backEndUrl}/azure-storage/directory/${session}`;
            this.http.http.delete(requestURL).pipe(
                tap(res => {
                    this.log.log('ExcelSyncAzureStorageService', 'clearSessionDirectory$', res);
                })
            ).subscribe({
                next: res => {
                    observer.next(res);
                    observer.complete();
                },
                error: err => {
                    observer.next({
                        error: 'Failed to clear session directory'
                    });
                    observer.complete();
                }
            });
        });
    }

    uploadFileStream$(fileName: string, buffer: any, session: string, start: number, fileSize: number) {
        return new Observable(observer => {
            const requestURL = `${this.state.currentState.backEndUrl}/azure-storage/chunk`;
            const formData = new FormData();
            formData.append('file', buffer, fileName);
            formData.append('session', session);
            formData.append('bufferIndex', start.toString());
            formData.append('bufferLength', fileSize.toString());
            this.http.http.post(requestURL, formData).pipe(
                tap(res => {
                    console.log('ExcelSyncAzureStorageService', 'uploadFileStream$', res);
                })
            ).subscribe({
                next: res => {
                    observer.next(res);
                    observer.complete();
                },
                error: () => {
                    observer.error({
                        error: 'Failed to upload file'
                    });
                    observer.complete();
                }
            });
        });
    }

    ensureFile$(fileId: string, session: string, fileName: string) {
        return new Observable(observer => {
            const requestURL = `${this.state.currentState.backEndUrl}/azure-storage/ensure`;
            this.http.http.post(requestURL, {
                fileId,
                session,
                fileName
            }).pipe(
                tap(res => {
                    console.log('ExcelSyncAzureStorageService', 'ensureFile$', res);
                })
            ).subscribe({
                next: res => {
                    observer.next(res);
                    observer.complete();
                },
                error: () => {
                    observer.next({
                        error: 'Failed to ensure file'
                    });
                    observer.complete();
                }
            });
        });
    }

    getFileByFileId$(fileId: string, session: string) {
        return new Observable(observer => {
            const requestURL = `${this.state.currentState.backEndUrl}/azure-storage/file`;
            this.http.http.post(requestURL, {
                fileId,
                session
            }).pipe(
                tap(res => {
                    console.log('ExcelSyncAzureStorageService', 'getFileByFileId$', res);
                })
            ).subscribe({
                next: res => {
                    observer.next(res);
                    observer.complete();
                },
                error: () => {
                    observer.next({
                        error: 'Failed to get file'
                    });
                    observer.complete();
                }
            });
        });
    }
}