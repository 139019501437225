import { Injectable } from "@angular/core";
import { AppvityCoreService } from "./appvity-core.service";
import { Observable, debounceTime, map, of, switchMap, tap } from "rxjs";
import { UPSDefaultView, UPSInfo } from "../../schemas/appvity-ups.schema";


@Injectable({
    providedIn: 'root'
})
export class UPSService extends AppvityCoreService {

    getUPSInfo$() {
        return of(UPSInfo).pipe(debounceTime(200))
    }

    getUPSProperties$(requestHeaders, requestParams) {
        const requestURL = `${this.state.currentState.domain}/api/v2/ups/property?$filter=isActive eq true`;
        return this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('UPSService', 'getUPSProperties$', res);
            }))
    }

    getUPSViews$() {
        return of(UPSDefaultView).pipe(debounceTime(200))
    }

    getUPSDataItems$(requestHeaders, requestParams, skipToken: string = '') {
        return new Observable(observer => {
            let requestURL = `${this.state.currentState.domain}/api/v2/ups/users`;
            if (skipToken !== '') {
                requestParams['$skiptoken'] = skipToken;
            }
            this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
                switchMap(res => {
                    const items = res['value'];
                    const hasNextPage = res['@odata.nextLink'] ? true : false;
                    if (hasNextPage) {
                        const urlParams = new URLSearchParams(res['@odata.nextLink'].split('?')[1]);
                        const nextLink = urlParams.get('$skiptoken');
                        return this.getUPSDataItems$(requestHeaders, requestParams, nextLink).pipe(
                            map((sub_res: any) => {
                                return [...items, ...sub_res];
                            })
                        )
                    } else {
                        return of(items);
                    }
                }),
                tap(res => {
                    this.log.log('UPSService', 'getUPSDataItems$', res);
                })
            ).subscribe(res => {
                observer.next(res);
                observer.complete();
            });
        });

    }

    getUPSDataItemsParrallel$(requestHeaders, requestParams) {
        return new Observable(observer => {
            const requestURL = `${this.state.currentState.domain}/api/v2/ups/users`;
            this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
                map(res => res['value']),
                tap(res => {
                    this.log.log('UPSService', 'getUPSDataItemsParrallel$', res);
                })
            ).subscribe(res => {
                observer.next(res);
                observer.complete();
            });
        });
    }

    updateUPSDataItem$(requestHeaders, userId, dataItem) {
        const requestURL = `${this.state.currentState.domain}/api/v2/ups/users/${userId}`;
        return this.http.updateItem(requestURL, dataItem, requestHeaders).pipe(
            map(res => res.value),
            tap(res => {
                this.log.log('UPSService', 'updateUPSDataItem$', res);
            }))
    }
}