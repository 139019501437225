export const AppvityFileColumnsDefaultProperties = [
    {
        'name': '_id',
        'displayName': 'ID',
        'readOnly': true,
        'isShow': true,
        'dataType': 'String',
        'required': true
    },
    {
        'name': 'name',
        'displayName': 'Name',
        'readOnly': false,
        'isShow': true,
        'dataType': 'String',
        'required': true

    },
    {
        'name': 'title',
        'displayName': 'Title',
        'readOnly': false,
        'isShow': true,
        'dataType': 'String',
        'required': false
    },
    {
        'name': 'description',
        'displayName': 'Description',
        'readOnly': false,
        'isShow': true,
        'dataType': 'String',
        'required': false
    },
    {
        'name': 'createdBy',
        'displayName': 'Created By',
        'readOnly': true,
        'isShow': true,
        'dataType': 'User',
        'required': true
    },
    {
        'name': 'lastModifiedDateTime',
        'displayName': 'Modified',
        'readOnly': true,
        'isShow': true,
        'dataType': 'DateTime',
        'required': false
    },
    {
        'name': 'createdDateTime',
        'displayName': 'Created',
        'readOnly': true,
        'isShow': true,
        'dataType': 'DateTime',
        'required': true
    },
    {
        'name': 'updatedBy',
        'displayName': 'Modified By',
        'readOnly': true,
        'isShow': true,
        'dataType': 'User',
        'required': false
    },
    {
        'name': 'sensitivity',
        'displayName': 'Sensitivity',
        'readOnly': false,
        'isShow': true,
        'dataType': 'Choice',
        'required': false
    },
    {
        'name': 'size',
        'displayName': 'Size',
        'readOnly': true,
        'isShow': true,
        'dataType': 'String',
        'required': true
    },
    {
        'name': 'status',
        'displayName': 'Status',
        'readOnly': false,
        'isShow': true,
        'dataType': 'Choice',
        'required': false
    },
    {
        'name': 'statusApproval',
        'displayName': 'Status Approval',
        'readOnly': true,
        'isShow': true,
        'dataType': 'String',
        'required': false
    },
    {
        'name': 'tags',
        'displayName': 'Tag',
        'readOnly': false,
        'isShow': true,
        'dataType': 'Array',
        'required': false
    },
    {
        'name': 'fileType',
        'displayName': 'File Type',
        'readOnly': true,
        'isShow': true,
        'dataType': 'String',
        'required': true
    },
    {
        'name': 'location',
        'displayName': 'Location',
        'readOnly': true,
        'isShow': true,
        'dataType': 'String',
        'required': true
    }
]

export const AppvityFileAttachColumn = {
    'name': 'AzureStorageFileId',
    'displayName': 'File Attach Id',
    'readOnly': true,
    'isShow': true,
    'dataType': 'String',
    'required': false
}