export const AppvityDataSource = {
    'Appvity.Task': 'Appvity.Task',
    'Appvity.Bug': 'Appvity.Bug',
    'Appvity.Ticket': 'Appvity.Ticket',
    'Appvity.UPS': 'Appvity.UPS',
    'Appvity.File': 'Appvity.File',
}

export const AppvityDataSourceType = ['Appvity.Task', 'Appvity.Bug', 'Appvity.Ticket', 'Appvity.UPS', 'Appvity.File'];

export const AppvityDataSourceTitle = {
    'Appvity.Task': 'Task',
    'Appvity.Bug': 'Bug',
    'Appvity.Ticket': 'Ticket',
    'Appvity.UPS': 'User Profile',
    'Appvity.File': 'File'
}

export enum AppvitySyncStatus {
    'conflict' = 'Conflict',
    'new' = 'New',
    'edited' = 'Update',
    'deleted' = 'Deleted',
    'invalid' = 'Invalid',
    'saveLocalChanged' = 'Overwrite',
    'keepServerChanged' = 'Discard Change',
    'compareConflict' = 'Compare Conflict',
    'syncSuccess' = 'Sync Success',
    'syncFailed' = 'Sync Failed'
}

export const AppvityProjectSourceAvailable = ['eTasks', 'Microsoft.Planner', 'Jira', 'Microsoft.Vsts'];

export const AppvityDefaultProjectSettingsTable = {
    name: '',
    range: '',
    values: [['Team ID', 'Team Name', 'Channel ID', 'Channel Name', 'Project Display Name', 'Abbreviation', 'Time Zone', 'User', 'User Mapping', 'Phase', 'Phase Name', 'Story', 'Story Name', 'Source', 'Task Priority', 'Task Priority Mapping', 'Bug Priority', 'Bug Priority Mapping', 'Task Status', 'Task Status Mapping', 'Bug Status', 'Bug Status Mapping', 'Severity', 'Severity Mapping', 'Task Custom Field', 'Bug Custom Field', 'Date', 'Task Color Style', 'Bug Color Style']],
    rows: [['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']]
}

export const AppvityDefaultProjectMappingTable = {
    name: '',
    range: '',
    values: [['Channel ID', 'Channel Name', 'Project Display Name', 'Project Abbreviation', 'View ID', 'Mapping Worksheet ID', 'Mapping Worksheet Name', 'Data Items Worksheet ID', 'Data Items Worksheet Name', 'Data Items Table Name', 'Review Data Items Worksheet Name', 'Review Data Items Original Table Name', 'Review Data Items New Table Name', 'Review Data Items Edited Table Name', 'Review Data Items Deleted Table Name']],
    rows: [['', '', '', '', '', '', '', '', '', '', '', '', '', '', '']]
}

export const AppvityDefaultProjectSettingUserTable = {
    name: '',
    range: '',
    values: [['User ID', 'Username', 'Display Name', 'Email', 'Source ID', 'Source Name']],
    rows: [['', '', '', '', '', '']]
}

export const AppvityDefaultProjectSettingUserMappingTable = {
    name: '',
    range: '',
    values: [['User ID', 'Username', 'Display Name', 'Email', 'Mapping Source ID', 'Mapping Source', 'Mapping Display Name', 'Mapping Email']],
    rows: [['', '', '', '', '', '', '', '']]
}

export const AppvityDefaultProjectSettingPhaseTable = {
    name: '',
    range: '',
    values: [['Phase ID', 'Phase Name', 'Source ID', 'Source Name', 'Status', 'Start Date', 'End Date']],
    rows: [['', '', '', '', '', '', '']]
}

export const AppvityDefaultProjectSettingStoryTable = {
    name: '',
    range: '',
    values: [['Story ID', 'Story Name', 'Source ID', 'Source Name', 'Description']],
    rows: [['', '', '', '', '']]
}

export const AppvityDefaultProjectSettingSourceTable = {
    name: '',
    range: '',
    values: [['Source ID', 'Display Name', 'Source', 'Enable', 'Project Name']],
    rows: [['', '', '', '', '']]
}

export const AppvityDefaultProjectSettingTaskPriorityTable = {
    name: '',
    range: '',
    values: [['Task Priority ID', 'Type', 'Name', 'Description', 'Color']],
    rows: [['', '', '', '', '']]
}

export const AppvityDefaultProjectSettingTaskPriorityMappingTable = {
    name: '',
    range: '',
    values: [['Task Priority ID', 'Type', 'Name', 'Mapping Source ID', 'Mapping Source', 'Mapping Source Name']],
    rows: [['', '', '', '', '', '']]
}

export const AppvityDefaultProjectSettingBugPriorityTable = {
    name: '',
    range: '',
    values: [['Bug Priority ID', 'Type', 'Name', 'Description', 'Color']],
    rows: [['', '', '', '', '']]
}

export const AppvityDefaultProjectSettingBugPriorityMappingTable = {
    name: '',
    range: '',
    values: [['Bug Priority ID', 'Type', 'Name', 'Mapping Source ID', 'Mapping Source', 'Mapping Source Name']],
    rows: [['', '', '', '', '', '']]
}

export const AppvityDefaultProjectSettingTaskStatusTable = {
    name: '',
    range: '',
    values: [['Task Status ID', 'Type', 'Name', 'Description', 'Color']],
    rows: [['', '', '', '', '']]
}

export const AppvityDefaultProjectSettingTaskStatusMappingTable = {
    name: '',
    range: '',
    values: [['Task Status ID', 'Type', 'Name', 'Mapping Source ID', 'Mapping Source', 'Mapping Source Name']],
    rows: [['', '', '', '', '', '']]
}

export const AppvityDefaultProjectSettingColorStyleTable = {
    name: '',
    range: '',
    values: [['priority', 'status']],
    rows: [['not applied', 'not applied']]
}

export const AppvityDefaultFileMappingTable = {
    name: '',
    range: '',
    values: [['Channel ID', 'Channel Name', 'Mapping Worksheet ID', 'Mapping Worksheet Name', 'Data Items Worksheet ID', 'Data Items Worksheet Name', 'Data Items Table Name', 'Review Data Items Worksheet Name', 'Review Data Items Original Table Name']],
    rows: [['', '', '', '', '', '', '', '', '']]
}

export const AppvityDefaultFileSettingsStatusTable = {
    name: '',
    range: '',
    values: [['_id', 'name', 'description', 'color', 'primary', 'category']],
    rows: [['', '', '', '', '', '']]
}

export const AppvityDefaultFileSettingsSensitivityTable = {
    name: '',
    range: '',
    values: [['_id', 'name', 'privacy']],
    rows: [['', '', '']]
}

export const AppvityDefaultFileSettingsTagTable = {
    name: '',
    range: '',
    values: [['name', 'description']],
    rows: [['', '']]
}

export const AppvityDefaultFileSettingsTable = {
    name: '',
    range: '',
    values: [['channelId', 'channelName', 'viewId', 'viewName', 'objectStatusTableName', 'objectSensitivityTableName', 'objectTagTableName']],
    rows: [['', '', '', '', '', '', '']],
}

export const AppvityDefaultProjectSettingTaskRequiredField = {
    internalName: ['source', 'status', 'priority', 'duration', 'complete', 'projectId'],
    displayName: ['Source', 'Status', 'Priority', 'Duration', 'Complete', 'ProjectId'],
    objectColumn: [
        { name: 'source', displayName: 'Source', hidden: false, dataType: 'String' },
        { name: 'status', displayName: 'Status', hidden: false, dataType: 'String' },
        { name: 'priority', displayName: 'Priority', hidden: false, dataType: 'String' },
        { name: 'duration', displayName: 'Duration', hidden: false, dataType: 'Number' },
        { name: 'complete', displayName: 'Complete', hidden: false, dataType: 'Number' },
        { name: 'projectId', displayName: 'ProjectId', hidden: true, dataType: 'String' },
    ],
    projectSettingDate: {
        startDate: { name: 'startDate', displayName: 'Start Date', hidden: false, dataType: 'DateTime' },
        dueDate: { name: 'dueDate', displayName: 'Due Date', hidden: false, dataType: 'DateTime' }
    }
}

export const AppvityDefaultProjectSettingBugRequiredField = {
    internalName: ['source', 'status', 'priority', 'projectId', 'severity'],
    displayName: ['Source', 'Status', 'Priority', 'ProjectId', 'Severity'],
    objectColumn: [
        { name: 'source', displayName: 'Source', hidden: false, dataType: 'String' },
        { name: 'status', displayName: 'Status', hidden: false, dataType: 'String' },
        { name: 'priority', displayName: 'Priority', hidden: false, dataType: 'String' },
        { name: 'projectId', displayName: 'ProjectId', hidden: true, dataType: 'String' },
        { name: 'severity', displayName: 'Severity', hidden: false, dataType: 'String' }
    ],
    projectSettingDate: {
        startDate: { name: 'startDate', displayName: 'Start Date', hidden: false, dataType: 'DateTime' },
        dueDate: { name: 'dueDate', displayName: 'Due Date', hidden: false, dataType: 'DateTime' }
    }
}

export const AppvityDefaultProjectSettingBugStatusTable = {
    name: '',
    range: '',
    values: [['Bug Status ID', 'Type', 'Name', 'Description', 'Color']],
    rows: [['', '', '', '', '']]
}

export const AppvityDefaultProjectSettingBugStatusMappingTable = {
    name: '',
    range: '',
    values: [['Task Status ID', 'Type', 'Name', 'Mapping Source ID', 'Mapping Source', 'Mapping Source Name']],
    rows: [['', '', '', '', '', '']]
}

export const AppvityDefaultProjectSettingSeverityTable = {
    name: '',
    range: '',
    values: [['Severity ID', 'Name', 'Description', 'Color']],
    rows: [['', '', '', '']]
}

export const AppvityDefaultProjectSettingSeverityMappingTable = {
    name: '',
    range: '',
    values: [['Severity ID', 'Name', 'Mapping Source ID', 'Mapping Source', 'Mapping Source Name']],
    rows: [['', '', '', '', '']]
}

export const AppvityDefaultProjectSettingTaskCustomFieldTable = {
    name: '',
    range: '',
    values: [['Task Custom Field ID', 'Name', 'Display Name', 'Default Value', 'Required', 'Possible Values', 'Visible', 'Data Type']],
    rows: [['', '', '', '', '', '', '', '']]
}

export const AppvityDefaultProjectSettingBugCustomFieldTable = {
    name: '',
    range: '',
    values: [['Bug Custom Field ID', 'Name', 'Display Name', 'Default Value', 'Required', 'Possible Values', 'Visible', 'Data Type']],
    rows: [['', '', '', '', '', '', '', '']]
}

export const AppvityDefaultProjectSettingDateTable = {
    name: '',
    range: '',
    values: [['Name', 'Required', 'Method']],
    rows: [['', '', '']]
}

export const AppvityTaskFieldsDependOnSettings = [
    { name: 'assignedTo', type: 'Array<User>', projectSettingsName: 'user' },
    { name: 'customFieldChoice1', type: 'Array<CustomeField>', projectSettingsName: 'taskCustomField' },
    { name: 'customFieldChoice2', type: 'Array<CustomeField>', projectSettingsName: 'taskCustomField' },
    { name: 'customFieldChoice3', type: 'Array<CustomeField>', projectSettingsName: 'taskCustomField' },
    { name: 'priority', type: 'Array', projectSettingsName: 'taskPriority' },
    { name: 'status', type: 'Array', projectSettingsName: 'taskStatus' },
    { name: 'severity', type: 'Array', projectSettingsName: 'severity' },
    { name: 'phase', type: 'String', projectSettingsName: 'phase' },
    { name: 'phaseName', type: 'String', projectSettingsName: 'phaseName' },
    { name: 'bucket', type: 'Array', projectSettingsName: 'story' },
    { name: 'bucketName', type: 'String', projectSettingsName: 'storyName' },
    { name: 'dueDate', type: 'DateTime', projectSettingsName: 'dueDate' },
    { name: 'startDate', type: 'DateTime', projectSettingsName: 'startDate' },
    { name: 'customFieldDateTime1', type: 'DateTime', projectSettingsName: 'customFieldDateTime1' },
]

export const AppvityBugFieldsDependOnSettings = [
    { name: 'assignedTo', type: 'Array<User>', projectSettingsName: 'user' },
    { name: 'customFieldChoice1', type: 'Array<CustomeField>', projectSettingsName: 'bugCustomField' },
    { name: 'customFieldChoice2', type: 'Array<CustomeField>', projectSettingsName: 'bugCustomField' },
    { name: 'customFieldChoice3', type: 'Array<CustomeField>', projectSettingsName: 'bugCustomField' },
    { name: 'priority', type: 'Array', projectSettingsName: 'bugPriority' },
    { name: 'status', type: 'Array', projectSettingsName: 'bugStatus' },
    { name: 'severity', type: 'Array', projectSettingsName: 'severity' },
    { name: 'phase', type: 'String', projectSettingsName: 'phase' },
    { name: 'phaseName', type: 'String', projectSettingsName: 'phaseName' },
    { name: 'bucket', type: 'Array', projectSettingsName: 'story' },
    { name: 'bucketName', type: 'String', projectSettingsName: 'storyName' },
    { name: 'dueDate', type: 'DateTime', projectSettingsName: 'dueDate' },
    { name: 'startDate', type: 'DateTime', projectSettingsName: 'startDate' },
    { name: 'customFieldDateTime1', type: 'DateTime', projectSettingsName: 'customFieldDateTime1' },
]

export interface IAppvityProjectProps {
    [k: string]: any;
    _id: string;
    projectName: string;
    tenantId: string;
    teamId: string;
    teamName: string;
    channelId: string;
    channelName: string;
    entityId?: string;
    groupId?: string;
}

export interface IAppvityProject {
    [k: string]: any;
    'generalSettings'?: IAppvityProjectGeneralSettings,
    '_id': string;
    'isArchived': boolean;
    'groupTypes': string[];
    'products': {
        'app': string;
        'dateAdded': string;
        'entityId': string
    }[];
    'enable': boolean;
    'propertyBag': any[];
    'tenantId': string;
    'groupId': string;
    'teamName': string;
    'channelId': string;
    'channelName': string;
    'teamId': string;
    'entityId': string;
    'subEntityId': string;
    'teamType': number;
    'teamSiteUrl': string;
    'visibility': string;
    'MSContext': {
        'locale': string;
        'theme': string;
        'subEntityId': string;
        'isFullScreen': string;
        'sessionId': string;
        'chatId': string;
        'meetingId': string;
        'parentMessageId': string;
        'hostClientType': string;
        'tenantSKU': string;
        'jsonTabUrl': string;
        'userLicenseType': string;
        'appSessionId': string;
        'appLaunchId': string;
        'isMultiWindow': string;
        'appIconPosition': string;
        'userClickTime': string;
        'sourceOrigin': string;
        'userFileOpenPreference': string;
        'frameContext': string;
        'isTeamArchived': string;
        'teamType': string;
        'userTeamRole': string;
        'channelRelativeUrl': string;
        'channelId': string;
        'channelName': string;
        'channelType': string;
        'defaultOneNoteSectionId': string;
        'teamId': string;
        'teamName': string;
        'hostTeamGroupId': string;
        'teamSiteUrl': string;
        'teamSiteDomain': string;
        'teamSitePath': string;
        'teamTemplateId': string;
        'teamSiteId': string;
        'ringId': string;
        'tid': string;
        'loginHint': string;
        'upn': string;
        'userPrincipalName': string;
        'userObjectId': string;
        'entityId': string;
        'app': string
    };
    'createdAt': string;
    'updatedAt': string;
    '__v': number;
    'createdDateTime': string;
    'membershipType': string;
}

export interface IAppvityProjectView {
    [k: string]: any;
    '_id': string;
    '_model': string;
    'type': string;
    'viewId': {
        [k: string]: any;
        '_id': string;
        'title': string;
        'value': {
            [k: string]: any;
            'title': string;
            'grid': {
                [k: string]: any;
                'displayColumns': {
                    'dataType': string,
                    'displayName': string,
                    'hidden': boolean,
                    'name': string
                }[];
            };
            'conditions': {
                [k: string]: {
                    [k: string]: any;
                    filter: string;
                }
            };
            'maxRows': number;
        };
        'application': string;
    }
}

export interface IAppvityCompanyTicketView {
    [k: string]: any;
    '_id': string;
    '_model': string;
    'type': string;
    'viewId': {
        [k: string]: any;
        '_id': string;
        'title': string;
        'value': {
            [k: string]: any;
            'title': string;
            'grid': {
                [k: string]: any;
                'displayColumns': {
                    'dataType': string,
                    'displayName': string,
                    'hidden': boolean,
                    'name': string
                }[];
            };
            'conditions': {
                [k: string]: {
                    [k: string]: any;
                    filter: string;
                }
            };
            'maxRows': number;
        };
        'application': string;
    }
}

export interface IAppvityProjectGeneralSettings {
    [k: string]: any;
    'projectName': string;
    'displayName': string;
    'color': string;
    'status': string;
    'startDate': string;
    'closedDate': string;
    'defineFields': {
        'startDate': {
            'required': boolean;
            'method': string;
        };
        'dueDate': {
            'required': boolean;
            'method': string;
        };
    };
    'channelId': string;
    'projectKey': string;
    'timeZone': number;
    'region': string;
    'sources': string[];
}

export interface IAppvityProjectSettings {
    [k: string]: any;
    'teamId': string;
    'teamName': string;
    'channelId': string;
    'channelName': string;
    'projectDisplayName': string;
    'abbreviation': string;
    'timeZone': number;
    'user': any[];
    'phase': any[];
    'phaseName': any[];
    'story': any[];
    'storyName': any[];
    'source': any[];
    'taskPriority': any[];
    'bugPriority': any[];
    'taskStatus': any[];
    'bugStatus': any[];
    'severity': any[];
    'taskCustomField': any[];
    'bugCustomField': any[];
    'date': {
        'startDate': {
            'required': boolean;
            'method': string;
        };
        'dueDate': {
            'required': boolean;
            'method': string;
        };
    };
    'taskColorStyle': {
        [k: string]: string;
        'priority': string;
        'severity'?: string;
        'status': string;
    },
    'bugColorStyle': {
        [k: string]: string;
        'priority': string;
        'severity'?: string;
        'status': string;
    }
}

export interface IAppvityTask {
    [k: string]: any;
    '_id': string;
    'bucket': string[];
    'visible': boolean;
    'assignedTo': any[];
    'attachments': any[];
    'propertyBag': any[];
    'relatedItems': any[];
    'customFieldChoice1': any[];
    'customFieldChoice2': any[];
    'customFieldChoice3': any[];
    'source': string;
    'name': string;
    'owner': string;
    'priority': string;
    'status': string;
    'body': string;
    'startDate': string;
    'dueDate': string;
    'duration': number;
    'complete': number;
    'effort': string;
    'phase': string;
    'phaseName': string;
    'bucketName': string;
    'projectId': string;
    'parentId': string;
    'tenantId': string;
    'createdAt': string;
    'createdBy': {
        'name': string;
        'username': string;
        'sourceId': string;
        'source': string
    };
    'updatedAt': string;
    '_createdBy': string;
    '_createdById': string;
    'teamId': string;
    'channelId': string;
    'groupId': string;
    'entityId': string;
    'sourceId': string;
    'statusId': string;
    'priorityId': string;
    'internalId': number;
    'key': string;
    'customFieldText1': string;
    'customFieldText2': string;
    'customFieldText3': string;
    'customFieldDateTime1': string;
    'updatedBy': string;
    '_updatedBy': string;
    '_updatedById': string;
    'invalidData': string
}

export interface IAppvityBug {
    'bucket': string[],
    'attachments': string[],
    'assignedTo': any[],
    'propertyBag': any[],
    '_id': string,
    'channelId': string,
    'sourceId': string,
    'teamId': string,
    '_createdBy': string,
    '_createdById': string,
    'body': string,
    'createdAt': string,
    'createdBy': {
        'source': string,
        'sourceId': string,
        'email': string,
        'name': string
    },
    'entityId': string,
    'groupId': string,
    'internalId': number,
    'name': string,
    'owner': string,
    'priority': string,
    'source': string,
    'status': string,
    'tenantId': string,
    'updatedAt': string,
    'projectId': {
        '_id': string,
        'displayName': string,
        'projectName': string,
        'sourceId': string
    },
    'reportedBy': {
        '_id': string,
        'sourceId': string,
        'displayName': string,
        'source': string,
        'username': string
    }
}

export interface IAppvityCompany {
    'owner': [
        {
            '_id': string,
            'sourceId': string,
            'displayName': string,
            'username': string,
            'aliasName': string,
        }
    ],
    'deleted': boolean,
    '_id': string,
    'tenantId': string,
    'internalId': number,
    'name': string,
    'description': string,
    'repEmail': string,
    'phone': string,
    'address': string,
    'industry': string,
    'status': string,
    'region': string,
    'key': string,
    'templateId': string,
    'createdBy': {
        'name': string,
        'username': string,
        'sourceId': string,
        'source': string,
    },
    'createdAt': string,
    'updatedAt': string,
    '__v': number,
    'channelId': string,
    'entityId': string,
    'groupId': string,
    'teamId': string,
    'modifiedBy': {
        'name': string,
        'username': string,
        'sourceId': string,
        'source': string,
    }
}

export interface IAppvityCompanyTicketSettings {
    [k: string]: any;
    name: string;
    abbreviation: string;
    customFields?: any[];
    staff: any[];
    members: any[];
    priority: any[];
    internalStatus: any[];
    status: any[];
}

export const AppvityDefaultCompanyTicketMappingTable = {
    name: '',
    range: '',
    values: [['Company Name', 'Company Abbreviation', 'View ID', 'Mapping Worksheet ID', 'Mapping Worksheet Name', 'Data Items Worksheet ID', 'Data Items Worksheet Name', 'Data Items Table Name', 'Review Data Items Worksheet Name', 'Review Data Items Original Table Name', 'Review Data Items New Table Name', 'Review Data Items Edited Table Name', 'Review Data Items Deleted Table Name']],
    rows: [['', '', '', '', '', '', '', '', '', '', '', '', '']]
}

export const AppvityDefaultCompanyTicketSettingsTable = {
    name: '',
    range: '',
    values: [['Company Name', 'Abbreviation', 'Staff Table Name', 'Member Table Name', 'Priority Table Name', 'Status Table Name', 'Custom Field Table Name']],
    rows: [['', '', '', '', '', '', '']]
}

export const AppvityDefaultCompanyTicketSettingStaffOrMemberTable = {
    name: '',
    range: '',
    values: [['User ID', 'Username', 'Display Name', 'Email']],
    rows: [['', '', '', '']]
}

export const AppvityDefaultCompanyTicketSettingPriorityTable = {
    name: '',
    range: '',
    values: [['Priority ID', 'Name', 'Description', 'Color', 'Primary']],
    rows: [['', '', '', '', '']]
}

export const AppvityDefaultCompanyTicketSettingStatusTable = {
    name: '',
    range: '',
    values: [['Status ID', 'Name', 'Category', 'Description', 'Color', 'Primary']],
    rows: [['', '', '', '', '', '']]
}

export const AppvityDefaultCompanyTicketSettingCustomFieldTable = {
    name: '',
    range: '',
    values: [['Custom Field ID', 'Name', 'Display Name', 'Default Value', 'Required', 'Possible Values', 'Control Type']],
    rows: [['', '', '', '', '', '', '']]
}

export const HardCodeTicketView = [{
    'type': 'built-in',
    'order': 1,
    'isShow': true,
    'isActive': true,
    'isDefault': true,
    '_id': '000000000000000000000000',
    'viewId': {
        '_id': '000000000000000000000000',
        'title': 'All Tickets',
        'value': {
            'grid': {
                'allowPageSize': false,
                'groupBy': '',
                'pageSize': '',
                'displayColumns': [
                    {
                        'name': 'assignedTo',
                        'displayName': 'Assignee',
                        'hidden': false,
                        'dataType': 'Array<User>'
                    },
                    {
                        'name': 'status',
                        'displayName': 'Status',
                        'hidden': false,
                        'dataType': 'String'
                    },
                    {
                        'name': 'internalStatus',
                        'displayName': 'Internal Status',
                        'hidden': false,
                        'dataType': 'String'
                    },
                    {
                        'name': 'priority',
                        'displayName': 'Priority',
                        'hidden': false,
                        'dataType': 'String'
                    },
                    {
                        'name': 'severity',
                        'displayName': 'Severity',
                        'hidden': false,
                        'dataType': 'String'
                    },
                    {
                        'name': 'technicalAssistant',
                        'displayName': 'Technical Assistant',
                        'hidden': false,
                        'dataType': 'Array<User>'
                    },
                    {
                        'name': 'tag',
                        'displayName': 'Tag',
                        'hidden': false,
                        'dataType': 'Array'
                    },
                    {
                        'name': 'requestor',
                        'displayName': 'Requestor',
                        'hidden': false,
                        'dataType': 'User'
                    },
                    {
                        'name': 'contractId',
                        'displayName': 'Contract',
                        'hidden': false,
                        'dataType': 'Object<Contract>'
                    },
                    {
                        'name': 'responseTimeStatus',
                        'displayName': 'Response Status',
                        'hidden': false,
                        'dataType': 'Flex<String>'
                    },
                    {
                        'name': 'resolutionTimeStatus',
                        'displayName': 'Resolution Time',
                        'hidden': false,
                        'dataType': 'Flex<String>'
                    }
                ]
            },
            'orderBy': {
                'field': {
                    'name': 'createdAt',
                    'dataType': 'datetime'
                },
                'direction': 'Desc'
            },
            'maxRows': 500
        },
        'application': 'eHelpdesk.SP'
    },
    '_model': 'ticket'
}];

export interface IAppvityCoreField {
    [k: string]: any;
    name: string;
    displayName: string;
    hidden: boolean;
    dataType: string;
    readOnly: boolean;
}

export interface IAppvityCoreView {
    [k: string]: any;
    id: string;
    title: string;
    fields: string[];
    extendsion?: any;
}
