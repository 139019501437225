import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { OdataParams } from './http.schema';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    http = inject(HttpClient);

    getItems(url: string, headers?: HttpHeaders, params?: OdataParams) {
        const requestOptions = {}
        if (headers) {
            requestOptions['headers'] = headers;
        }
        if (params) {
            const requestParams = {}
            if (params.$count) {
                requestParams['$count'] = params.$count;
            }
            if (params.$expand) {
                requestParams['$expand'] = params.$expand;
            }
            if (params.$filter) {
                requestParams['$filter'] = params.$filter;
            }
            if (params.$orderby) {
                requestParams['$orderby'] = params.$orderby;
            }
            if (params.$select) {
                requestParams['$select'] = params.$select;
            }
            if (params.$skiptoken) {
                requestParams['$skiptoken'] = params.$skiptoken;
            }
            if (typeof (params.$skip) === 'number' && params.$skip >= 0) {
                requestParams['$skip'] = params.$skip;
            }
            if (typeof (params.$top) === 'number' && params.$top >= 0) {
                requestParams['$top'] = params.$top;
            }
            requestOptions['params'] = requestParams;
        }
        return this.http.get<any>(url, requestOptions);
    }

    getItem(url: string, headers?: HttpHeaders, params?: any) {
        const requestOptions = {}
        if (headers) {
            requestOptions['headers'] = headers;
        }
        if (params) {
            requestOptions['params'] = params;
        }
        return this.http.get<any>(url, requestOptions);
    }

    getItemsNoPagingHandle(url: string, headers = new HttpHeaders(), options: any) {
        let arrItems: Array<any> = [];
        let skip = 0;
        let oginalUrl: string = url;
        return new Observable<any>((res: any) => {
            let subject = new BehaviorSubject(url);
            subject.subscribe(() => {
                this.getItems(oginalUrl, headers, options).subscribe((data: any) => {
                    let dataJSON = data;
                    let items = dataJSON['value'] as Array<any>;
                    if (items && items.length > 0) {
                        for (let item of items) {
                            arrItems.push(item);
                        }
                        let count = dataJSON['@odata.count'];
                        if (skip < count && arrItems.length < count) {
                            skip += items.length;
                            options.skip = skip;
                            subject.next(url);
                        } else {
                            subject.complete();
                            subject.unsubscribe();
                            res.next(arrItems);
                            res.complete();
                        }
                    } else {
                        subject.complete();
                        subject.unsubscribe();
                        res.next(arrItems);
                        res.complete();
                    }
                });
            });
        });
    }

    addItem(url: string, body: any, headers = new HttpHeaders()) {
        return this.http.post<any>(url, body, { headers: headers });
    }

    deleteItem(url: string, body: any, headers = new HttpHeaders()) {
        return this.http.delete<any>(url, { body: body, headers: headers });
    }

    updateItem(url: string, body: any, headers = new HttpHeaders()) {
        return this.http.patch<any>(url, body, { headers: headers });
    }
}
