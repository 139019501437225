export enum SharePointDataSource {
    'SharePoint.List' = 'SharePoint.List'
}

export enum SharePointList {
    'SP.List.Document' = 'Document Library',
    'SP.List.Custom' = 'Custom List',
    'SP.List.Tasks' = 'Tasks List'
}

export enum SharePointFieldType {
    'Invalid',
    'Integer',
    'Text',
    'Note',
    'DateTime',
    'Counter',
    'Choice',
    'Lookup',
    'Boolean',
    'Number',
    'Currency',
    'URL',
    'Computed',
    'Threading',
    'Guid',
    'MultiChoice',
    'GridChoice',
    'Calculated',
    'File',
    'Attachments',
    'User',
    'Recurrence',
    'CrossProjectLink',
    'ModStat',
    'Error',
    'ContentTypeId',
    'PageSeparator',
    'ThreadIndex',
    'WorkflowStatus',
    'AllDayEvent',
    'WorkflowEventType',
    'MaxItems',
    'OutcomeChoice',
    'Location',
    'Thumbnail'
}

export enum SharePointSyncStatus {
    'conflict' = 'Conflict',
    'new' = 'New',
    'edited' = 'Update',
    'deleted' = 'Deleted',
    'invalid' = 'Invalid',
    'saveLocalChanged' = 'Overwrite',
    'keepServerChanged' = 'Discard Change',
    'compareConflict' = 'Compare Conflict',
    'syncSuccess' = 'Sync Success',
    'syncFailed' = 'Sync Failed'
}

export const SharePointUserPrincipalType = {
    ['None']: 0,
    ['User']: 1,
    ['DistributionList']: 2,
    ['SecurityGroup']: 4,
    ['SharePointGroup']: 8,
    ['All']: 15,
}

export const ListTemplate = {
    '100': 'Custom List',
    '101': 'Document Library',
    '107': 'Tasks List'
}

export const CurrencyConditionMapping = {
    '1066': `{format} [$₫-vi-VN]`,
    '1033': `[$$-chr-Cher-US]{format}`,
    '2057': `[$£-en-GB]{format}`,
    '5121': `{format} [$د.ج.‏-ar-DZ]`,
    '11274': `[$$-es-AR] {format}`,
    '3081': `[$$-en-AU] {format}`,
    '15361': `{format} [$د.ب.‏-ar-BH]`,
    '16394': `[$$b] {format}`,
    '1046': `[$R$] {format}`,
    '3084': `{format} [$$]`,
    '4105': `[$$] {format}`,
    '13322': `[$$] {format}`,
    '9226': `[$$] {format}`,
    '5130': `[$C]{format}`,
    '1029': `{format} [$Kč]`,
    '1030': `[$kr] {format}`,
    '7178': `[$RD$]{format}`,
    '12298': `[$S/.] {format}`,
    '3073': `{format} [$.م.ج]`,
    '17418': `[$C]{format}`,
    '-2': `{format} [$€]`,
    '-1': `[$€] {format}`,
    '4106': `[$Q]{format}`,
    '18442': `[$L.] {format}`,
    '3076': `[$HK$]{format}`,
    '1038': `{format} [$Ft]`,
    '1081': `[$रु] {format}`,
    '1065': `{format} [$ريال]`,
    '2049': `{format} [$.ع.د]`,
    '1037': `[$₪]{format}`,
    '1041': `[$¥]{format}`,
    '11265': `{format} [$.ا.د]`,
    '1042': `[$￦]{format}`,
    '13313': `{format} [$.ك.د]`,
    '12289': `{format} [$.ل.ل]`,
    '4097': `{format} [$.ل.د]`,
    '2058': `[$$]{format}`,
    '6145': `{format} [$.م.د]`,
    '5129': `[$$]{format}`,
    '19466': `[$C$] {format}`,
    '1044': `[$kr] {format}`,
    '8193': `{format} [$.ع.ر]`,
    '1056': `[$Rs] {format}`,
    '6154': `[$B/.] {format}`,
    '15370': `[$Gs] {format}`,
    '2052': `[$￥] {format}`,
    '10250': `[$S/.] {format}`,
    '1045': `{format} [$zł]`,
    '16385': `{format} [$.ق.ر]`,
    '1049': `{format}[$р.]`,
    '1025': `{format} [$.س.ر]`,
    '4100': `[$$]{format}`,
    '1051': `{format} [$Sk]`,
    '1060': `{format} [$SIT]`,
    '7177': `[$R] {format}`,
    '1053': `{format} [$kr]`,
    '2055': `[$SFr.] {format}`,
    '10241': `{format} [$.س.ل]`,
    '1028': `[$NT$]{format}`,
    '1054': `[$฿]{format}`,
    '7169': `{format} [$.ت.د]`,
    '1055': `{format} [$TL]`,
    '14337': `{format} [$.إ.د]`,
    '14346': `[$$U] {format}`,
    '8202': `[$Bs] {format}`,
    '9217': `{format} [$.ي.ر]`
}

export const SharePointDocListAttachColumn: ISPField = {
    id: '00e00e00-0000-0d00-0000-00f000000000',
    internalName: 'AzureStorageFileId',
    title: 'File Attach Id',
    required: false,
    readonlyField: true,
    fieldTypeText: 'Text',
    fieldTypeKind: SharePointFieldType['Text'],
    fieldTypeDisplayName: 'Text',
    hidden: false,
    uniqueValue: false
}

export const SharePointDefaultListSettingsTable = {
    name: '',
    range: '',
    values: [['List ID', 'List Name', 'View ID', 'View Name', 'User', 'Column']],
    rows: [['', '', '', '', '', '']]
}

export const SharePointDefaultListMappingTable = {
    name: '',
    range: '',
    values: [['List ID', 'List Name', 'View ID', 'Mapping Worksheet ID', 'Mapping Worksheet Name', 'Data Items Worksheet ID', 'Data Items Worksheet Name', 'Data Items Table Name', 'Review Data Items Worksheet Name', 'Review Data Items Original Table Name', 'Review Data Items New Table Name', 'Review Data Items Edited Table Name', 'Review Data Items Deleted Table Name']],
    rows: [['', '', '', '', '', '', '', '', '', '', '', '', '']]
}

export const SharePointDefaultListSettingsUserTable = {
    name: '',
    range: '',
    values: [['User ID', 'Username', 'Display Name', 'Email', 'Login Name', 'Principal Type']],
    rows: [['', '', '', '', '', '']]
}

export const SharePointDefaultListSettingsColumnsTable = {
    name: '',
    range: '',
    values: [['Column ID', 'Title', 'Internal Name', 'Required', 'Read Only', 'Hidden', 'Type Text', 'Type Kind', 'Type Display Name', 'Choices', 'Number Format', 'Currency Format', 'DateTime Format', 'Person Format', 'Allow Fill In', 'Default Value', 'Unique Value', 'Max Length', 'Taxonomy Setting']],
    rows: [['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']]
}

export const SharePointDefaultListTaxonomyHiddenTable = {
    name: '',
    range: '',
    values: [['ID', 'Title', 'Term Store ID', 'Term Set ID', 'Term ID']],
    rows: [['', '', '', '', '']]
}

export interface ISPListSettings {
    [k: string]: any;
    listId: string;
    listName: string;
    viewId: string;
    viewName: string;
    columns: ISPField[];
    taxonomyHidden: ITaxonomyHidden[];
    siteUsers: IUser[];
}

export interface ISiteContext {
    [k: string]: any;
    '@odata.context': string,
    'createdDateTime': string,
    'description': string,
    'id': string,
    'lastModifiedDateTime': string,
    'name': string,
    'webUrl': string,
    'displayName': string,
    'root': any,
    'siteCollection': {
        'hostname': string
    }
}

export interface ISPListContext {
    [k: string]: any;
    BaseTemplate: number;
    BaseType: number;
    Created: string;
    Id: string;
    LastItemDeletedDate: string;
    LastItemModifiedDate: string;
    LastItemUserModifiedDate: string;
    ListItemEntityTypeFullName: string;
    MajorVersionLimit: number;
    MultipleDataList: boolean;
    Title: string;
    EntityTypeName: string;
    ParentWebUrl?: string;
    ParentWeb: any;
}

export interface IGroup {
    [k: string]: any;
    id: string;
    loginName: string;
    displayName: string;
    principalType: number;
}

export interface IUser extends IGroup {
    username: string;
    email: string;
}

export interface ILists {
    [k: string]: any;
    id: string;
    title: string;
    created: string;
    listTemplate: string;
    views?: ISPViews[];
    columns?: ISPField[];
    items?: any[];
    entityTypeFullName: string;
    entityTypeName: string;
    internalName: string;
    parentWebUrl: string
    siteTitle: string;
}

export interface ISPViews {
    [k: string]: any;
    id: string;
    title: string;
    fields: string[];
    camlQuery?: string;
    camlSelect: string;
    paging: boolean;
}

export interface ISPField {
    [k: string]: any;
    id: string;
    internalName: string;
    title: string;
    required: boolean;
    defaultValue?: any;
    readonlyField: boolean;
    fieldTypeText: string;
    fieldTypeKind: number;
    fieldTypeDisplayName: string;
    choices?: any[];
    maxLength?: number;
    uniqueValue: boolean;
    numberFormat?: INumberFieldProperties;
    currencyFormat?: ICurrencyFormat;
    dateTimeFormat?: IDateFieldPropeties;
    personFormat?: IUserFieldProperties;
    allowFillIn?: boolean;
    taxonomySetting?: ITaxonomyFieldProperties;
    hidden: boolean;
}

export interface ITaxonomyHidden {
    [k: string]: any;
    ID: number;
    Title: string;
    IdForTerm: string;
    IdForTermSet: string;
    IdForTermStore: string;
}

export interface IListItem {
    [k: string]: any;
    id: string;
}

export interface INumberFieldProperties {
    [k: string]: any;
    maxValue: number;
    minValue: number;
    commaSeparator: boolean;
    decimalPlace: -1 | 0 | 1 | 2 | 3 | 4 | 5;
    unit?: NumberUnitType;
}

export interface ICurrencyFormat extends INumberFieldProperties {
    currencyLocaleId: number;
}

export interface IDateFieldPropeties {
    dateFormat?: string;
    timeFormat?: string;
    dateTimeCalendarType?: number;
    includeTime: number;
}

export interface IUserFieldProperties {
    allowMultipleValues: boolean;
    allowSelectionOfGroup: number;
}

export interface ITaxonomyFieldProperties {
    termStoreId: string; //map with SspId
    termSetId: string; //map with TermSetId
    termId?: string; //map with AnchorId
}

export type NumberUnitType = 'Percentage' | 'Custom' | string;

export enum SharePointUrlType {
    'SPSitePageContext',
    'SPSiteCustomList',
    'SPSiteDocumentList',
    'SPSiteUrl',
    'SPSubSitePageContext',
    'SPSubSiteCustomList',
    'SPSubSiteDocumentList',
    'SPSubSiteUrl',
    'SPFileUrl'
}

/**
 * Pattern of some page context of SharePoint such as settings page, site context page, etc.
 * @example 'https://appvity.sharepoint.com/sites/eXsyncTestingTeams/Forms/_layouts/15/settings.aspx'
 */
export const SharePointPageContextPattern = /^https:\/\/[\w%-]+.sharepoint.com(?:\/sites)?(?:\/[\w%\/-]+)?(\/Forms|\/SitePages)(?:\/_layouts\/15)?\/[\w%-]+\.aspx/

/**
 * Pattern of link copy of file, folder.
 * @see https://techcommunity.microsoft.com/t5/sharepoint/what-is-f-r-in-shared-urls/m-p/234117
 * @example 'https://appvity.sharepoint.com/:f:/s/eXsyncTestingTeams/EnB7Sl9GFxFMp-vSjSb7Zd4B80KvkDtQmRIfO1yzTVWGsA?e=MBPg6Z'
 * :f: folder
 * :w: word
 * :x: excel
 * :p: pdf
 * :b: power point
 * :i: image
 * :t: text file
 * :v: video
 * :o: one note
 * :u: other file
 * ============
 * r: read only
 * e: edit
 * s: share or site
 */
export const SharePointFileUrlPattern = /^https:\/\/[\w%-]+.sharepoint.com\/:[fwxpbitvuo]:\/[ers]\/[\w%\/-]+/

/**
 * Pattern of SharePoint custom list.
 * @example 'https://appvity.sharepoint.com/sites/eXsyncTestingTeams/Lists/TB%20Shopping/AllItems.aspx'
 */
export const SharePointCustomListUrlPattern = /^https:\/\/[\w%-]+.sharepoint.com(?:\/sites)?(?:\/[\w%\/-]+)?\/Lists\/[\w%-]+\/[\w%-]+\.aspx/

/**
 * Pattern of SharePoint document list.
 * @example 'https://appvity.sharepoint.com/sites/eXsyncTestingTeams/new%20doc/Forms/AllItems.aspx'
 */
export const SharePointDocumentListUrlPattern = /^https:\/\/[\w%-]+.sharepoint.com(?:\/sites)?(?:\/[\w%\/-]+)?\/[\w%-]+\/Forms\/[\w%-]+\.aspx/

/**
 * Pattern of SharePoint site.
 * @example 'https://appvity.sharepoint.com/sites/eXsyncTestingTeams'
 */
export const SharePointSiteUrlPattern = /^https:\/\/[\w%-]+.sharepoint.com(?:\/sites)?(?:\/[\w%\/-]+)?/