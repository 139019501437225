import { Injectable, inject } from "@angular/core";
import { HttpService } from "../http/http.service";
import { StateService } from "@utils/state";
import { IAppState } from "@app-state";
import { LogService } from "@utils/services";
import { Observable, map, of, switchMap, tap } from "rxjs";
import { OfficeService } from "../office";
import { EntitiesFilterQueryString, ICRMEntityContext, IEntity, IEntityColumn, IEntityOptionSet, IEntityRelationship, IEntityView } from "../../schemas/crm-dynamics.schema";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class PowerAppsDataverseService {
    http = inject(HttpService);
    state = inject(StateService<IAppState>);
    log = inject(LogService);
    officeApi = inject(OfficeService);

    /**
    * @description Important:  Worksheet names cannot:
       - Be blank.
       - Contain more than 31 characters.
       - Contain any of the following characters: / \ ? * : [ ]. For example, 02/17/2016 would not be a valid worksheet name, but 02-17-2016 would work fine.
       - Begin or end with an apostrophe ('), but they can be used in between text or numbers in a name.
       - Be named 'History'. This is a reserved word Excel uses internally.
    * @param {string} title Worksheet name.
    * @returns {*}
    * @see https://support.microsoft.com/en-us/office/rename-a-worksheet-3f1f7148-ee83-404d-8ef0-9ff99fbad1f9
    */
    validateViewTitle(title: string, target: string) {
        let error = false;
        let cause = '';
        if (!error && typeof title !== 'string') {
            error = true;
            cause = `${target} is invalid.`;
        }
        if (!error && title === '') {
            error = true;
            cause = `${target} cannot be blank.`;
        }
        if (!error && typeof title === 'string' && /[\/\\?\*:\[\]]/g.test(title)) {
            error = true;
            cause = `${target} is currently containing these characters: / \\ ? * : [ ]. Please change to a different name.`;
        }
        return ({ error, cause });
    }

    getOfficeSsoToken$() {
        return new Observable<any>((observer) => {
            this.officeApi.getIDToken$().then(res => {
                const { error, ssoToken } = res;
                if (!error) {
                    let appState: IAppState = this.state.currentState;
                    appState.officeSsoToken = ssoToken;
                    this.state.commit(appState);
                    observer.next();
                    observer.complete();
                } else {
                    observer.error(error);
                }
            });
        });
    }

    /**
     * @function requestAccessToken
     * @description Middle-tier access token request
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-on-behalf-of-flow#middle-tier-access-token-request
     */
    requestAccessToken$() {
        const requestURL = `${this.state.currentState.backEndUrl}/token/crmtoken`;
        const body = {
            evn_name: this.state.currentState.domain,
            assertion: this.state.currentState.officeSsoToken
        }
        return this.http.http.post(requestURL, body).pipe(
            map(res => res['value']),
            tap(res => {
                this.log.log('PowerAppsDataverseService', 'requestAccessToken$', res);
            })
        );
    }

    refreshCRMAccessToken(callbackFn$: Observable<any>) {
        return new Observable<any>(obs => {
            let appState: IAppState = this.state.currentState;
            this.requestAccessToken$().pipe(
                switchMap(res => {
                    appState.crmAccessToken = res;
                    this.state.commit(appState);
                    return callbackFn$;
                }),
            ).subscribe({
                next: res => {
                    obs.next(res);
                    obs.complete();
                },
                error: () => {
                    this.getOfficeSsoToken$().pipe(
                        switchMap(() => this.requestAccessToken$()),
                        switchMap(res => {
                            appState.crmAccessToken = res;
                            this.state.commit(appState);
                            return callbackFn$;
                        }),
                    ).subscribe({
                        next: res => {
                            obs.next(res);
                            obs.complete();
                        },
                    })
                }
            })
        })
    }

    /**
     * @function getEnvironmentContextInfo
     * @description Get the context info of environment.
     * @return {Observable<*>}
     */
    getEnvironmentContextInfo$(environmentUrl: string) {
        return new Observable<any>(obs => {
            const requestURL = `${environmentUrl}/api/data/v9.2/organizations?$select=powerappsmakerbotenabled`;
            this.http.http.get(requestURL).pipe(
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'getEnvironmentContextInfo$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.getEnvironmentContextInfo$(environmentUrl)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else if (err.status === 404) {
                        obs.next({
                            error: 'environment-not-found'
                        });
                        obs.complete();
                    } else {
                        obs.next({
                            error: 'error'
                        });
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityMetadata
     * @description query list entity metadata (table metadata)
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/query-metadata-web-api#querying-the-entitymetadata-entity-type
     */
    queryEntityMetadata$(requestHeaders: any, requestParams: any = {}) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.domain}/api/data/v9.2/EntityDefinitions?$select=MetadataId,DisplayName,LogicalName,CreatedOn,TableType,EntitySetName,IsActivity`;
            if (requestParams['$filter']) {
                requestParams['$filter'] = `${requestParams['$filter']} and ${EntitiesFilterQueryString}`
            } else {
                requestParams['$filter'] = EntitiesFilterQueryString;
            }
            this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
                map(res => {
                    return res.value.reduce((acc: IEntity[], cur: ICRMEntityContext) => {
                        if (cur) {
                            acc.push({
                                id: cur.MetadataId,
                                columns: [],
                                logicalName: cur.LogicalName,
                                entitySetName: cur.EntitySetName,
                                created: cur.CreatedOn,
                                title: cur.DisplayName.UserLocalizedLabel.Label,
                                tableType: cur.IsActivity ? 'Activity' : cur.TableType
                            })
                        }
                        return acc;
                    }, [])
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryEntityMetadata$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryEntityMetadata$(requestHeaders, requestParams)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityMetadataDetail
     * @description query list entity metadata (table metadata)
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/query-metadata-web-api#querying-entitymetadata-attributes
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/retrieve-metadata-name-metadataid#retrieve-definition-items-by-metadataid
     */
    queryEntityMetadataDetail$(entityId: string, requestHeaders: any, requestParams: any = {}) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.domain}/api/data/v9.2/EntityDefinitions(${entityId})?$select=MetadataId,DisplayName,LogicalName,CreatedOn,TableType,EntitySetName`;
            this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
                map(res => {
                    return {
                        id: res.MetadataId,
                        logicalName: res.LogicalName,
                        entitySetName: res.EntitySetName,
                        created: res.CreatedOn,
                        title: res.DisplayName.UserLocalizedLabel.Label,
                        tableType: res.IsActivity ? 'Activity' : res.TableType
                    }
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryEntityMetadataDetail$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryEntityMetadataDetail$(entityId, requestHeaders, requestParams)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityMetadataLookupDetail
     * @description query list entity metadata (table metadata)
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/query-metadata-web-api#querying-entitymetadata-attributes
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/retrieve-metadata-name-metadataid#retrieve-definition-items-by-metadataid
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/virtual-entities/get-started-ve#limitations-of-virtual-tables
     */
    queryEntityMetadataLookupDetail$(logicalName: string, requestHeaders: any, requestParams: any = {}) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.domain}/api/data/v9.2/EntityDefinitions(LogicalName='${logicalName}')?$select=MetadataId,DisplayName,LogicalName,PrimaryNameAttribute,EntitySetName,TableType`;
            this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
                map(res => {
                    return {
                        id: res.MetadataId,
                        logicalName: res.LogicalName,
                        entitySetName: res.EntitySetName,
                        primaryNameColumn: res.PrimaryNameAttribute,
                        title: res.DisplayName.UserLocalizedLabel.Label,
                        tableType: res.IsActivity ? 'Activity' : res.TableType
                    }
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryEntityMetadataLookupDetail$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryEntityMetadataLookupDetail$(logicalName, requestHeaders, requestParams)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityMetadataRelationship
     * @description query list entity relationship metadata
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/query-metadata-web-api#querying-entitymetadata-attributes
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/retrieve-metadata-name-metadataid#retrieve-definition-items-by-metadataid
    */
    queryEntityMetadataRelationship$(logicalName: string, requestHeaders: any, requestParams: any = {}) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.domain}/api/data/v9.2/EntityDefinitions(LogicalName='${logicalName}')?$expand=OneToManyRelationships($select=MetadataId,ReferencingAttribute,ReferencingEntityNavigationPropertyName),ManyToOneRelationships($select=MetadataId,ReferencingAttribute,ReferencingEntityNavigationPropertyName),ManyToManyRelationships&$select=MetadataId`;
            this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
                map(res => {
                    const O2M: IEntityRelationship[] = res.OneToManyRelationships.reduce((acc: IEntityRelationship[], cur) => {
                        if (cur) {
                            acc.push({
                                id: cur.MetadataId,
                                referencingAttribute: cur.ReferencingAttribute,
                                referencingNavigationPropertyName: cur.ReferencingEntityNavigationPropertyName
                            })
                        }
                        return acc;
                    }, []);
                    const M2O: IEntityRelationship[] = res.ManyToOneRelationships.reduce((acc: IEntityRelationship[], cur) => {
                        if (cur) {
                            acc.push({
                                id: cur.MetadataId,
                                referencingAttribute: cur.ReferencingAttribute,
                                referencingNavigationPropertyName: cur.ReferencingEntityNavigationPropertyName
                            })
                        }
                        return acc;
                    }, []);
                    return {
                        oneToManyRelationships: O2M,
                        manyToOneRelationships: M2O
                    }
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryEntityMetadataRelationship$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryEntityMetadataRelationship$(logicalName, requestHeaders, requestParams)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityAttributeMetadata
     * @description query list entity attribute metadata (column table metadata)
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/query-metadata-web-api#querying-entitymetadata-attributes
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/reference/attributemetadata?view=dataverse-latest
     */
    queryEntityAttributeMetadata$(entityLogicalName: string) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.domain}/api/data/v9.2/EntityDefinitions(LogicalName='${entityLogicalName}')/Attributes/Microsoft.Dynamics.CRM.AttributeMetadata?api-version=9.1`;
            this.http.getItems(requestURL).pipe(
                map(res => {
                    return res.value;
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryEntityAttributeMetadata$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryEntityAttributeMetadata$(entityLogicalName)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityAttributeMetadata
     * @description query list entity attribute metadata (column table metadata)
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/query-metadata-web-api#querying-entitymetadata-attributes
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/reference/attributemetadata?view=dataverse-latest
     */
    queryRelatedEntityAttributeMetadata$(entityLogicalName: string) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.domain}/api/data/v9.2/EntityDefinitions(LogicalName='${entityLogicalName}')/Attributes/Microsoft.Dynamics.CRM.AttributeMetadata?api-version=9.1`;
            this.http.getItems(requestURL).pipe(
                map(res => {
                    return res.value;
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryRelatedEntityAttributeMetadata$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryRelatedEntityAttributeMetadata$(entityLogicalName)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityOptionSetMetadata
     * @description query list entity option set metadata (choice option)
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/query-metadata-web-api#querying-entitymetadata-attributes
     */
    queryEntityOptionSetPicklistMetadata$(entityLogicalName: string, requestHeaders: any, requestParams: any = {}) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.domain}/api/data/v9.2/EntityDefinitions(LogicalName='${entityLogicalName}')/Attributes/Microsoft.Dynamics.CRM.PicklistAttributeMetadata?$expand=OptionSet($select=Options)&api-version=9.1&$select=MetadataId,OptionSet,LogicalName`;
            this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
                map(res => {
                    return res.value.reduce((acc: IEntityOptionSet[], cur: any) => {
                        if (cur) {
                            const columnChoices = cur.OptionSet?.Options.reduce((acc_option, cur_option) => {
                                if (cur_option) {
                                    acc_option.push({
                                        value: cur_option.Value,
                                        text: cur_option.Label.UserLocalizedLabel?.Label ?? '',
                                        color: cur_option.Color ?? null
                                    });
                                }
                                return acc_option;
                            }, [])
                            acc.push({
                                id: cur.MetadataId,
                                attributeLogicalName: cur.LogicalName,
                                choices: columnChoices
                            })
                        }
                        return acc;
                    }, [])
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryEntityOptionSetPicklistMetadata$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryEntityOptionSetPicklistMetadata$(entityLogicalName, requestHeaders, requestParams)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityOptionSetMetadata
     * @description query list entity option set metadata (choice option)
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/query-metadata-web-api#querying-entitymetadata-attributes
     */
    queryEntityOptionSetMultiSelectPicklistMetadata$(entityLogicalName: string, requestHeaders: any, requestParams: any = {}) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.domain}/api/data/v9.2/EntityDefinitions(LogicalName='${entityLogicalName}')/Attributes/Microsoft.Dynamics.CRM.MultiSelectPicklistAttributeMetadata?$expand=OptionSet($select=Options)&api-version=9.1&$select=MetadataId,OptionSet,LogicalName`;
            this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
                map(res => {
                    return res.value.reduce((acc: IEntityOptionSet[], cur: any) => {
                        if (cur) {
                            const columnChoices = cur.OptionSet?.Options.reduce((acc_option, cur_option) => {
                                if (cur_option) {
                                    acc_option.push({
                                        value: cur_option.Value,
                                        text: cur_option.Label.UserLocalizedLabel?.Label ?? '',
                                        color: cur_option.Color ?? null
                                    });
                                }
                                return acc_option;
                            }, [])
                            acc.push({
                                id: cur.MetadataId,
                                attributeLogicalName: cur.LogicalName,
                                choices: columnChoices
                            })
                        }
                        return acc;
                    }, [])
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryEntityOptionSetMultiSelectPicklistMetadata$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryEntityOptionSetMultiSelectPicklistMetadata$(entityLogicalName, requestHeaders, requestParams)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityViewsMetadata
     * @description query list entity views metadata
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/reference/retrieveunpublishedmultiple?view=dataverse-latest
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/retrieve-and-execute-predefined-queries#predefined-queries
     */
    queryEntityViewsMetadata$(entityLogicalName: string, requestHeaders: any, requestParams: any = {}) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.domain}/api/data/v9.2/savedqueries/Microsoft.Dynamics.CRM.RetrieveUnpublishedMultiple()?$select=layoutjson,name,returnedtypecode,savedqueryid,fetchxml&$filter=(returnedtypecode eq '${entityLogicalName}' and (querytype eq 0 or querytype eq 1 or querytype eq 2 or querytype eq 4 or querytype eq 64))`;
            this.http.getItems(requestURL, requestHeaders, requestParams).pipe(
                map(res => {
                    return res.value.reduce((acc: IEntityView[], cur: any) => {
                        if (cur) {
                            const selectedColumns = JSON.parse(cur.layoutjson)?.Rows[0]?.Cells?.reduce((acc_col, cur_col) => {
                                acc_col.push({
                                    internalName: cur_col.Name,
                                    relatedTable: cur_col.RelatedEntityName
                                })
                                return acc_col;
                            }, []) ?? [];
                            acc.push({
                                id: cur.savedqueryid,
                                title: cur.name,
                                fields: selectedColumns,
                                fetchXml: cur.fetchxml
                            })
                        }
                        return acc;
                    }, [])
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryEntityViewsMetadata$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryEntityViewsMetadata$(entityLogicalName, requestHeaders, requestParams)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityDataItems
     * @param {string} entitySetName
     * @param {string} fetchXml fetch query xml of view
     * @description query list entity data items
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/retrieve-and-execute-predefined-queries#predefined-queries
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/use-fetchxml-web-api
     */
    queryEntityDataItems$(entitySetName: string, fetchxml: string = null, modifiedFieldName: string, onUpdateDataSource: (data: any) => any, pagingCookie: string = '') {
        return new Observable<any>(obs => {
            let requestURL = `${this.state.currentState.domain}/api/data/v9.2/${entitySetName}?$select=${modifiedFieldName}`;
            if (fetchxml) {
                requestURL += `&fetchXml=${fetchxml}`;
            }
            if (pagingCookie !== '') {
                requestURL += `&$skiptoken=${pagingCookie}`;
            }
            const requestHeaders = new HttpHeaders()
                .set('prefer', 'odata.include-annotations="*"');
            this.http.getItems(requestURL, requestHeaders).pipe(
                switchMap((res) => {
                    const items = onUpdateDataSource(res['value']);
                    const hasNextPage = res['@Microsoft.Dynamics.CRM.morerecords'] ?? false;
                    const pagingCookie = res['@Microsoft.Dynamics.CRM.fetchxmlpagingcookie'] ?? '';
                    if (hasNextPage) {
                        return this.queryEntityDataItems$(entitySetName, fetchxml, modifiedFieldName, onUpdateDataSource, pagingCookie).pipe(
                            map(sub_res => {
                                return [...items, ...sub_res];
                            })
                        )
                    } else {
                        return of(items);
                    }
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryEntityDataItems$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryEntityDataItems$(entitySetName, fetchxml, modifiedFieldName, onUpdateDataSource, pagingCookie)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    getDataverseEntityDataItem$(entitySetName: string, fetchxml: string = null, onUpdateDataSource: (data: any) => any) {
        return new Observable<any>(obs => {
            let requestURL = `${this.state.currentState.domain}/api/data/v9.2/${entitySetName}`;
            if (fetchxml) {
                requestURL += `?fetchXml=${fetchxml}`
            }
            const requestHeaders = new HttpHeaders()
                .set('prefer', 'odata.include-annotations="*"');
            this.http.getItems(requestURL, requestHeaders).pipe(
                map(res => {
                    if (onUpdateDataSource) {
                        return onUpdateDataSource(res['value']);
                    } else {
                        return res['value'];
                    }
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'getDataverseEntityDataItem$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.getDataverseEntityDataItem$(entitySetName, fetchxml, onUpdateDataSource)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityDataItemsLookup
     * @param {string} entitySetName
     * @param {string} fetchXml fetch query xml of view
     * @description query list entity data items
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/retrieve-and-execute-predefined-queries#predefined-queries
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/use-fetchxml-web-api
     */
    queryEntityDataItemsLookup$(entitySetName: string, entityLogicalName: string, primaryName: string, page: number = 1, searchString: string = '') {
        return new Observable<any>(obs => {
            let requestURL = `${this.state.currentState.domain}/api/data/v9.2/${entitySetName}`;
            const fetchxml = `<fetch mapping="logical" returntotalrecordcount="true" page="${page}" count="20" no-lock="false"><entity name="${entityLogicalName}"><attribute name="${primaryName}"/><attribute name="${entityLogicalName}id"/>${searchString !== '' ? `<filter type="and"><condition attribute="${primaryName}" operator="like" value="%${searchString}%"/></filter>` : ``}<order attribute="${primaryName}" descending="false"/></entity></fetch>`
            requestURL += `?fetchXml=${encodeURIComponent(fetchxml)}`
            const requestHeaders = new HttpHeaders()
                .set('prefer', 'odata.include-annotations="*"');
            this.http.getItems(requestURL, requestHeaders).pipe(
                map(res => {
                    return {
                        total: res['@odata.count'],
                        value: res['value'].reduce((acc, cur) => {
                            if (cur) {
                                acc.push({
                                    'primaryName': cur[primaryName],
                                    'primaryId': cur[`${entityLogicalName}id`]
                                })
                            }
                            return acc;
                        }, [])
                    }
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryEntityDataItemsLookup$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryEntityDataItemsLookup$(entitySetName, entityLogicalName, primaryName, page, searchString)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function queryEntityDataItemsParallels
     * @param {string} entitySetName
     * @param {string} fetchxml fetchxml query param
     * @description query list entity data items
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/retrieve-and-execute-predefined-queries#predefined-queries
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/use-fetchxml-web-api
     */
    queryEntityDataItemsParallels$(entitySetName: string, fetchxml: string, modifiedFieldName: string, onUpdateDataSource?: (data: any) => any) {
        return new Observable<any>(obs => {
            let requestURL = `${this.state.currentState.domain}/api/data/v9.2/${entitySetName}?$select=${modifiedFieldName}&fetchXml=${fetchxml}`;
            this.http.getItems(requestURL).pipe(
                map(res => {
                    if (onUpdateDataSource) {
                        return onUpdateDataSource(res['value']);
                    } else {
                        return res['value'];
                    }
                }),
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'queryEntityDataItemsParallels$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.queryEntityDataItemsParallels$(entitySetName, fetchxml, modifiedFieldName, onUpdateDataSource)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next();
                        obs.complete();
                    }
                }
            });
        })
    }

    /**
     * @function getParallelItemWithBatch$
     * @description Make batch requests with the REST APIs.
     * @param {string} batchGuid guid of batch. 
     * @param {any} batchBody batch requests.
     * @return {Observable<*>}
     * @see https://learn.microsoft.com/en-us/power-apps/developer/data-platform/webapi/execute-batch-operations-using-web-api
    */
    cmrDynamicsBatchRequest$(batchGuid: string, batchBody: any) {
        return new Observable<any>(obs => {
            const requestURL = `${this.state.currentState.domain}/api/data/v9.2/$batch`;
            this.http.http.post(requestURL, batchBody, {
                headers: {
                    'content-type': `multipart/mixed;boundary="batch_${batchGuid}"`,
                    'accept': `application/json`,
                    'OData-MaxVersion': '4.0',
                    'OData-Version': '4.0'
                },
                responseType: 'text'
            }).pipe(
                tap(res => {
                    this.log.log('PowerAppsDataverseService', 'cmrDynamicsBatchRequest$', res);
                })
            ).subscribe({
                next: (res) => {
                    obs.next(res);
                    obs.complete();
                },
                error: (err) => {
                    if (err.status === 401) {
                        this.refreshCRMAccessToken(this.cmrDynamicsBatchRequest$(batchGuid, batchBody)).subscribe({
                            next: (newResonse) => {
                                obs.next(newResonse);
                                obs.complete();
                            }
                        })
                    } else if (err.status === 403) {
                        obs.next({
                            error: 'access-denied'
                        });
                        obs.complete();
                    } else {
                        obs.next(err.error);
                        obs.complete();
                    }
                }
            });
        });
    }
}